import {
    createEntityAdapter, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Card {
    id?: string;
    rank: number;
    suit: number;
    selected?: boolean;
}

const adapter = createEntityAdapter<Card>();

function getId(cardData: any) {
    return cardData.suit + "-" + cardData.rank;
}

export const slice = createSlice({
    name: "modal",
    initialState: adapter.getInitialState(),
    reducers: {
        setCardSelected(state, action) {
            const cards = (Array.isArray(action.payload)) ? action.payload : [action.payload];
            const upserts = [];
            const removes = [];
            for (const card of cards) {
                const id = getId(card);
                if (!card.selected) {
                    removes.push(id);
                } else {
                    const finalData = {
                        ...card,
                        id: id,
                    };

                    upserts.push(finalData);
                }
            }

            if (!cards?.[0]?.selected) {
                return adapter.removeMany(state, removes);
            }

            return adapter.upsertMany(state, upserts);
        },
        clearSelectedCards(state) {
            return adapter.removeAll(state);
        },
    },
});

export default slice.reducer;
export const { setCardSelected, clearSelectedCards } = slice.actions;

const selectors = adapter.getSelectors<RootState>(
    (state) => state.cardSelection
);

export const isCardSelected = (cardData: any) => (state: RootState) =>
    selectors.selectById(state, getId(cardData))?.selected ?? false;

export const getSelectedCards = (state: RootState) => selectors.selectAll(state).filter(
    (entry) => entry.selected
);
