import {
    chooseSeat,
    createTable,
    joinTable,
    resetGame
} from "./thunks";
import {
    createAsyncThunk, createSlice, PayloadAction
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import {selectUserId} from "../user/slice";
import { updateTable } from "../shared";
import {
    selectPlayerByTablePos, selectPlayersArray
} from "../player/slice";

////////////////// THUNKS /////////////////

export const updateTableFull = createAsyncThunk(
    "table/updateTableFull",
    async() => {
        //thunkAPI.dispatch(updateTable(payload))
        //.then(() => thunkAPI.dispatch(updatePlayers(payload)));
    }
);

/////////////////// SLICE //////////////////

export const initialState = {
    requiresRefresh: false,
    players: {},
    updateCount: 0, // Local number of updates
    updateNumber: 0, // Server number of updates
    updateTime: 0,
    id: null,
    connected: false,
};

export const slice = createSlice({
    name: "table",
    initialState: initialState,
    reducers: {
        resetTableId: (state) => {
            state.id = null;
        },
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTable.fulfilled, (state, action) => {
            return { ...state, ...action.payload };
        });
        builder.addCase(joinTable.fulfilled, (state) => {
            return { ...state, requiresRefresh: true };
        });
        builder.addCase(chooseSeat.fulfilled, (state) => {
            return { ...state, requiresRefresh: true };
        });
        builder.addCase(resetGame.fulfilled, (state, action) => {
            return { ...state, ...action.payload, requiresRefresh: true };
        });
        builder.addCase(updateTable, (state, action) => {
            const tableData = action.payload.table;
            if (!tableData) return;
            return {
                ...state,
                ...tableData,
                requiresRefresh: false,
                updateCount: state.updateCount + 1,
                updateTime: action.payload.updateTime,
                updateNumber: action.payload.updateNumber,
            };
        });
    },
});

export const { resetTableId, setConnected } = slice.actions;
export default slice.reducer;

///////////// SELECTORS ////////////////

const selectTable = (state: any) => state.table;
export const selectUserByName = (name: string) => (state: RootState) => selectTableUsers(state).find((user: any) => user.user.nickName === name);
export const selectUserByTablePos = (tablePosition: number) => (state: RootState) => {
    const player = selectPlayerByTablePos(tablePosition)(state);
    return selectUserByName(player.name)(state);
};
export const selectConnectionState = (state: RootState) => state.table.connected;
export const selectUpdateCount = (state: RootState) => state.table.updateCount;
export const selectUpdateNumber = (state: RootState) => state.table.updateNumber;
export const selectUpdateTime = (state: RootState) => state.table.updateTime;
export const selectTableId = (state: any) => state.table.id || 0;
export const selectName = (state: any) => state.table.name;
export const selectRequiresRefresh = (state: any) =>
    state.table.requiresRefresh;
export const selectTableUsers = (state: any) =>
    selectTable(state).tableUsers || [];
export const selectLoggedIn = (state: any) =>
    selectUserId(state) > 0;
export const selectJoined = (state: any) => selectTableId(state) > 0;
export const isSeatTaken = (tablePosition: number) => (state: any) => {
    const player = selectPlayerByTablePos(tablePosition)(state);
    return player && player?.name;
};
export const selectSeatedStatus = (state: RootState) => state.user?.seatPosition > 0;
export const selectUserPlayer = (state: any) => selectPlayersArray(state).find((player: any) => player?.name === state?.user?.name);
export const selectUserSeatNumber = (state: RootState) => selectUserPlayer(state)?.seatNumber;
export const selectOffset = (state: RootState) => {
    const position = selectUserSeatNumber(state);
    if (position <= 0) {
        return 0;
    }
    return 7 - position;
};

export const screenToTablePosition = (screenPosition: number) => (state: RootState) =>
    ((screenPosition - selectOffset(state) + 7) % 7);


