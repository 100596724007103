import "./PlayerBet.css";
import React from "react";
import { useSelector } from "react-redux";
import {
    getPlayerBet, getPlayerChecked
} from "../../logic/gameInstance/slice";
import {formatMoneyString} from "../../utils/MoneyFormat";

interface Props {
    tablePosition: number;
}

export const PlayerBet = ({ tablePosition }: Props) => {
    const betAmount = useSelector(getPlayerBet(tablePosition));
    const playerChecked = useSelector(getPlayerChecked(tablePosition));
    const showChecked = playerChecked && betAmount <= 0;

    let cssClass = "player-bet";
    if (showChecked) cssClass += " checked";
    if (!betAmount && !showChecked) cssClass += " hidden";

    const toDisplay = (showChecked) ? "Check" : formatMoneyString(betAmount);

    return (
        <div className={cssClass}>
            <p>{toDisplay}</p>
        </div>
    );
};
