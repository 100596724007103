import React, {
    useCallback, useEffect, useMemo, useState
} from "react";
import {Table} from "../table/Table";
import "./Dev.css";
import {withRouter} from "react-router-dom";
import {useParams} from "react-router";
import {
    Provider, useDispatch
} from "react-redux";
import {stores} from "./DevStores";
import {makeStore} from "../../../logic/store";
import {useDeveloper} from "../../components/Dev/hooks/useDeveloper";
import {useRedirect} from "../../../hooks/useRedirect";
import {resetTableId} from "../../../logic/table/slice";
import {FeaturesList} from "../../../logic/features/FeaturesList";
import Modal, {useModal} from "../../components/Modal";
import { playAutomatically } from "../../../logic/prompts/slice";

const hidden = {opacity: "0", zIndex: 0};

function getName(index: number) {
    const char = String.fromCharCode("A".charCodeAt(0) + index);
    return char + char + char + "-Dev";
}

function Dev() {
    const { id } = useParams() as any;
    const dispatch = useDispatch();
    const featuresModal = useModal("features-toggle");
    const [active, setActive] = useState(0);
    const [players, setPlayers] = useState(2);
    const [autoSelect, setAutoSelect] = useState(true);
    const [autoPlay, setAutoPlay] = useState(false);
    const playerIndexes = useMemo(() => Array.from(Array(players).keys()), [players]);
    const [hasAction, setHasAction] = useState(playerIndexes.map(() => false));
    const [events, setEvents] = useState([]) as any;
    const callBacks = useMemo(() => playerIndexes.map((index: number) => (event: string) => {
        const eventObj = [index, event];
        setEvents((_events: any[]) => {
            const newEvents = [..._events, eventObj];
            return newEvents;
        });
    }), [playerIndexes]);
    const developer = useDeveloper({turnOn: true});
    const redirectHome = useRedirect("/");

    const goHome = useCallback(() => {
        dispatch(resetTableId());
        redirectHome();
    }, [dispatch, redirectHome]);

    useEffect(() => {
        localStorage.setItem("developer", "true");
    }, []);

    const selectActive = useCallback((hasAction) => {
        if (!autoSelect) {
            return;
        }
        for (let i = 0; i < players; i++) {
            if (hasAction[i]) {
                setActive(i);
                return;
            }
        }
    }, [players, autoSelect]);

    const mapTab = (index: number) => {
        const classes = [];
        if (active === index) classes.push("activeTab");
        if (hasAction[index]) classes.push("hasAction");
        return (
            <div key={getName(index)} className={classes.join(" ")} onClick={() => setActive(index)}>
                {getName(index)}
            </div>);
    };

    const handleEvent = useCallback((index: number, event: string) => {
        const hasActionCopy = [...hasAction];

        if (event === "waiting") {
            hasActionCopy[index] = false;
            setHasAction(hasActionCopy);
        } else if (event === "active") {
            hasActionCopy[index] = true;
            setHasAction(hasActionCopy);
        }
    }, [hasAction]);

    useEffect(() => {
        if (!autoPlay) {
            return;
        }
        dispatch(playAutomatically());

        const repeatSecondsDelay = 2;
        const repeat = setInterval(() => {
            dispatch(playAutomatically());
        }, repeatSecondsDelay * 1000);

        return () => {
            clearInterval(repeat);
        };
    }, [autoPlay, dispatch]);

    useEffect(() => {
        selectActive(hasAction);
    }, [hasAction, selectActive]);

    useEffect(() => {
        if (events.length === 0) return;

        events.forEach((event: [number, string]) => {
            handleEvent(event[0], event[1]);
        });

        setEvents((oldEvents: any) => oldEvents.filter((event: [number, string]) =>
            events.find((_event: [number, string]) => event[0] === _event[0] && event[1] === _event[1]) === undefined
        ));
    }, [events, handleEvent]);

    const mapTable = (index: number) => {
        if (stores[index] === undefined) {
            stores[index] = makeStore(getName(index));
        }

        return <Provider key={getName(index) + "-provider"}  store={stores[index]}>
            <span className={"devTable"} style={(active === index) ? {} : hidden }>
                <Table eventHandler={callBacks[index]} delay={400 * index} id={id} username={getName(index)}/>
            </span>
        </Provider>;
    };

    return (
        <div className={"devPage"}>
            <div className={"devTabs"}>
                {
                    playerIndexes.map(mapTab)
                }
                <div key={"ADDPLAYER"}
                    className="addPlayer"
                    onClick={() => setPlayers(players => Math.min(players + 1, 7))}>
                    +
                </div>
                <div key={"AUTOSELECT"}
                    className={autoSelect ? "activeTab" : ""}
                    onClick={() => setAutoSelect(!autoSelect)}>
                    Auto-select Player
                </div>
                <div key={"AUTOPLAY"}
                    className={autoPlay ? "activeTab" : ""}
                    onClick={() => setAutoPlay(!autoPlay)}>
                    Auto-Play
                </div>
                <div key={"HOME"} className="home" onClick={goHome}>
                    Home
                </div>
                <div key={"EXPERIMENTAL"} className="experimental" onClick={featuresModal.show}>
                        Experimental
                </div>
                <div key={"NEWTABLE"} className="newTable" onClick={developer.gotoDevTable}>
                    New Table
                </div>
            </div>
            <div className={"devTables"}>
                {
                    playerIndexes.map(mapTable)
                }
            </div>
            <Modal id={featuresModal.id}><FeaturesList /></Modal>
        </div>);
}

export default withRouter(Dev);
