import "../BetArea.css";
import React from "react";
import { selectFixedAmount } from "../../../../logic/gameInstance/slice";

import { useSelector } from "react-redux";
import {GenericBetArea} from "./GenericBetArea";

const FixedBetArea = () => {
    const fixedAmount = useSelector(selectFixedAmount);

    return <GenericBetArea showTopButtons={false} showSlider={false} maxRaise={fixedAmount} minRaise={fixedAmount}/>;
};

export default FixedBetArea;
