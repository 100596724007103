import { title } from "process";
import { Denominations } from "../../data/settings/denominations";
import React from "react";

// a reusable chip component used throughout application

interface chipProps {
    value: Number, // ie. 0.25, 0.05, 1, 5, 100
    denom: string, // denomination category - low, mid, or high
    disabled?: boolean, // dims the chip and disbles onClick
    raise?: boolean, // raise will add a plus sign above the denomination for betting phase
    onClick?: any
}

export const Chip = (props: chipProps) => {
    const getTitle = (chipDenom: any) => {
        const denomination = Denominations.VALUES.filter((item) => {
            return item.VALUE == chipDenom
        })[0];
        return denomination.TITLE
    }
    const chipTitle = getTitle(props.value)

    // this is just for text sizing, based on how many characters are in the chip title
    let titleSizeClass
    switch (chipTitle.length) {
        case 2:
            titleSizeClass = 'chipTitleLarge'
            break
        case 3:
            titleSizeClass = 'chipTitleMedium'
            break
        case 4:
            titleSizeClass = 'chipTitleSmall'
            break
    }

    let chipClassName = "chipContainer "+props.denom+"Chip";
    chipClassName += props.raise ? " raise" : "";
    chipClassName += props.disabled ? " disabled" : "";

    return (
        <div 
            onClick={props.onClick && !props.disabled ? props.onClick : null}
            className={chipClassName}
        >
            {props.raise && (
                <div className="raisePlus">
                    +
                </div>
            )}
            <div className={"chipTitle "+titleSizeClass}>
                {chipTitle}
            </div>
        </div>
    )
}