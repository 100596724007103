import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { useWinners } from "../../../logic/gameInstance/hooks";
import {
    getPrefs,
    selectDeclarations, selectWinnings
} from "../../../logic/gameInstance/slice";
import awardSfx from "../../../static/sounds/winner-trumpet.wav";
import {selectPlayerByTablePos} from "../../../logic/player/slice";
import { HI_LO_SETTINGS } from "poker-cows-common";
import { formatMoneyString } from "../../../utils/MoneyFormat";

interface WinningsProps {
    tablePosition: number;
    isClientWinnings: boolean;
}

export const Winnings = (props: WinningsProps) => {
    const declarations = useSelector(selectDeclarations);
    const declaration: string = declarations[props.tablePosition] ?? "";
    const player = useSelector(selectPlayerByTablePos(props.tablePosition));
    const { amWinner } = useWinners({ tablePosition: props.tablePosition });
    const amountWon = useSelector(selectWinnings)[player.id];
    const highLowSetting = useSelector(getPrefs).highLowSetting;

    let className = "winnings";
    if (declaration === "High" || highLowSetting === HI_LO_SETTINGS.HIGH) className += " wonHigh";
    if (declaration === "Low" || highLowSetting === HI_LO_SETTINGS.LOW) className += " wonLow";
    

    useEffect(() => {
        if (amWinner && props.isClientWinnings) {
            playSound(awardSfx);
        }
    }, [amWinner, props.isClientWinnings]);

    const playSound = (sfx: any) => {
        const sound = new Audio(sfx);
        sound.play();
    };

    return (
        <>
            <div className={className}>
                {"+ " + formatMoneyString(amountWon)}
            </div>
        </>
    );
};
