import * as React from "react";
import {
    useSelector, useDispatch
} from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPause, faPlay
} from "@fortawesome/free-solid-svg-icons";
import {togglePause} from "../../logic/prompts/slice";
import {selectPaused} from "../../logic/gameInstance/slice";

interface PauseButtonProps {
    useTextOnButton?: boolean;
}

const PauseButton = (props: PauseButtonProps) => {
    const dispatch = useDispatch();
    const paused = useSelector(selectPaused);
    const isTextButton = props.useTextOnButton;

    const pauseClick = () => {
        dispatch(togglePause());
    };

    return <div className={isTextButton ? "actionButton pauseGameButton" : "startGame menuButton"} onClick={pauseClick}>
        {isTextButton && !paused && `PAUSE
            GAME`}
        {isTextButton && paused && `RESUME
            GAME`}
        {!isTextButton && <FontAwesomeIcon icon={(paused) ? faPlay : faPause} />}
    </div>;
};

export default PauseButton;