import React from "react";
import "./CreateDevTable.css";
import {useDeveloper} from "./hooks/useDeveloper";

export const CreateDevTable = () => {
    const developer = useDeveloper();

    return (
        <div className="button blue createDevTable" onClick={developer.gotoDevTable}>Create Dev Table</div>
    );
};
