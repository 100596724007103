import React, {
    useEffect, useState
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowUp,
    faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { useWinners } from "../../../logic/gameInstance/hooks";
import "./HandValue.css";
import { useSelector } from "react-redux";
import {
    getPrefs, selectDeclarations
} from "../../../logic/gameInstance/slice";
import { HI_LO_SETTINGS } from "poker-cows-common/";
import {useSeatContext} from "./../Seat/SeatContext";
import {doesGameExist} from './../../../logic/gameInstance/slice';

interface HandValueProps {
    tablePosition: number
}

export const HandValue = (props: HandValueProps) => {
    const { myHand } = useWinners({ tablePosition: props.tablePosition });
    const {isThisSeatTaken} = useSeatContext();

    const prefs = useSelector(getPrefs);
    const highLow = prefs?.highLowSetting;

    const declarations = useSelector(selectDeclarations);
    const declaration = declarations[props.tablePosition] ?? "";

    const inGamePlay = useSelector(doesGameExist);

    const [hand, setHand] = useState({
        high: "",
        low: ""
    });

    const getShowHandDesc = (hilo: string) => {
        if (declaration == "" && inGamePlay) {
            switch (hilo) {
                case "High":
                    return hand.high.length > 0 && (highLow == HI_LO_SETTINGS.HIGH || highLow === HI_LO_SETTINGS.HI_LO);
                    break;
                case "Low":
                    return hand.low.length > 0 && (highLow === HI_LO_SETTINGS.LOW || highLow === HI_LO_SETTINGS.HI_LO);
                    break;
                default:
                    return false;
                    break;
            }
        } else {
            return declaration === hilo
        }
    }

    useEffect(() => {
        const highDesc = (myHand?.description ?? "").toString().trim();
        const lowDesc = (myHand?.lowDescription ?? "").toString().trim();

        setHand({
            high: highDesc,
            low: lowDesc
        });

    }, [myHand?.description, myHand?.lowDescription, declaration, highLow]);

    const getValues = () => {
        if (isThisSeatTaken) {
            return (
                <div className="myHandValueContainer">
                    {getShowHandDesc("Low") && (
                        <div className="myHandValue lowValue">
                            <div className="declareIcon valueIcon">
                                <FontAwesomeIcon icon={faArrowDown} /> 
                            </div>
                            {hand.low}
                        </div>
                    )}
                    {getShowHandDesc("High") && (
                        <div className="myHandValue highValue">
                            <div className="declareIcon valueIcon">
                                <FontAwesomeIcon icon={faArrowUp} /> 
                            </div>
                            {hand.high}
                        </div>
                    )}
                </div>
            );
        } else {
            return <></>
        }
    }
    return getValues()
};

