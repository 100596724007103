import React from "react";

export const CheckRaisingContent = () => {
    
    const checked = true;
    const onClick = () => {};
    
    return (
        <>
            Yes
        </>
    )
}