import React from "react";
import { VersionSection } from "./VersionSection";
import { WinnersSection } from "./WinnersSection";
import { OptionSection } from "./OptionSection";
import { WildcardSection } from "./WildcardSection";

// This is the options tab found when choosing a game or clicking "rules" button as a non-dealer player
// it contains all sections that show in options tab
export const OptionsPage = ({props}: any) => {
    const { gameRules, gameOptions } = props;
    return (
        <div className="options">
            <VersionSection gameRules={gameRules} gameOptions={gameOptions}></VersionSection>
            <WinnersSection gameOptions={gameOptions}></WinnersSection>
            <OptionSection gameOptions={gameOptions}></OptionSection>
            <WildcardSection gameOptions={gameOptions}></WildcardSection>
        </div>
    );
}
