import {
    faArrowUp, faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import "./PlayerDeclarationIndicator.css";

interface PlayerDeclareProps{
    high: boolean;
    low: boolean;
}

export function PlayerDeclarationIndicator(props: PlayerDeclareProps) {
    let className = "playerDeclareIndicatorContainer";
    if (props.high) className += " declareIndicatorHigh";
    if (props.low) className += " declareIndicatorLow";
    return <div className={className}>
        { props.high && <FontAwesomeIcon icon={faArrowUp} /> }
        { props.low && <FontAwesomeIcon icon={faArrowDown} /> }
    </div>;
}
