import React from "react";
import { useBetting } from "../BetArea/hooks/Betting";
import { getPrefs } from "../../../../logic/gameInstance/slice";
import { useSelector } from "react-redux";
import { BetSchemes } from "../../../../logic/gameMenu/components/GameRules";
import { selectFixedAmount } from "../../../../logic/gameInstance/slice";

// this component displays those three little dots in the user actionArea
// to tell you how many raises have happened in the round

export const RaisesCounter = () => {
    const prefs = useSelector(getPrefs);
    const fixedAmount = useSelector(selectFixedAmount);

    let betting: any;
    let bettingProps: any;

    if (prefs?.limitType === BetSchemes.FIXED_LIMIT) {
        bettingProps = {minRaise: fixedAmount, maxRaise: fixedAmount};
    }
    if (prefs?.limitType === BetSchemes.SPREAD_LIMIT) {
        bettingProps = {minRaise: prefs?.spreadMinRaise, maxRaise: prefs?.spreadMaxRaise};
    }
    betting = useBetting(bettingProps);

    function getRaiseDot(index: number) {
        const classes = ["raiseDot"];
        if (index < betting.numberOfRaises) classes.push("raiseDotFilled");
        return <div key={index} className={classes.join(" ")}/>;
    }
    function getRaiseDots() {
        const dots = [];
        for (let i = 0; i < betting.maxRaises; i++) {
            dots.push(getRaiseDot(i));
        }

        return dots;
    }
    return (
        <div className="raisesCounter">Raises {getRaiseDots()}</div>
    )
}