import "./App.css";
import {
    Route, BrowserRouter as Router, Switch
} from "react-router-dom";
import Home from "./ui/views/home/Home";
import { Provider } from "react-redux";
import React from "react";
import Table from "./ui/views/table/Table";
import Testing from "./ui/views/testing/Testing";
import tableStore from "./logic/store";
import {ErrorBoundary} from "./error_handling/ErrorBoundary";
import {ErrorPage} from "./error_handling/ErrorPage";
import Dev from "./ui/views/dev/Dev";
import DevCreate from "./ui/views/dev/DevCreate";
import {SpectateTable} from "./ui/views/table/SpectateTable";
import {FeaturesProvider} from "./logic/features/FeatureContext";
import { NonHostShutdown } from "./ui/views/shutdown/NonHostShutdown";
import { HostShutdown } from "./ui/views/shutdown/HostShutdown";

console.log("Poker Cows FE Version Code 9");

function App() {
    return (
        <ErrorBoundary renderTemplate={ErrorPage}>
            <ErrorBoundary>
                <FeaturesProvider>
                    <Provider store={tableStore}>
                        <div className="App">
                            <Router>
                                <Switch>
                                    <Route path="/end-session-host" component={HostShutdown} />
                                    <Route path="/end-session-non-host" component={NonHostShutdown} />
                                    <Route path="/testing" component={Testing} />
                                    <Route path="/table/:id" component={Table} />
                                    <Route path="/spectate/:id" component={SpectateTable} />
                                    <Route path="/dev/:id" component={Dev} />
                                    <Route path="/dev" component={DevCreate} />
                                    <Route path="/" component={Home} />
                                </Switch>
                            </Router>
                        </div>
                    </Provider>
                </FeaturesProvider>
            </ErrorBoundary>
        </ErrorBoundary>
    );
}

export default App;
