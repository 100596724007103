import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export function initSentry() {
    Sentry.init({
        dsn: "https://7cdf07f425ef4282bfe0a2e72a4bb1ed@o931598.ingest.sentry.io/5880525",
        integrations: [new Integrations.BrowserTracing()],
        normalizeDepth: 10,

        //Performance data tracking (1 for 100% of performance data, 0 off)
        tracesSampleRate: 0.2,
    });

    console.log("Sentry initiated");
}
