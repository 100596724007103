import {
    Dispatch, MutableRefObject
} from "react";
import {
    LocalTrack,
    LocalAudioTrack,
    Participant,
    RemoteParticipant,
    LocalDataTrack,
    LocalAudioTrackPublication,
    LocalVideoTrackPublication,
    RemoteTrack, RemoteTrackPublication, Room, connect as twilioConnect,
    createLocalTracks
} from "twilio-video";
import {
    connected,
    muted,
    participantConnected as participantConnectedAction,
    participantReconnecting,
    reconnecting,
    startedVideo,
    stoppedVideo,
    subscribed,
    unmuted, unsubscribed
} from "./slice";
import {LocalVideoTrack} from "twilio-video/tsdef/LocalVideoTrack";

export class TwilioClient {
    track?: LocalVideoTrack
    audioTrack?: LocalAudioTrack
    room?: Room
    _dispatch?: Dispatch<any>

    connect = (token: string, roomId: string, dispatch: Dispatch<any>) => {
        this._dispatch = dispatch;
        createLocalTracks().then(tracks => {
            tracks.forEach(track => {
                if (track.kind === "data") return;
                if (track.kind === "video") this.track = track;
                if (track.kind === "audio") this.audioTrack = track;
            });


            twilioConnect(token, { name: roomId, tracks: tracks }).then((newRoom) => this.subscribeToRoom(newRoom));
        });
    }

    private _getLocalVideos() {
        return this.room?.localParticipant.videoTracks ?? [];
    }

    private _attach(media: RemoteTrack | LocalTrack | null | undefined, ref: MutableRefObject<any>) {
        if (!media) return;

        if ("attach" in media) {
            media.attach(ref.current);
        }
    }

    attachMedia = (ref: MutableRefObject<any>, participantId: Participant.SID, mediaId: any) => {
        this._attach(this.getTrack(participantId, mediaId), ref);
    }

    attachLocalMedia = (video: MutableRefObject<any>) => {
        this._attach(this.track, video);
    }

    private detach(track: RemoteTrack | LocalTrack | null) {
        if (!track) return;

        if ("detach" in track) {
            track.detach().forEach(el =>  el.srcObject = null );
        }
    }

    private _localMediaStart(track: LocalTrack | undefined) {
        if (!track) return;

        if (!(track instanceof LocalDataTrack)) {
            if (track.isStopped) track.restart();

            track.enable();
        }

        this.room?.localParticipant.publishTrack(track);
    }

    private _localMediaStop(track: LocalTrack | undefined) {
        if (!track) return;

        if (!(track instanceof LocalDataTrack)) {
            track.disable();
            track.stop();
        }

        this.detach(track);
        this.room?.localParticipant.unpublishTrack(track);
    }

    private _localPublicationStop(publication: LocalAudioTrackPublication | LocalVideoTrackPublication) {
        this._localMediaStop(publication.track);
        publication.unpublish();
    }

    mute = () => {
        this._localMediaStop(this.audioTrack);
        this.room?.localParticipant.audioTracks.forEach(this._localPublicationStop);
        this.dispatch(muted());
    }

    stopVideo = () => {
        this._localMediaStop(this.track);
        this.room?.localParticipant.videoTracks.forEach(this._localPublicationStop);
        this.dispatch(stoppedVideo());
    }

    unmute = () => {
        this._localMediaStart(this.audioTrack);
        this.dispatch(unmuted());
    }

    startVideo = () => {
        this._localMediaStart(this.track);
        this.dispatch(startedVideo());
    }

    private getParticipantData(participant: RemoteParticipant) {
        return {
            userId: participant.identity,
            twilioId: participant.sid,
        };
    }

    private getTrackData(participant: RemoteParticipant, track: RemoteTrack) {
        return {
            userId: participant.identity,
            mediaId: track.sid,
            mediaType: track.kind,
        };
    }

    //// TWILIO CALLBACK //////

    private participantDisconnected = (participant: RemoteParticipant) => {
        participant.tracks.forEach(publication => this.trackUnpublished(publication));
    }

    private participantReconnecting = (participant: RemoteParticipant) => {
        this.dispatch(participantReconnecting(this.getParticipantData(participant)));
        this.participantDisconnected(participant);
    }

    //Setup callback and perform callback on existing publications
    private participantConnected = (participant: RemoteParticipant) => {
        this.dispatch(participantConnectedAction(this.getParticipantData(participant)));

        participant.tracks.forEach(publication => this.trackPublished(publication, participant));
        participant.on("trackPublished", publication => this.trackPublished(publication, participant));
        participant.on("trackUnpublished", publication => this.trackUnpublished(publication));
        participant.on("reconnecting", () => this.participantReconnecting(participant));
        participant.on("reconnected", () => this.participantConnected(participant));
    }

    private trackPublished = (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        publication.on("subscribed", (track: RemoteTrack) => this.trackSubscribed(track, participant));
        publication.on("unsubscribed", (track: RemoteTrack) => this.trackUnsubscribed(track, participant));
    }

    private trackUnpublished = (publication: RemoteTrackPublication) => this.detach(publication.track);

    private trackSubscribed(track: RemoteTrack, participant: RemoteParticipant) {
        this.dispatch(subscribed(this.getTrackData(participant, track)));
    }

    private trackUnsubscribed(track: RemoteTrack, participant: RemoteParticipant) {
        this.detach(track);
        this.dispatch(unsubscribed(this.getTrackData(participant, track)));
    }

    private subscribeToRoom = (room: Room) => {
        this.room = room;

        this.dispatch(connected());

        //Setup participantConnected callback and call for everyone already connected
        this.room.participants.forEach(this.participantConnected);
        this.room.on("participantConnected", this.participantConnected);
        this.room.on("participantDisconnected", this.participantDisconnected);
        this.room.on("reconnecting", () => this.dispatch(reconnecting()));
        this.room.on("reconnected", () => this.subscribeToRoom(room));
    }

    //////// PRIVATE /////////

    private getTrack = (participantId: string, videoId: string): RemoteTrack | null | LocalTrack => {
        if (!this.room) return null;

        const participant = this.room.participants.get(participantId);
        if (!participant) return null;

        const videoTrack = participant.tracks.get(videoId);
        if (!videoTrack) return null;

        return videoTrack.track;
    }

    dispatch(action: any) {
        if (!this._dispatch) return;

        return this._dispatch(action);
    }
}
