import React from "react";
import "./HostGameWidget.css";

import { createTable } from "../../logic/table/thunks";
import { useDispatch } from "react-redux";

const HostGameWidget = () => {
    const dispatch = useDispatch();
    const handleCreateTable = () => {
        dispatch(createTable("Created Table"));
    }
    return (
        <div className="hostGameWidget">
            <div className="hostGameWidgetText">READY TO HOST A <br/> GAME WITH FRIENDS?</div>
            <div className="actionButton green" onClick={handleCreateTable}>HOST A GAME</div>
        </div>
    );
}
export default HostGameWidget;