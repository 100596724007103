import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNextPrefs } from "../../../../logic/gameInstance/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";

interface WildcardSectionProps {
    gameOptions: any;
}

// This is the Wildcard section in the OptionsPage when picking a game or looking at "Rules" within the game,
// it determines whether 2's jacks, kings, low hole etc. is the wild card
// low hole wild cards show up as blue, and face up cards affected by low hole in your hand should not be visible to other players
// public wild cards likes deuces, jacks, or kings will show gold and will show gold for other players when face up
export const WildcardSection = (props: WildcardSectionProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const dispatch = useDispatch();

    const setWildcardSetting = useCallback( (value) => {
        const prefsString = JSON.stringify({wildcardSetting: value});
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    return (
        <div className="section">
            <div className="optionsSubSection optionTitle"> 
                <span>Wildcard:</span><br/>
                <div className="optionSelectionContainer">
                    {props.gameOptions?.OPTIONS?.WILDCARD?.map((option: {DESCRIPTION: string, VALUE: string | boolean, DEFAULT?: boolean}) => {

                        const checked = nextPrefs?.wildcardSetting === option.VALUE;
                        
                        return (
                            <div style={checked ? {color: "#fff"} : {}} className="winnersOption" onMouseUp={() => {setWildcardSetting(option.VALUE)}}>
                                <label htmlFor="wildcard" className="radio">
                                    <input readOnly type="radio" id="wildcard" name="wildcard" className="radioInput" checked={checked} />
                                    <div className="customRadio"></div>
                                    <div className="radioOptionText">
                                        {option.DESCRIPTION}
                                    </div>
                                </label>
                            </div>
                        )
                    })} 
                </div>
            </div>
        </div>
    );
}

