import React from "react";
import PlayerInfo from "../PlayerInfo";
import {formatMoneyString} from "../../../utils/MoneyFormat";
import {useSeatContext} from "./SeatContext";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    isSeatTaken,
    selectSeatedStatus,
    selectTableId
} from "../../../logic/table/slice";
import {
    getPlayerBank, selectPlayerByTablePos
} from "../../../logic/player/slice";
import {chooseSeat} from "../../../logic/table/thunks";
import {selectUser} from "../../../logic/user/slice";

export const SeatInfoContents = () => {
    const dispatch = useDispatch();
    const {tablePosition} = useSeatContext();
    const seatedUser = useSelector(selectPlayerByTablePos(tablePosition));
    const tableUser = useSelector(selectUser);
    const nickName = seatedUser?.name ?? "";
    const isTaken = useSelector(isSeatTaken(tablePosition));
    const balance = useSelector(getPlayerBank(tablePosition));
    const isSeated = useSelector(selectSeatedStatus);
    const tableId = useSelector(selectTableId);

    const sitAction = () => dispatch(chooseSeat({
        tableUserId: tableUser.id,
        seatPosition: tablePosition,
        tableId: tableId,
    }));

    if (isTaken) {
        return <PlayerInfo name={nickName} balance={formatMoneyString(balance)}/>;
    }

    if (!isSeated) {
        return <div className="seatSpot button-glow open-seat" onClick={sitAction as any}>Click To Sit</div>;
    }

    return null;
};
