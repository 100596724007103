/**
 * FeaturesContext.tsx
 *
 * Context and hooks for managing the state of experimental features.
 * These are UI controlled feature flags, allowing us to push UI changes that may not be fully ready for release.
 */

import React, {
    useCallback, useMemo
} from "react";

/**
 * An enum containing all currently available experimental features.
 */
export enum EXPERIMENTAL_FEATURES {
    ADVANCED_CARD_DELAY = "Advanced Card Delay - Calculate delay based on latency and server time.",
    KICK_PLAYERS = "Kick Players - Kick players from the table.",
    CHECK_CALL_IN_TURN = "Check / Call and Fold in turn feature",
    PLAY_AUTOMATICALLY = "Play Automatically - Adds a button for the host to progress the game by letting having a random action chosen for the current player.",
}

/**
 * Hook to manage a single experimental feature.
 * Useful when deciding behavior based on the state of the feature.
 */
export function useExperimentalFeature(featureId: EXPERIMENTAL_FEATURES) {
    const {isFeatureEnabled, toggleFeature} = useExperimentalFeatures();

    const isEnabled = React.useMemo(() => isFeatureEnabled(featureId), [featureId, isFeatureEnabled]);
    const toggle = useCallback((value?: boolean) => toggleFeature(featureId, value), [featureId, toggleFeature]);

    return {
        isEnabled,
        toggle,
    };
}

/**
 * Hook to manage all experimental features.
 * Useful when managing multiple features, such as a UI list for toggling features.
 */
export function useExperimentalFeatures() {
    const context = React.useContext(FeaturesContext);

    if (!context) {
        throw new Error("useExperimentalFeature must be used within a FeaturesProvider");
    }

    const isFeatureEnabled = useCallback((featureId) => context?.map.get(featureId) ?? false, [context]);
    const getFeaturesList = useCallback(() => Object.values(EXPERIMENTAL_FEATURES), []);

    return {
        isFeatureEnabled,
        toggleFeature: context?.toggleFeature,
        getFeaturesList,
    };
}

/**
 * A context provider that initializes and maintains a map of experimental features enabled/disabled states.
 */
export function FeaturesProvider({children}: FeaturesProviderProps) {
    const [map, setMap] = React.useState(new Map<EXPERIMENTAL_FEATURES, boolean>());

    const toggleFeature = useCallback((featureId: EXPERIMENTAL_FEATURES, value?: boolean) => {
        if (value == null) value = !map.get(featureId);

        setMap(new Map(map).set(featureId, value));
    }, [map]);

    const providerState = useMemo(() => {
        return {
            map,
            toggleFeature,
        };
    }, [map, toggleFeature]);

    return (
        <FeaturesContext.Provider value={providerState}>
            {children}
        </FeaturesContext.Provider>
    );
}

/**
 * Various types to keep typescript sane
 */

type FeaturesProviderProps = {children: React.ReactNode}
const FeaturesContext = React.createContext<ProviderState | undefined>(undefined);

interface ProviderState {
    map: Map<EXPERIMENTAL_FEATURES, boolean>;
    toggleFeature: (featureId: EXPERIMENTAL_FEATURES, value?: boolean) => void;
}
