import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    alertOkay, getAlertPrompt
} from "../../../logic/prompts/slice";

export const AlertArea = () => {
    const alertPrompt = useSelector(getAlertPrompt);
    const dispatch = useDispatch();

    const click = () => {
        dispatch(alertOkay());
    };

    return (
        <div>
            {alertPrompt.actionsAvailable[0].name}
            <br />
            <button onClick={click}> OK </button>
        </div>);
};
