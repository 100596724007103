import React, { useState } from "react"
import "./JoinGameWidget.css";

const JoinGameWidget = () => {
    const [inputValue, setInputValue] = useState("");
    const onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const tableCode = event.target.value.trim();
        setInputValue(tableCode);
    }
    const goToTable = () => {
        location.href = "./table/" + inputValue;
    }
    return <div className = "JoinGameWidget">
        <input className = "JoinGameWidgetInput" id = "join-table-code" type = "text" placeholder = "Enter Table Code" value={inputValue} onChange={onChange}></input>
        <div className = "JoinGameButton" onClick = {goToTable}>JOIN GAME</div>
    </div>
}
export default JoinGameWidget;