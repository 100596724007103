import React from "react";
import { useSelector } from "react-redux";
import {getPrefs} from "../../../../logic/gameInstance/slice";
import {GenericBetArea} from "./GenericBetArea";
import {ChipBetArea} from "./ChipBetArea";


export const SpreadBetArea = () => {
    const prefs = useSelector(getPrefs);

    return <ChipBetArea
        showTopButtons={false}
        showSlider={true}
        maxRaise={prefs.spreadMaxRaise}
        minRaise={prefs.spreadMinRaise}/>;
};
