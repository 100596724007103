import React from "react";
import { useSelector } from "react-redux";
import { getPrefs } from "../../../logic/gameInstance/slice";
import { FoldInTurnButton } from "./FoldArea/FoldInTurnButton";
import { FoldButton } from "./FoldArea/FoldButton";
import "./FoldArea.css";

// this component is what hangs out to the left of the user's
// seat/video in the actionArea

interface foldAreaProps {
    isWaiting: boolean,
    isBetting: boolean,
    isDiscarding: boolean
}

export const FoldArea = (props: foldAreaProps) => {
    const showFold = props.isDiscarding || props.isBetting

    return (
        <div className="foldArea">
            {showFold && <FoldButton />}
            {props.isWaiting && <FoldInTurnButton />}
        </div>
    )
}