import IronCrossRulesImagePlayer from "../../static/graphics/rules/Iron_Cross_Player_Hand.svg";
import IronCrossRulesImageCommunity from "../../static/graphics/rules/Iron_Cross_Community_Cards.svg";

export const IronCrossRules = {
    RULES: {
        CARDS: [
            "• 4 hole cards dealt to each player.",
            "• 5 community cards dealt in cross pattern.",
        ],
        OBJECT: "Best 5 card hand utlizing any or all player hole cards and up to 3 community cards up and down, or left to right.",
        ACTIONS: [
            "1. After all cards are dealt, a betting round starts left of the dealer. (Opening bet position rotates each betting round.)",
            "2. Next, the community card in position 1 is turned up, followed by a round of betting. This process is repeated for the remaining community cards 2 through 5.",
            "3. After final betting round, each remaining player will declare High or Low (if High/Low split game).",
            "4. Cards displayed; winners paid.",
        ],
        IMAGES: {
            PLAYER: IronCrossRulesImagePlayer,
            COMMUNITY: IronCrossRulesImageCommunity,
        },
    },
};
