import {io} from "socket.io-client";

const url = process.env.REACT_APP_BACKEND_URL || "http://localhost:3002";
let _userId: string | number = "";

export const setUserId = (userId: string | number) => {
    _userId = userId;
};

// Helper

//TODO(@Nerdsie): If endpoint starts with /, strip it ( to avoid // )
function endpointToURL(endpoint: string) {
    return url + endpoint;
}

// GET requests
export const eventEndpoint = (endpoint: string) => new EventSource(endpointToURL(endpoint + "?userId=" + _userId));
export const websocketEndpoint = ({tableId}: {tableId: string}) => io(endpointToURL("/"), {
    auth: {
        tableId: tableId,
        userId: _userId,
    },
});
export const getEndpoint = (endpoint: string) => get(endpointToURL(endpoint + "?userId=" + _userId));

export async function get(url: string) {
    return fetch(url)
        .then(response => response.json())
        .catch(error => error);
}

// POST requests

export const postEndpoint = (endpoint: string, body: any) =>{
    if (_userId === "SPECTATE") {
        return undefined;
    }

    return post(endpointToURL(endpoint), { ...body, userId: _userId });
};

export async function post(url: string, body: any) {
    const requestOptions = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
    };

    //This returns a promise of a json response, should modify to somehow return actual 
    // response instead of just the json
    // (so that we can handle 404 or other non-json responses)
    return fetch(url, requestOptions)
        .then(response => response.json())
        .catch(error => error);
}
