import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBetPromptForPlayer, foldForPlayer, checkForPlayer, callForPlayer } from "../../../../logic/prompts/slice";
import { selectCallAmountForPlayer } from "../../../../logic/gameInstance/slice";
import { SubpageHeader } from "./Components/SubpageHeader";

interface ActForPlayerSubpageProps {
    navigate: any;
    selectedPlayer: any;
}

export const ActForPlayerSubpage = (props: ActForPlayerSubpageProps) => {
    const dispatch = useDispatch();
    const selectedPlayer = props.selectedPlayer;
    const promptForPlayer = useSelector(getBetPromptForPlayer(selectedPlayer?.id));
    const callAmountForPlayer = useSelector(selectCallAmountForPlayer(props.selectedPlayer?.seatNumber));

    const checkCallForPlayer = () => {
        if (callAmountForPlayer == 0) {
            dispatch(checkForPlayer(props.selectedPlayer.id));
        } else {
            dispatch(callForPlayer({playerId: selectedPlayer?.id, amount: callAmountForPlayer}));
        }
    }
    const foldAndTakeBreakForPlayer = () => {
        dispatch(foldForPlayer(selectedPlayer?.id));
    }
    const foldAndRemovePlayer = () => {
        dispatch(foldForPlayer(selectedPlayer?.id));
    }

    const promptActive = promptForPlayer !== undefined;
    const buttonClass = promptActive ? "hostControlsButton" : "hostControlsButton inactive";

    return (
        <>
            <SubpageHeader navigate={() => {props.navigate('ActForPlayer')}} title={"Act for Player: "+props.selectedPlayer?.name} />
            <div className="hostControlsSubpageContainer actForPlayerSubpage">
                <div className="actForPlayerSubpageButtonsContainer">
                    <div className={buttonClass} onClick={promptActive ? checkCallForPlayer : ()=>{}}>
                        <span className="buttonText">Check/Call for {selectedPlayer?.name}</span>
                    </div>
                    <div className={buttonClass} onClick={promptActive ? foldAndTakeBreakForPlayer : ()=>{}}>
                        <span className="buttonText">Fold & Take a Break for {selectedPlayer?.name}</span>
                    </div>
                    <div className={buttonClass} onClick={promptActive ? foldAndRemovePlayer : ()=>{}}>
                        <span className="buttonText">Fold & Remove {selectedPlayer?.name} from Table</span>
                    </div>
                </div>
            </div>
        </>
    )
}