import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

interface SubpageHeaderProps {
    navigate: any;
    title: string;
}

export const SubpageHeader = (props: SubpageHeaderProps) => {
    return (
        <div className="subpageHeaderContainer">
            <div onClick={props.navigate} className="navigateBack">
                <FontAwesomeIcon className="backIcon" icon={faAngleLeft} />
            </div>
            <div className="modalTitle">{props.title}</div>
        </div>
    )
}