// This component shows upon table creation, if tableInitialized is false in state > nextPrefs, 
// show the game selection screen, otherwise show the master startup screen.
import React from "react";
import GameTypeList from "../../../logic/selectGame/components/GameTypeList";
import { getNextPrefs } from "../../../logic/gameInstance/slice";
import { useSelector } from "react-redux";
import MasterStartup from "./MasterStartup";

export const GameSelectArea = () => {
    const nextPrefs = useSelector(getNextPrefs);

    return (
        <>
            {!nextPrefs?.tableInitialized && (
                <MasterStartup />
            )}
            {nextPrefs?.tableInitialized && (
                <GameTypeList />
            )}
        </>
    )
};


