import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNextPrefs } from "../../../../logic/gameInstance/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";


interface WinnersSectionProps {
    gameOptions: any;
}

// This is the winners section in the OptionsPage when picking a game or looking at "Rules" within the game
export const WinnersSection = (props: WinnersSectionProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const dispatch = useDispatch();

    const setHighLowSetting = useCallback((value) => {
        const prefsString = JSON.stringify({ highLowSetting: value }).replace(" ", "");
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    return (
        <div className="section">
            <div className="optionsSubSection optionTitle"> 
                <span>Winner(s):</span><br/>
                <div className="optionSelectionContainer">
                    {props.gameOptions?.OPTIONS?.WINNERS?.map((option: {DESCRIPTION: string, VALUE: string, DEFAULT?: boolean}) => {
                        const checked = nextPrefs?.highLowSetting === option.VALUE
                        return (
                            <div style={checked ? {color: "#FFF"} : {}} className="winnersOption" onMouseUp={() => setHighLowSetting(option.VALUE)}>
                                <label htmlFor="winners" className="radio">
                                    <input readOnly type="radio" id="winners" name="winners" className="radioInput" checked={nextPrefs.highLowSetting === option.VALUE} />
                                    <div className="customRadio"></div>
                                    <div className="radioOptionText">
                                        {option.DESCRIPTION}
                                    </div>
                                </label>
                            </div>);
                    })}
                </div>
            </div>
        </div>
    )
}

