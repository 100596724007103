import "./Settings.css";
import React, {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";

import { getDefaultSettings } from "poker-cows-common";
import { selectGameType } from "../../../logic/gameInstance/slice";
import {
    getGameOptions, getGameRules 
} from "../../../utils/GameInfo";
import { dealerChangePrefs } from "../../../logic/prompts/slice";
import { OptionsPage } from "./Options/OptionsPage";
import { RulesPage } from "./Rules/RulesPage";

interface SettingsProps {
    rulesFirst: boolean
}

export const Settings = (props: SettingsProps) => {
    const dispatch = useDispatch();
    const rawGameName = useSelector(selectGameType);
    const gameRules = useMemo(() => getGameRules(rawGameName), [rawGameName]);
    const gameOptions = useMemo(() => getGameOptions(rawGameName), [rawGameName]);
    const [showRulesScreen, setShowRulesScreen] = useState(props.rulesFirst);

    useEffect(() => {
        // update nextPrefs with default rules
        const prefsString = JSON.stringify(getDefaultSettings(rawGameName)).replace(" ", "");
        dispatch(dealerChangePrefs(prefsString));

    }, [rawGameName]);

    return (
        <div style={{
            overflow: "auto", borderRadius: "5px", display: "flex", flexDirection: "column",
        }}>
            <div className="gameSetupButtonsContainer hostOptionRulesButtons">
                <div className={showRulesScreen ? "inactiveButton" : "activeButton"} onClick={() => setShowRulesScreen(false)}>OPTIONS</div>
                <div className={showRulesScreen ? "activeButton" : "inactiveButton"} onClick={() => setShowRulesScreen(true)}>RULES</div>
            </div>
            {!showRulesScreen ? <OptionsPage props={{gameRules: gameRules, gameOptions: gameOptions}}/> : <RulesPage props={{gameRules: gameRules}} /> }
        </div>);

};
