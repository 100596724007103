import React from "react";
import { useSelector } from "react-redux";
import { getNextPrefs } from "../../../../../logic/gameInstance/slice";

export const BettingStructureContent = () => {
    const nextPrefs = useSelector(getNextPrefs);

    const BetSchemes = {
        NO_LIMIT: 1,
        POT_LIMIT: 2,
        FIXED_LIMIT: 3,
        CAP_LIMIT: 4,
        SPREAD_LIMIT: 5,
    };

    return (
        <>
            {nextPrefs?.limitType == BetSchemes.FIXED_LIMIT ?
                <div className="betReadingsContainer">
                    <div className="ante">
                        Ante: ${nextPrefs?.ante}
                    </div>
                    <div className="minBet">
                        Min Bet: ${nextPrefs?.fixedLimitAmount}
                    </div>
                    <div className="maxBet">
                        Max Bet: ${nextPrefs?.fixedLimitAmount}
                    </div>
                </div>
                : null
            }
            {nextPrefs?.limitType === BetSchemes.SPREAD_LIMIT ?
                <div className="betReadingsContainer">
                    <div className="ante">
                        Ante: ${nextPrefs?.ante}
                    </div>
                    <div className="minBet">
                        Min Bet: ${nextPrefs?.spreadMinRaise}
                    </div>
                    <div className="maxBet">
                        Max Bet: ${nextPrefs?.spreadMaxRaise}
                    </div>
                </div>
                : null
            }
        </>
    )
}