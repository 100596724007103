import React from "react";

export const RulesPage = ({props}: any) => {
    return (
    <>
        <div className="section">
            <div className="sectionTextContent">
                <span className="sectionTextTitle">Cards:</span>
                <br />
                <span className="sectionBulletPoints">
                    {props.gameRules?.RULES?.CARDS.map((cardRule: string) => <>{cardRule}<br /></>)}
                </span>
                <br />
                <br />
                <div className="objective">Object: <span className="objectiveText"> {props.gameRules?.RULES?.OBJECT}</span></div>
            </div>
            <div className="sectionImages">
                {props.gameRules?.RULES?.IMAGES?.PLAYER ? <img src={props.gameRules.RULES?.IMAGES?.PLAYER}></img> : undefined}
                {props.gameRules?.RULES?.IMAGES?.COMMUNITY ? <img src={props.gameRules.RULES?.IMAGES?.COMMUNITY}></img> : undefined}
            </div>
        </div>
        <div className="section">
            <div className="sectionTextContent">
                <span className="sectionTextTitle">Action:</span><br />
                <div className="actionText">
                    {props.gameRules?.RULES?.ACTIONS?.map((item: string) => <>{item}<br /></>)}
                </div>
            </div>
        </div>
    </>)
}