import React, { useMemo, useState } from "react";
import { BettingStructureContent } from "./BettingStructureContent";
import { CheckRaisingContent } from "./CheckRaisingContent";
import { ChipDenominationContent } from "./ChipDenominationContent";
import { LastCardUpCostContent } from "./LastCardUpCostContent";
import { MaxRaisesContent } from "./MaxRaisesContent";
import { RulesRow } from "../RulesRow";

export const TableRules = ({props}: any) => {
    return (
        
        <div className="tableRules">
            <RulesRow title={"Betting Structure"} content={<BettingStructureContent/>} />
            <RulesRow title={"Chip Denominations"} content={<ChipDenominationContent/>} />
            <RulesRow title={"Check Raising Allowed?"} content={<CheckRaisingContent/>} />
            <RulesRow title={"Max Raises"} content={<MaxRaisesContent/>} />
            <RulesRow title={"Last Card Up Cost"} content={<LastCardUpCostContent/>} />
        </div>
        
    );
};