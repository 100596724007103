import "./Modal.css";
import React, {useCallback} from "react";
import {
    selectModalVisible,
    toggleModal
} from "../../logic/modal/slice";
import {
    useDispatch, useSelector
} from "react-redux";

interface Props {
    id: string;
    children?: React.ReactNode;
    defaultVisible?: boolean;
    hideClose?: boolean;
}

export function useModal(modalId: string) {
    const dispatch = useDispatch();

    const setVisible = useCallback((visible: boolean) => {
        dispatch(toggleModal({
            id: modalId,
            visible,
        }));
    }, [dispatch, modalId]);

    const show = useCallback(() => setVisible(true), [setVisible]);
    const hide = useCallback(() => setVisible(false), [setVisible]);
    const visible = useSelector(selectModalVisible(modalId));
    const toggleVisible = useCallback(() => setVisible(!visible), [visible, setVisible]);

    return {
        setVisible,
        toggleVisible,
        show,
        hide,
        visible,
        id: modalId,
    };
}

const Modal = ({ id, children, hideClose }: Props) => {
    const modal = useModal(id);

    if (!modal.visible) {
        return null;
    }

    return (
        <div>
            <div className="modal-overlay" onClick={modal.hide} />
            <div className="modal">
                {!hideClose && (<div className="exitModalButton" onClick={modal.hide}></div>)
                }
                <div className="modal-contents">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
