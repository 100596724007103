import {
    MutableRefObject, useEffect, useMemo, useRef
} from "react";
import {
    attachLocalMedia,
    attachMedia, connect, selectConnected, selectLatestMedia, selectTwilioId, selectVideoHidden
} from "./slice";
import {
    selectTableId, selectUserByTablePos
} from "../table/slice";
import {
    useDispatch, useSelector
} from "react-redux";
import {selectTwilioKey} from "../user/slice";
import {useSeatContext} from "../../ui/components/Seat/SeatContext";

//This should be 1-1 with users who have a video/audio connection
export function useTwilioPeer() {
    const {tablePosition} = useSeatContext();
    const user = useSelector(selectUserByTablePos(tablePosition));
    const userId = useMemo(()=>user?.id, [user]);
    const twilioId = useSelector(selectTwilioId(userId));
    const connected = useSelector(selectConnected);
    const dispatch = useDispatch();
    const videoHidden = useSelector(selectVideoHidden);
    const {isClientSeat} = useSeatContext();

    const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
    const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;

    const latestVideo = useSelector(selectLatestMedia(userId, "video"));
    const latestAudio = useSelector(selectLatestMedia(userId, "audio"));

    useEffect(() => {
        if (!isClientSeat) return;
        if (!connected) return;
        if (videoHidden) return;

        dispatch(attachLocalMedia(videoRef));
    }, [videoRef, connected, videoHidden, dispatch, isClientSeat]);

    //Attach latest video
    useEffect(() => {
        if (isClientSeat) return;
        if (!latestVideo) return;
        if (!connected) return;

        dispatch(attachMedia(videoRef, twilioId, latestVideo));
    }, [dispatch, latestVideo, twilioId, videoRef, connected, isClientSeat]);

    //Attach latest audio
    useEffect(() => {
        if (isClientSeat) return;
        if (!latestAudio) return;
        if (!connected) return;

        dispatch(attachMedia(audioRef, twilioId, latestAudio));
    }, [dispatch, latestAudio, twilioId, audioRef, connected, isClientSeat]);

    return {video: videoRef, audio: audioRef};
}

//This should be singleton, in charge of managing connection
// as tokens and roomIds are changed
export function useTwilioClient() {
    const token = useSelector(selectTwilioKey);
    const roomId = useSelector(selectTableId);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!token || !roomId) {
            return;
        }

        dispatch(connect(token, roomId));
    }, [dispatch, token, roomId]);
}
