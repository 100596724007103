// This is a reusable dropdown input component for assigning dollar amounts to chips
import React from "react";

interface DenominationSelectProps {
    name: string,
    prefsName: string,
    options: Array<object>,
    onChange: Function,
    value: any
}

export const DenominationSelect = ( props: DenominationSelectProps ) => {
    return (
        <select value={props.value} className="denominationSelect" name={props.prefsName} id="" onChange={(event) => { props.onChange(event) }}>
            {props.options.map((option: any) => {
                // this makes sure that the 500.00 and 200.00 option are only displayed when
                // building the select box for initialBuyIn
                if (option.VALUE == 50000 || option.VALUE == 20000) {
                    return props.prefsName == 'initialBuyIn' ? <option key={option.VALUE} value={option.VALUE}>{option.TITLE}</option> : null
                } else {
                    return (
                        <option key={option.VALUE} value={option.VALUE}>{option.TITLE}</option>
                    )
                }
            })}
        </select>
    )
}