import React, {
    useMemo, useState
} from "react";
import GameTypeList from "../../../../../logic/selectGame/components/GameTypeList";
import {
    getGameOptions, getGameRules
} from "../../../../../utils/GameInfo";
import { CurrentGame } from "../CurrentGame/CurrentGame";

export const AllGames = ({props}: any) => {
    const [selectedGame, setSelectedGame] = useState("TEXAS_HOLDEM");
    const [gameIsSelected, setGameIsSelected] = useState(false);
    const gameRules = useMemo(() => getGameRules(selectedGame), [selectedGame]);
    const gameOptions = useMemo(() => getGameOptions(selectedGame), [selectedGame]);
    const onGameClick = (game: any) => {
        setSelectedGame(game);
        setGameIsSelected(true);
    };

    return (
        <>
            {!gameIsSelected && <GameTypeList isStatic={true} clickAction={onGameClick} />}
            {gameIsSelected &&
                <>
                    <div className="actionButton backToAllGamesButton" onClick={() => setGameIsSelected(false)}>
                        &lt; BACK TO ALL GAMES
                    </div>
                    <CurrentGame gameRules={gameRules} gameOptions={gameOptions}/>
                </>
            }
        </>
    );
};
