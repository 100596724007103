import "./Seat.css";
import {
    isSeatTaken, selectSeatedStatus
} from "../../../logic/table/slice";
import {useSelector} from "react-redux";
import React, {useMemo} from "react";
import {
    isOnBreak, selectPlayerByTablePos
} from "../../../logic/player/slice";
import { PlayerBet } from "../PlayerBet";
import {
    useCurrentPlayer, useWinners
} from "../../../logic/gameInstance/hooks";
import {PlayerRow} from "./PlayerRow";
import { getAnyDelayPrompt } from "../../../logic/prompts/slice";
import {
    getPrefs,
    isUserHost, selectDealer, selectDeclarations
} from "../../../logic/gameInstance/slice";
import { TakeBreakIndicator } from "./TakeBreakIndicator";
import { DealerIndicator } from "./DealerIndicator";
import { Winnings } from "./Winnings";
import { BankButton } from "./BankButton";
import { TurnIndicator } from "./TurnIndicator";
import {ErrorBoundary} from "../../../error_handling/ErrorBoundary";
import {useSeatContext} from "./SeatContext";
import {useAllCardsOut} from "../../../logic/cards/allCardsOut";
import {KickButton} from "./KickButton";
import {
    EXPERIMENTAL_FEATURES, useExperimentalFeature
} from "../../../logic/features/FeatureContext";
import { PlayAutomaticallyButton } from "./PlayAutomaticallyButton";

const Seat = () => {
    const isHost = useSelector(isUserHost);
    const dealer = useSelector(selectDealer);
    const {tablePosition, isClientSeat} = useSeatContext();
    const player = useSelector(selectPlayerByTablePos(tablePosition));
    const { amWinner } = useWinners({ tablePosition });
    const winnerPrompt = useSelector(getAnyDelayPrompt);
    const { isCurrentPlayer } = useCurrentPlayer(tablePosition);
    const isTaken = useSelector(isSeatTaken(tablePosition));
    const isSeated = useSelector(selectSeatedStatus);
    const onBreak = useSelector(isOnBreak(tablePosition));
    const allCardsOut = useAllCardsOut();
    const kickFeature = useExperimentalFeature(EXPERIMENTAL_FEATURES.KICK_PLAYERS);
    const botFeature = useExperimentalFeature(EXPERIMENTAL_FEATURES.PLAY_AUTOMATICALLY);
    const prefs = useSelector(getPrefs);

    const isDealer = useMemo(() => dealer === player?.id, [dealer, player?.id]);

    const declarations = useSelector(selectDeclarations);
    const declaration: string = declarations[tablePosition] ?? "";

    let seatContainerClass = "seatContainer" + ((isCurrentPlayer && allCardsOut) ? " active" : "");

    if (amWinner && winnerPrompt) {
        seatContainerClass += " winner";
        if (declaration === "High" || prefs?.highLowSetting === "High") seatContainerClass += " declaredHigh";
        if (declaration === "Low" || prefs?.highLowSetting === "Low") seatContainerClass += " declaredLow";
    }
    if (isSeated && !isTaken) seatContainerClass += " open";

    return (
        <div className={seatContainerClass}>
            {isHost && <BankButton tablePosition={tablePosition} />}
            {kickFeature.isEnabled && isHost && <KickButton position={tablePosition} />}
            { amWinner && winnerPrompt && <Winnings tablePosition={tablePosition} isClientWinnings={isClientSeat} />}
            {isCurrentPlayer && <TurnIndicator />}
            {isClientSeat && botFeature.isEnabled && isHost && <PlayAutomaticallyButton/>}
            {isDealer && <DealerIndicator />}
            { onBreak && <TakeBreakIndicator />}
            <PlayerBet tablePosition={tablePosition} />
            <ErrorBoundary><PlayerRow /></ErrorBoundary>
        </div>
    );
};

export default Seat;
