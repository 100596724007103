import { DENOMINATIONS } from "poker-cows-common/";

// all available chip denominations (as seen on masterStartup screen)

export const Denominations = {
    VALUES: [
        {
            TITLE: "1¢",
            VALUE: DENOMINATIONS.ONE_CENT
        },
        {
            TITLE: "5¢",
            VALUE: DENOMINATIONS.FIVE_CENT
        },
        {
            TITLE: "10¢",
            VALUE: DENOMINATIONS.TEN_CENT
        },
        {
            TITLE: "25¢",
            VALUE: DENOMINATIONS.TWENTY_FIVE_CENT
        },
        {
            TITLE: "50¢",
            VALUE: DENOMINATIONS.FIFTY_CENT
        },
        {
            TITLE: "$1",
            VALUE: DENOMINATIONS.ONE_DOLLAR
        },
        {
            TITLE: "$2",
            VALUE: DENOMINATIONS.TWO_DOLLAR
        },
        {
            TITLE: "$5",
            VALUE: DENOMINATIONS.FIVE_DOLLAR
        },
        {
            TITLE: "$10",
            VALUE: DENOMINATIONS.TEN_DOLLAR
        },
        {
            TITLE: "$20",
            VALUE: DENOMINATIONS.TWENTY_DOLLAR
        },
        {
            TITLE: "$50",
            VALUE: DENOMINATIONS.FIFTY_DOLLAR
        },
        {
            TITLE: "$100",
            VALUE: DENOMINATIONS.HUNDRED_DOLLAR
        },
        {
            TITLE: "$200",
            VALUE: DENOMINATIONS.TWO_HUNDRED_DOLLAR
        },
        {
            TITLE: "$500",
            VALUE: DENOMINATIONS.FIVE_HUNDRED_DOLLAR
        }
    ]
}