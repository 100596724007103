import React from "react";
import { useSelector } from "react-redux";
import { selectPot } from "../../../../../logic/gameInstance/slice";
import { selectPlayersArray } from "../../../../../logic/player/slice";
import { SubpageProps } from "../../HostControlsModal";
import { SubpageHeader } from "../Components/SubpageHeader";
import { ChipTransfer } from "./ChipTransfer";
import { LedgerRow } from "./LedgerRow";


export const BankingLedger = (props: SubpageProps) => {
    const players = useSelector(selectPlayersArray);
    
    // get the net balance of every player and add together to make the "system net balance" for auditing
    let balanceArray:any = [];
    players.map((player: any) => {
        const balance = player.bank - player.deposit;
        balanceArray.push(balance);
    });

    // get the value of the pot
    const potAmount = useSelector(selectPot);

    // add it to the balanceArray
    if (potAmount) balanceArray.push(potAmount);

    const systemNetBalance = balanceArray.reduce((a: number, b: number) => a+b);

    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'Banking Ledger'} />
            <div className="hostControlsSubpageContainer bankingLedgerModalContainer">
                <div className="ledgerTable">
                    <LedgerRow isHeader={true} />
                    {players.map( (player: any) => {
                        const netBalance = player.bank - player.deposit;
                        return <LedgerRow playerName={player.name} bankBalance={player.deposit} chipBalance={player.bank} netBalance={netBalance} navigate={props.navigate} player={player} setSelectedPlayer={props.setSelectedPlayer} />
                    })}
                    <LedgerRow playerName="Current Pot Amount" bankBalance={0} chipBalance={potAmount} netBalance={potAmount} ></LedgerRow>
                    <LedgerRow isEndRow={true} systemNetBalance={systemNetBalance}/>
                    
                </div>
                <div className="chipTransferArea">
                    <ChipTransfer players={players}/>
                </div>
            </div>
        </>
    );
}