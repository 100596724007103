import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../logic/user/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { leaveGame, kickPlayer } from "../../../logic/prompts/slice";
import { getPlayerId } from "../../../logic/player/slice";
import { useSeatContext } from "../Seat/SeatContext";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

interface CashMeOutProps {
    navigate: any;
}

export const CashMeOut = (props: CashMeOutProps) => {
    const user = useSelector(selectUser);
    const { tablePosition } = useSeatContext();
    const dispatch = useDispatch();
    const playerId = useSelector(getPlayerId(tablePosition));

    const leaveGameClick = () => {
        dispatch(kickPlayer(playerId.toString()));
    }

    return (
        <>
            <div onClick={()=>{props.navigate(false)}} className="navigateBack">
                <FontAwesomeIcon className="backIcon" icon={faAngleLeft} />
            </div>
            <div className="tableLedgerTitle">{user?.name}'s Bank</div>
            <div className="section cashMeOutContainer">
                <div className="tableLedgerTitle">CASH ME OUT?</div>
                <div className="cashMeOutText">This cashes you out of the game and<br/>disconnects you from PokerCows.</div>
                <div onClick={leaveGameClick} className="leaveGameButton actionButton green">CASH ME OUT & LEAVE GAME</div> {/* TODO: make button work */}
                <div onClick={()=>{props.navigate(false)}} className="actionButton cancelButton playerBank">NO, CANCEL</div>
            </div>
        </>
    );
};