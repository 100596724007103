import {
    Action, combineReducers, configureStore
} from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { TwilioClient } from "./twilio/client";
import gameMenuReducer from "./gameMenu/slice";
import modalReducer from "./modal/slice";
import playerReducer from "./player/slice";
import promptsReducer from "./prompts/slice";
import selectGameReducer from "./selectGame/slice";
import tableReducer from "./table/slice";
import twilioReducer from "./twilio/slice";
import userReducer from "./user/slice";
import gameReducer from "./gameInstance/slice";
import cardsReducer from "./cards/slice";
import bettingReducer from "./betting/slice";
import cardSelectionReducer from "./cardSelection/slice";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

// Local objects //

const twilioClient = new TwilioClient();

const rootReducer = combineReducers({
    betting: bettingReducer,
    table: tableReducer,
    user: userReducer,
    twilio: twilioReducer,
    players: playerReducer,
    modal: modalReducer,
    selectGame: selectGameReducer,
    gameMenu: gameMenuReducer,
    prompts: promptsReducer,
    gameInstance: gameReducer,
    cardSelection: cardSelectionReducer,
    cards: cardsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<
    void,
    RootState,
    TwilioClient,
    Action<string>
>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

//TODO(@Nerdsie): Manage more specifically, don't just save and load complete redux state
export function makeStore(name: string) {
    return configureStore({
        reducer: rootReducer,
        devTools: {
            trace: true,
            traceLimit: 25,
            name: name,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: {extraArgument: twilioClient}}).concat(LogRocket.reduxMiddleware()),
        enhancers: defaultEnhancers => [...defaultEnhancers, sentryReduxEnhancer],
    });
}

const store = makeStore("Default");

export type AppDispatch = typeof store.dispatch;

export default store;
