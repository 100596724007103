/**
 * TableSocket.tsx
 * React hook for creating a websocket connection to server and receiving table update events
 */

import { io } from "socket.io-client";
import {
    useEffect, useState
} from "react";
import {useTableUpdater} from "./TableUpdater";
import {getWebsocket} from "../api";
import {setConnected} from "../slice";
import {useDispatch} from "react-redux";

export function useTableSocket(id: string) {
    const [socket, setSocket] = useState<ReturnType<typeof io>>();
    const updateTable = useTableUpdater();
    const dispatch = useDispatch();

    const setConnectedStatus = (state: boolean) => {
        dispatch(setConnected(state));
    };

    useEffect(() => {
        const newSocket = getWebsocket(id);
        setSocket(newSocket);
        newSocket.on("connect", () => setConnectedStatus(true));
        newSocket.on("disconnect", () => setConnectedStatus(false));
        newSocket.on("update_table", updateTable);
        return () => {
            newSocket.off("update_table", updateTable);
            newSocket.close();
        };
    }, [id, updateTable]);
}
