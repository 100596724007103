import "./Home.css";
import {
    selectJoined, selectTableId
} from "../../../logic/table/slice";
import CreateTableButton from "../../components/CreateTableButton";
import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {useDeveloper} from "../../components/Dev/hooks/useDeveloper";
import {CreateDevTable} from "../../components/Dev/CreateDevTable";
import JoinGameWidget from "../../components/JoinGameWidget";
import HostGameWidget from "../../components/HostGameWidget";
import Widgets from "../../components/Widgets";

const Home = () => {
    const joined = useSelector(selectJoined);
    const tableId = useSelector(selectTableId);
    const developer = useDeveloper();

    if (joined) {
        const url = "/table/" + tableId;
        return <Redirect to={url} />;
    }

    return (
        <div className="container">
            <div className="inner-container">
                <div className="hero-section">
                    <div className="logo" />
                    <h1>Play Classic Home Style Poker Games With Friends Over Video Chat</h1>
                    <h2>We're more than just Texas Hold'em...</h2>
                    <h2>Play classic poker games including: Follow The Queen, Iron Cross, The Slut, Seven Card Stud, and more. All completely FREE during the Poker Cows Open Alpha.</h2>
                    <CreateTableButton/>
                    {developer.isDeveloper && <CreateDevTable/>}
                </div>
                <div className="footer">
                    <div className="step">Step 1: Start A Game</div>
                    <div className="step">Step 2: Send the Link to Friends</div>
                    <div className="step">Step 3: Have Fun and Send Feedback to support@pokercows.com</div>
                </div>
                <Widgets/>                
            </div>
            
        </div>
    );
};

export default Home;
