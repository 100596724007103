import React from "react";

export const NonHostShutdown = () => {

    const handleClick = () => {
        location.href = "./";
    }
    return (
        <div className="endSessionBackground">
            <div className="sessionEndedModal">
                <div className="sessionEndedHeadline">YOU HAVE LEFT THE TABLE</div>
                <div className="sessionEndedSubtext">Your pokergame session has ended. A final report of your game balance will be emailed to you.</div>
                <div className="sessionEndedContinueButton actionButton green" onClick={handleClick}>CONTINUE</div>
            </div>
        </div>
    )
}