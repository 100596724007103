import TheSlutRulesImagePlayer from "../../static/graphics/rules/The_Slut_Player_Hand.svg";
import TheSlutRulesImageCommunity from "../../static/graphics/rules/The_Slut_Community_Cards.svg";

export const TheSlutRules = {
    RULES: {
        CARDS: [
            "• 4 player cards dealt down",
            "• 5 Community down cards",
        ],
        OBJECT: "Best 5 card poker hand using 2 player cards and 3 community cards.",
        ACTIONS: [
            "1. Each player is dealt 4 cards down with 5 community cards.",
            "2. Bet, starting with the player left of the dealer. Bet rotates each round.",
            "3. 1 community card flipped, bet. Repeat 2 more times.",
            "4. Players simultaneously discard 2 cards, bet.",
            "5. 1 community card flipped, bet. Repeat 1 time.",
            "6. Players declare “High” or “Low” (if a high-low split game)",
            "7. Cards displayed; winners paid",
        ],
        IMAGES: {
            PLAYER: TheSlutRulesImagePlayer,
            COMMUNITY: TheSlutRulesImageCommunity,
        },
    },
};
