import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useParams} from "react-router";
import {Table} from "./Table";
import {useDispatch} from "react-redux";
import {setUserId as setGlobalUserId} from "../../../logic/api";
import {setUserId} from "../../../logic/user/slice";
import "./SpectateTable.css";

function _SpectateTable() {
    const dispatch = useDispatch();
    const { id } = useParams() as any;

    useEffect(() => {
        dispatch(setUserId(1));
        setGlobalUserId("SPECTATE");
    }, []);

    return <div className="spectateContainer">
        <Table id={id} />
        <div className="spectateOverlay">You are spectating</div>
    </div>;
}

export const SpectateTable = withRouter(_SpectateTable);
