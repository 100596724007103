import "./TableLedger.css";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getAllBets, isUserHost
} from "../../../logic/gameInstance/slice";
import { selectPlayers } from "../../../logic/player/slice";
import { selectUser } from "../../../logic/user/slice";
import { BankingTransactionTypes } from "poker-cows-common";
import { TableLedgerRow } from "../Table/TableLedgerRow";
import { CashMeOut } from "./CashMeOut";
import { playerRequestChips, getPlayerRequestChipsPrompt } from "../../../logic/prompts/slice";
import { formatMoneyString } from "../../../utils/MoneyFormat";

const TableLedger = () => {
    const dispatch = useDispatch();
    const playerRequestChipsPrompt = useSelector(getPlayerRequestChipsPrompt);
    const [subpageIsVisible, setSubpageIsVisible] = useState(false);

    const navigate = (subpage: boolean) => {
        if (!subpage) {
            setSubpageIsVisible(false);
        } else {
            setSubpageIsVisible(true);
        }
    }
    const players = useSelector(selectPlayers);
    const currentBets = useSelector(getAllBets);
    const isHost = useSelector(isUserHost);
    const user = useSelector(selectUser);
    const [requestChipsValue, setRequestChipsValue] = useState('100');

    if (!players || Object.keys(players).length === 0) {
        return <div>No Players</div>;
    }

    let player: any;
    let bankingHistory: Array<{amount: number, datetime: number, description: string, type_arg: null}> = [];
    for (const [index, playerEntry] of Object.entries(players)) {
        if (playerEntry.name === user.name) {
            const name = playerEntry.name;
            player = playerEntry
            bankingHistory = player?.bankingHistory;   
        }
    }
    const makeDateString = (timestamp: number) => {
        const date = new Date(timestamp);
        // const string = (date.getMonth()+1)+'-'+(date.getDay())+'-'+(date.getFullYear())+' '+(date.getHours())+':'+(date.getMinutes())+' '+(Intl.DateTimeFormat().resolvedOptions().timeZone)
        const string= date.toISOString().substr(0, 19).replace('T', ' ');
        return string;
    }
    const requestChips = () => {
        const adjString = player.id + " " + (requestChipsValue ?? 0);
        dispatch(playerRequestChips(adjString));
    }

    return (
        <>
            {!subpageIsVisible && (
                <>
                    <div className="tableLedgerTitle">{user?.name}'s Bank</div>
                    <div className="section tableLedgerContainer">
                        <div className="tableContainer">
                            <div className="table">
                                <TableLedgerRow isHeader={true} leftContent="Date/Time" centerContent="Amount" rightContent="Description"/>
                                {bankingHistory.map((entry, i) => {
                                    const dateString = makeDateString(entry.datetime);
                                    return (
                                        <TableLedgerRow key={i} isHeader={false} leftContent={dateString} centerContent={formatMoneyString(entry.amount)} rightContent={BankingTransactionTypes[entry.description]} />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="playerBankRowsContainer">
                            <div className="playerBankRow">
                                <div className="playerBankIndicator totalBuyInsIndicator">
                                    <div className="playerBankIndicatorTitle">Total Buy Ins</div>
                                    <div className="playerBankIndicatorContent">-{formatMoneyString(player?.deposit)}</div>
                                </div>
                                <div className="playerBankIndicator currentChipCountIndicator">
                                    <div className="playerBankIndicatorTitle">Current Chip Count</div>
                                    <div className="playerBankIndicatorContent">{formatMoneyString(player?.bank)}</div>
                                </div>
                                <div className="playerBankIndicator netPlayerBalanceIndicator">
                                    <div className="playerBankIndicatorTitle">Net Player Balance</div>
                                    <div className="playerBankIndicatorContent">{formatMoneyString(player.bank - player.deposit)}</div>
                                </div>
                            </div>
                            <div className="playerBankRow">
                                <div className={playerRequestChipsPrompt !== undefined ? "chipRequestContainer" : "chipRequestContainer inactive"}>
                                    <div className="playerBankIndicatorTitle">Request Chips from the Bank</div>
                                    <select className={"requestChipsSelect"} name={"request-chips-value"} value={requestChipsValue} onChange={(select) => {setRequestChipsValue(select.target.value)}}>
                                        <option value={100 }>{formatMoneyString(100)}</option>
                                        <option value={500 }>{formatMoneyString(500)}</option>
                                        <option value={1000 }>{formatMoneyString(1000)}</option>
                                        <option value={2000 }>{formatMoneyString(2000)}</option>
                                        <option value={4000 }>{formatMoneyString(4000)}</option>
                                        <option value={5000 }>{formatMoneyString(5000)}</option>
                                        <option value={10000 }>{formatMoneyString(10000)}</option>
                                        <option value={20000 }>{formatMoneyString(20000)}</option>
                                        <option value={50000}>{formatMoneyString(50000)}</option>
                                    </select>
                                    <div onClick={requestChips} className="chipRequestButton actionButton green">REQUEST CHIPS</div>
                                </div>
                                <div className="actionButton cashMeOutButton playerBank" onClick={()=>{navigate(true)}}>Begin<br/>"Cash Me Out"</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {subpageIsVisible && <CashMeOut navigate={navigate}/>}
        </>
    );
};

export default TableLedger;
