import * as Requests from "./api";
import {
    createAsyncThunk, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { selectTableUsers } from "../table/slice";
import { updateTable } from "../shared";

////////////////// THUNKS //////////////////

export const updatePlayer = createAsyncThunk(
    "table/updatePlayer",
    async(payload: { tableId: string, playerId: string }) => (
        Requests.updatePlayer(payload.tableId, payload.playerId)
    )
);

export const updatePlayers = createAsyncThunk(
    "table/updatePlayers",
    async(tableId: string, thunkAPI: any) => {
        Array.from(selectTableUsers(thunkAPI.getState()).keys()).forEach((playerId: any) => {
            const player = selectTableUsers(thunkAPI.getState())[playerId];
            if (!player) return;
            thunkAPI.dispatch(updatePlayer({ tableId, playerId: player.id }));
        });
    }
);

/////////////////// SLICE //////////////////

export const initialState = { players: {} as any };

export const slice = createSlice({
    name: "player",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updatePlayer.fulfilled, (state, action) => {
            const player = action.payload.tableUser;
            state.players[player.seatPosition] = {
                ...state.players[player.seat_position],
                ...player,
            };
        });
        builder.addCase(updateTable, (state, action) => {
            const playerData = action.payload?.fullTable?.players;
            if (!playerData) return;
            state.players = playerData;
        });
    },
});

export default slice.reducer;
///////////// SELECTORS ////////////////

export const selectPlayers = (state: RootState): any => state.players.players || {};
export const selectPlayersArray = (state: RootState): any => Object.values(selectPlayers(state));
export const selectPlayerByTablePos = (tablePosition: number) => (state: RootState): any => (
    selectPlayersArray(state).find((p: any) => p.seatNumber === tablePosition) || {}
);
export const selectPlayerById = (playerId: string) => (state: RootState): any => (
    selectPlayersArray(state).find((p: any) => p.id === playerId) || {}
);
export const getPlayerBank = (tablePosition: number) => (state: RootState) => selectPlayerByTablePos(tablePosition)(state).bank;
export const getPlayerId = (tablePosition: number) => (state: RootState) => selectPlayerByTablePos(tablePosition)(state).id;
export const getPlayerName = (tablePosition: number) => (state: RootState) => selectPlayerByTablePos(tablePosition)(state).name;
export const selectPlayerCards = (tablePosition: number) => (state: RootState) => (
    selectPlayerByTablePos(tablePosition)(state).hand?._cards ?? []
);
export const selectHand = (tablePosition: number) => (state: RootState) => (
    selectPlayerByTablePos(tablePosition)(state).hand ?? ""
);
export const selectCardStack = (tablePosition: number) => (state: RootState) => (
    selectPlayerCards(tablePosition)(state) || []
);
export const hasCards = (tablePosition: number) => (state: RootState) => (
    selectCardStack(tablePosition)(state).length > 0
);
export const isOnBreak = (tablePosition: number) => (state: RootState) => (
    selectPlayerByTablePos(tablePosition)(state).takingBreak
);
export const isFaceUp = (tablePosition: number) => (state: RootState) => (
    selectPlayerCards(tablePosition)(state)[0]?.up
);

//TODO(@Nerdsie) - Replace with explicit data from backend
export const cardsDealt = (state: any) => {
    const players = selectPlayers(state);
    if (!players) return false;

    for (const key in players) {
        const player = players[key];

        if (selectCardStack(player.position)(state).length > 0) return true;
    }

    return false;
};
