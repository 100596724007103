import React, {useEffect} from "react";
import {
    useSelector, useDispatch
} from "react-redux";
import {
    selectCheckCallInTurn, selectFoldInTurn, selectTargetBetRound, setCheckCallInTurn, setFoldInTurn
} from "../../../../../logic/betting/slice";
import {useBetting} from "../hooks/Betting";
import {getPrefs} from "../../../../../logic/gameInstance/slice";
import {BetSchemes} from "../../../../../logic/gameMenu/components/GameRules";
import { selectFixedAmount } from "../../../../../logic/gameInstance/slice";


export function useAutomatedBet() {
    const dispatch = useDispatch();
    const checkCallInTurn = useSelector(selectCheckCallInTurn);
    const foldInTurn = useSelector(selectFoldInTurn);
    const fixedAmount = useSelector(selectFixedAmount);
    const targetBetRound = useSelector(selectTargetBetRound);
    const prefs = useSelector(getPrefs);

    let betting: any;
    let bettingProps: any;

    if (prefs.limitType === BetSchemes.FIXED_LIMIT) {
        bettingProps = {minRaise: fixedAmount, maxRaise: fixedAmount};
    }
    if (prefs.limitType === BetSchemes.SPREAD_LIMIT) {
        bettingProps = {minRaise: prefs.spreadMinRaise, maxRaise: prefs.spreadMaxRaise};
    }
    betting = useBetting(bettingProps);

    useEffect(() => {
        if (!betting.isTurnToBet) {
            return;
        }
        if (targetBetRound !== betting.betRoundId) {
            dispatch(setCheckCallInTurn(false));
            dispatch(setFoldInTurn(false));
            return;
        }
        if (checkCallInTurn) {
            dispatch(setCheckCallInTurn(false));
            if (betting.canCheck) {
                return betting.check();
            } else {
                return betting.call();
            }
        }
        if (foldInTurn) {
            dispatch(setFoldInTurn(false));
            return betting.fold();
        }
        return;
    }, [betting.isTurnToBet, betting.betRoundNumber, betting, targetBetRound, checkCallInTurn, foldInTurn, dispatch]);
}
