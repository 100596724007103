import React, {
    useCallback, useEffect
} from "react";
import { selectLoggedIn } from "../../../logic/table/slice";
import {
    useDispatch, useSelector
} from "react-redux";
import Login from "../login/Login";
import { Redirect, useParams } from "react-router";
import { withRouter } from "react-router-dom";
import { joinTable } from "../../../logic/table/thunks";
import { TableArea } from "../../components/Table/TableArea";
import {useTableStream} from "../../../logic/table/hooks/TableStream";
import LogRocket from "logrocket";
import {useTableSocket} from "../../../logic/table/hooks/TableSocket";
import { isUserHost, selectTableStatus } from "../../../logic/gameInstance/slice";
import { TABLE_STATUS } from "poker-cows-common";

const emptyFunc = () => undefined;

interface TableProps{
    id: string
    username?: string
    delay?: number
    eventHandler?: (event: string) => any
}

export const TableContext = React.createContext({eventCallback: (event: string) => undefined});

export const Table = (props: TableProps) => {
    const id = props.id;

    const dispatch = useDispatch();
    const loggedIn = useSelector(selectLoggedIn);
    const tableStatus = useSelector(selectTableStatus);
    const userIsHost = useSelector(isUserHost);

    useTableSocket(id);

    const sessionUsername = props.username ?? sessionStorage.getItem("username") ?? "";
    const success = sessionStorage.getItem("success-" + props.username) || false;
    const rejoining = props.username || (success && sessionUsername !== "" && !loggedIn);

    const dispatchLogin = useCallback((name: string) => {
        if (name.trim().length === 0) return;
        LogRocket.identify(id + "." + name, {
            tableId: id,
            nickname: name,
        });
        dispatch(joinTable({ tableId: id, nickName: name, stack: 50000 }));
    }, [dispatch, id]);

    //Track successful login via sessionStorage
    useEffect(() => {
        if (!loggedIn) return;

        sessionStorage.setItem("success-" + props.username, String(true));
    }, [loggedIn, props.username]);

    //Re-login if a rejoining
    useEffect(() => {
        if (!rejoining) return;

        const timer = setTimeout(() => {
            dispatchLogin(sessionUsername);
        }, props.delay ?? 0);

        return () => {
            clearTimeout(timer);
        };
    }, [dispatchLogin, rejoining, sessionUsername]);

    //If not logged in and not rejoining, display login screen
    if (!loggedIn && !rejoining) {
        return <Login login={dispatchLogin} defaultName={sessionUsername} />;
    }

    // If the players game/tableStatus is ARCHIVED, redirect to the appropriate "Session Ended" screen
    if (tableStatus === TABLE_STATUS.ARCHIVED) {
        const url = userIsHost ? "/end-session-host" : "/end-session-non-host";
        return <Redirect to={url}/>
    }

    //If logged in or rejoining, display table
    return <TableContext.Provider value={{eventCallback: props.eventHandler ?? emptyFunc}}>
        <TableArea />
    </TableContext.Provider>;
};

const RoutedTable = () => {
    const { id } = useParams() as any;

    return <Table id={id} />;
};

export default withRouter(RoutedTable);
