import "./GameSelectPopup.css";
import React from "react";

interface GameSelectPopupProps {
    children: React.ReactNode;
    header: string;
}

export function GameSelectPopup(props: GameSelectPopupProps) {

    const children: any = props?.children;
    const firstChild = children[0];
    
    const popupClassName =  firstChild?.props?.children === "PLEASE SELECT A GAME" ? "gamePopup gameSelectPopup" : "gamePopup";
    
    return <div className={popupClassName}>
        <div className="gameSelectorTitle">{props.header}</div>
        <div className="gameSelectBody">
            {props.children}
        </div>
    </div>;
}
