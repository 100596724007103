import React from "react";
import HostGameWidget from "./HostGameWidget";
import JoinGameWidget from "./JoinGameWidget";
import "./Widgets.css";


const Widgets = () => {
    return (
        <div className = "widgetContainer">
            <JoinGameWidget/>
            <HostGameWidget/>
        </div>
    )
}
export default Widgets;