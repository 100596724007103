
import { truncate, truncateSync } from "fs";
import React from "react";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";

interface LedgerRowProps {
    playerName?: string;
    bankBalance?: number;
    chipBalance?: number;
    netBalance?: number;
    isEndRow?: boolean;
    isHeader?: boolean;
    systemNetBalance?: number;

    navigate?: any;
    player?: any;
    setSelectedPlayer?: any;
}

export const LedgerRow = (props: LedgerRowProps) => {

    const playerClick = (player: any) => {
        props.setSelectedPlayer(player);
        props.navigate('BankingLedgerSubpage');
    }

    if (props.isHeader) {
        return (
            <div className="ledgerRow">
                <div className="leftColumn">Player</div>
                <div className="col">Bank Balance</div>
                <div className="col">Chip Balance</div>
                <div className="rightColumn" style={{flex: "1"}}>Net Balance</div>
            </div>
        )
    }

    if (props.isEndRow) {
        return (
            <div className="ledgerRow">
                <div className="leftColumn" style={{flex: "1", borderRight: "1px solid transparent", borderLeft: "1px solid transparent"}}></div>
                <div className="col" style={{flex: "1", borderRight: "1px solid transparent", borderLeft: "1px solid transparent"}}></div>
                <div className="col" style={{flex: "1", justifyContent: "flex-start", borderLeft: "1px solid transparent", fontSize: ".8em", alignItems: "center"}}>This should always equal zero</div>
                <div className="rightColumn" style={{flex: "1"}}>{formatMoneyString(props.systemNetBalance)}</div>
            </div>
        )
    }

    return (
        <div className="ledgerRow">
            <div className="leftColumn ledgerPlayerName" style={{flex: "1"}} onClick={()=>{playerClick(props.player)}}>{props.playerName}</div>
            <div className="col" style={{flex: "1"}}>{formatMoneyString(props.bankBalance)}</div>
            <div className="col" style={{flex: "1"}}>{formatMoneyString(props.chipBalance)}</div>
            <div className="rightColumn" style={{flex: "1"}}>{formatMoneyString(props.netBalance)}</div>
        </div>
    )
}