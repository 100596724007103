import React from "react";

export const ChipDenominationContent = () => {
    return (
        <div className="chipAreaRow chips">
            <div className="one-dollar-bet betChip"></div>
            <div className="two-dollar-bet betChip"></div>
            <div className="five-dollar-bet betChip"></div>
        </div>
    );
}


