import React, { ReactNode } from "react";

interface RulesRowProps {
    title: string;
    content: string | ReactNode;
    isTitleBold?: boolean;
}

export const RulesRow = (props: RulesRowProps) => {
    let titleClass = "title";
    titleClass = props.isTitleBold ? "boldTitle" : "title";
    return (
        <div className="rulesRow">
            <div className="leftColumn">
                <div className={titleClass}>{props.title}</div>
            </div>
            <div className="rightColumn">
                {props.content}
            </div>
        </div>
    )
}