import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNextPrefs } from "../../../../logic/gameInstance/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";

interface VersionSectionProps {
    gameRules: any;
    gameOptions: any;
}

const applyLineBreaks = (value: string, checked: boolean) => {
    const descriptionStrings = value?.split(" | ");
    return descriptionStrings?.map((line) => <span style={checked ? {color: "#FFF"} : {}} className="optionSelectionDescription">{line}<br /></span>);
}

// This is the Version section in the OptionsPage when picking a game or looking at "Rules" within the game,
// this is where you choose your 5 or 7 or 9 card etc. dealing versions
export const VersionSection = (props: VersionSectionProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const dispatch = useDispatch();

    const gameFlavorChange = useCallback( (flavor) => {
        const prefsString = JSON.stringify({gameFlavor: flavor});
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    return (
        <div className="section">
                <div className="sectionTextContent optionTitle">
                    <span>Version:</span><br />
                </div>

                <div className="gameFlavorOptionsContainer">
                    {props.gameOptions?.OPTIONS?.VERSION?.map((option: {TITLE: string, DESCRIPTION: string, FLAVOR: string}) => {

                        const checked = nextPrefs?.gameFlavor === option.FLAVOR;
                        const description = applyLineBreaks(option.DESCRIPTION, checked);
                
                        return (
                            <div className="gameFlavorOption" onMouseUp={() => {
                                gameFlavorChange(option.FLAVOR);
                            }}>
                                <label htmlFor="version" className="radio">
                                    <input readOnly type="radio" id="version" name="version" className="radioInput" checked={checked} />
                                    <div className="customRadio"></div>
                                    <div style={checked ? {color: "#FFF"} : {}} className="radioOptionText">
                                        {option.TITLE}
                                        {description.length > 1 ? description : null}
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
                
                { /* these are the images on the right side of the version section in options */ }
                <div className="sectionImages">
                    {props.gameRules?.RULES?.IMAGES?.PLAYER ? <img src={props.gameRules.RULES?.IMAGES?.PLAYER}></img> : undefined}
                    {props.gameRules?.RULES?.IMAGES?.COMMUNITY ? <img src={props.gameRules.RULES?.IMAGES?.COMMUNITY}></img> : undefined}
                </div>
            </div>
    )
}

