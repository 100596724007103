// This is a reusable dropdown input component for selecting players from a game
import React from "react";

interface PlayerSelectProps {
    name?: string,
    toOrFrom?: string,
    options: Array<object>,
    onChange: Function,
    value?: any
}

export const PlayerSelect = ( props: PlayerSelectProps ) => {
    return (
        <select value={props.value} className="playerSelect" name={props.toOrFrom} id="" onChange={(event) => { props.onChange(event)}}>
            {props.options.map((player: any) => {
                return <option key={player.id} value={player.id}>{player.name}</option>
            })}
        </select>
    )
}