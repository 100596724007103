import React from "react";
import { SubpageHeader } from "../Components/SubpageHeader";
import { TableLedgerRow } from "../../../Table/TableLedgerRow";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { useSelector } from "react-redux";
import { selectPlayers } from "../../../../../logic/player/slice";
import { selectUser } from "../../../../../logic/user/slice";
import { BankingTransactionTypes } from "poker-cows-common";

interface BankingLedgerPlayerSubpageProps {
    navigate: any;
    selectedPlayer: any;
}

export const BankingLedgerSubpage = (props: BankingLedgerPlayerSubpageProps) => {
    const players = useSelector(selectPlayers);
    const user = useSelector(selectUser);

    let player: any;
    let bankingHistory: Array<{amount: number, datetime: number, description: string, type_arg: null}> = [];
    for (const [index, playerEntry] of Object.entries(players)) {
        if (playerEntry.name === user.name) {
            const name = playerEntry.name;
            player = playerEntry
            bankingHistory = player?.bankingHistory;   
        }
    }
    const makeDateString = (timestamp: number) => {
        const date = new Date(timestamp);
        // const string = (date.getMonth()+1)+'-'+(date.getDay())+'-'+(date.getFullYear())+' '+(date.getHours())+':'+(date.getMinutes())+' '+(Intl.DateTimeFormat().resolvedOptions().timeZone)
        const string= date.toISOString().substr(0, 19).replace('T', ' ');
        return string;
    }

    return (
        <>
            <SubpageHeader navigate={() => {props.navigate('BankingLedger')}} title={"Banking Ledger: "+props.selectedPlayer?.name} />
            <div className="section tableLedgerContainer">
                <div className="tableContainer">
                    <div className="table">
                        <TableLedgerRow isHeader={true} leftContent="Date/Time" centerContent="Amount" rightContent="Description"/>
                        {bankingHistory.map((entry, i) => {
                            const dateString = makeDateString(entry.datetime);
                            return (
                                <TableLedgerRow key={i} isHeader={false} leftContent={dateString} centerContent={formatMoneyString(entry.amount)} rightContent={BankingTransactionTypes[entry.description]} />
                            )
                        })}
                    </div>
                </div>
                <div className="playerBankRow">
                    <div className="playerBankIndicator totalBuyInsIndicator">
                        <div className="playerBankIndicatorTitle">Total Buy Ins</div>
                        <div className="playerBankIndicatorContent">-{formatMoneyString(player?.deposit)}</div>
                    </div>
                    <div className="playerBankIndicator currentChipCountIndicator">
                        <div className="playerBankIndicatorTitle">Current Chip Count</div>
                        <div className="playerBankIndicatorContent">{formatMoneyString(player?.bank)}</div>
                    </div>
                    <div className="playerBankIndicator netPlayerBalanceIndicator">
                        <div className="playerBankIndicatorTitle">Net Player Balance</div>
                        <div className="playerBankIndicatorContent">{formatMoneyString(player.bank - player.deposit)}</div>
                    </div>
                </div>
            </div>
        </>
    )
}