import RollYourOwnRulesImagePlayer from "../../static/graphics/rules/Roll_Your_Own_Player_Hand.svg";

export const RollYourOwnRules = {
    RULES: {
        CARDS: [
            "• 7 cards dealt 3 down, 1 down, 1 down, 1 down, 1 down",
        ],
        OBJECT: "Best 5 card poker hand",
        ACTIONS: [
            "1. Each player is dealt 3 cards down.",
            "2. Players roll 1 card up (simultaneously) and bet, starting with the player showing the best hand.",
            "3. rounds of players receiving 1 down card, rolling and betting.",
            "4. Players declare “High” or “Low” (if a high-low split game)",
            "5. Cards displayed; winners paid",
        ],
        IMAGES: { PLAYER: RollYourOwnRulesImagePlayer },
    },
};
