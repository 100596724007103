import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

const initialState = {lastAnimated: 0};

export const slice = createSlice({
    name: "cards",
    initialState: initialState as any,
    reducers: {
        animate(state, action) {
            if (action.payload > state.lastAnimated) {
                state.lastAnimated = action.payload;
            }
        },
        resetLastAnimated(state) {
            state.lastAnimated = 0;
        },
    },
});

export default slice.reducer;
export const { animate, resetLastAnimated } = slice.actions;
