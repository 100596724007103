import {
    useDispatch, useSelector
} from "react-redux";
import {
    getCurrentPlayer, getPrefs, selectPaused,
    selectWinners
} from "./slice";
import {
    selectHand, selectPlayerByTablePos
} from "../player/slice";
import {
    useEffect, useMemo
} from "react";
import {endShowdownPhase} from "../prompts/slice";

export const SUITS = ["Clubs", "Diamonds", "Hearts", "Spades"];
export const RANKS = ["Ace", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Jack", "Queen", "King"];

interface Props {
    tablePosition: number;
    dispatch?: boolean
}

export const useWinners = (props: Props) => {
    const dispatch = useDispatch();
    const winners = useSelector(selectWinners);
    const prefs = useSelector(getPrefs);
    const paused = useSelector(selectPaused);
    const player = useSelector(selectPlayerByTablePos(props.tablePosition));
    const amWinner = useMemo(() => winners.includes(player.id), [winners, player.id]);
    const myHand = useSelector(selectHand(props.tablePosition ?? -1));

    useEffect(() => {
        if (!props.dispatch) return;
        if (paused) return;
        // this previously set the time limit to 10 seconds 
        // before automatically "passing the deck". 
        // we may implement a time limit again in the future 
        // but for now client want dealer or host to explicitly choose to pass the deck
        //
        // const id = setTimeout(() => {
        //     dispatch(endShowdownPhase());
        // }, (prefs?.showdownPauseTime ?? 10) * 1000);
        // // const id = dispatch(endShowdownPhase());
        // return () => {
        //     clearTimeout(id);
        // };
    }, [dispatch, paused, prefs?.showdownPauseTime, props.dispatch]);

    return { winners, amWinner, myHand };
};

export const useCurrentPlayer = (tablePosition: number) => {
    //const userIndex = useSelector(selectAdjustedSeatIndex(position));
    const currentPlayer = useSelector(getCurrentPlayer);
    const isCurrentPlayer = useMemo(() => currentPlayer && tablePosition === currentPlayer.seatNumber,
        [currentPlayer, tablePosition]);

    return { isCurrentPlayer, currentPlayer };
};
