import React from "react";
import { useDispatch } from "react-redux";
import {
    dismiss, showHand
} from "../../../logic/prompts/slice";
import "./PlayerShowdownArea.css";


export const PlayerShowdownArea = () => {
    const dispatch = useDispatch();

    const showHandClick = () => {
        dispatch(showHand());
    };

    const dismissClick = () => {
        dispatch(dismiss());
    };

    return (
        <div className="showdownActions">
            <div className="actionButton green" onClick={showHandClick}>Show Hand</div>
            <br />
            <div className="actionButton blue" onClick={dismissClick}>Dismiss</div>
        </div>
    );
};
