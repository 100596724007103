import {
    AppThunk, RootState
} from "../store";
import { createSlice } from "@reduxjs/toolkit";

enum STATE {
    DISCONNECTED,
    CONNECTING,
    RECONNECTING,
    CONNECTED
}

/////////////////// THUNKS /////////////////

export const connect = (token: string, room: string): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.connect(token, room, dispatch);
    };

export const attachMedia = (setMedia: (any), twilioId: string, mediaId: any): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.attachMedia(setMedia, twilioId, mediaId);
    };

export const attachLocalMedia = (video: (any)): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.attachLocalMedia(video);
    };

export const mute = (): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.mute();
    };

export const unmute = (): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.unmute();
    };

export const stopVideo = (): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.stopVideo();
    };

export const startVideo = (): AppThunk =>
    (dispatch, getState, twilioClient) => {
        twilioClient.startVideo();
    };

/////////////////// SLICE //////////////////

export const initialState = {
    users: {}, connected: false, muted: false, videoHidden: false,
} as any;

export const slice = createSlice({
    name: "twilio",
    initialState: initialState,
    reducers: {
        muted: (state) => {
            state.muted = true;
        },
        unmuted: (state) => {
            state.muted = false;
        },
        startedVideo: (state) => {
            state.videoHidden = false;
        },
        stoppedVideo: (state) => {
            state.videoHidden = true;
        },
        connected: (state: typeof initialState) => {
            state.connected = true;
        },
        reconnecting: (state: typeof initialState) => {
            state.connected = false;
        },
        subscribed: (state, action) => {
            const { userId, mediaId, mediaType } = action.payload;

            if (!state.users[userId]) {
                state.users[userId] = {};
            }

            if (!state.users[userId][mediaType]) {
                state.users[userId][mediaType] = [];
            }

            state.users[userId][mediaType].push(mediaId);
        },
        unsubscribed: (state, action) => {
            const { userId, mediaId, mediaType } = action.payload;

            if (!state.users[userId]) return;

            if (!state.users[userId][mediaType]) {
                state.users[userId][mediaType] = [];
            }

            state.users[userId][mediaType] = state.users[userId][mediaType].filter((id: string) => mediaId !== id);
        },
        participantConnected: (state, action) => {
            const { userId, twilioId } = action.payload;

            if (!state.users[userId]) {
                state.users[userId] = {};
            }

            state.users[userId].twilio_id = twilioId;
            state.users[userId].state = STATE.CONNECTED;
        },
        participantReconnecting: (state, action) => {
            const { userId, twilioId } = action.payload;

            if (!state.users[userId]) {
                state.users[userId] = {};
            }

            state.users[userId].twilio_id = twilioId;
            state.users[userId].state = STATE.RECONNECTING;
        },
    },
});

export const {
    subscribed,
    unsubscribed,
    participantConnected,
    participantReconnecting,
    connected,
    muted,
    unmuted,
    startedVideo,
    stoppedVideo,
    reconnecting,
} = slice.actions;

export default slice.reducer;

///////////// SELECTORS ////////////////
export const selectMuted = (state: RootState) => state.twilio.muted;
export const selectVideoHidden = (state: RootState) => state.twilio.videoHidden;
export const selectConnected = (state: RootState) => state.twilio.connected;
export const selectTwilioUser = (user_id: string) => (state: any) => (
    state.twilio.users[user_id] || null
);
export const selectMedia = (user_id: string, type: string) => (state: any) => {
    const user = selectTwilioUser(user_id)(state);

    if (!user) return null;
    return user[type];
};
export const selectLatestMedia = (user_id: string, type: string) => (state: any) => {
    const media = selectMedia(user_id, type)(state);
    if (!media) return;

    const length = media.length;

    if (length <= 0) return null;
    return media[length - 1];
};
export const selectTwilioId = (userId: string) => (state: any) => {
    const user = selectTwilioUser(userId)(state);


    if (!user) return null;
    return user.twilio_id;
};
