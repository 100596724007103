import React, { useMemo, useState } from "react";
import { RulesRow } from "../RulesRow";

export const HouseRules = ({props}: any) => {
    return (
        <>
            <div className="houseRulesTextArea">
                <span className="houseRulesTextAreaTitle">General:</span><br/>
                1. The person who orgainzed and set up the game is the <span className="keyword">Host</span>.<br/>
                2. The <span className="keyword">Host</span> will act as the <span className="keyword">Banker</span> for the game.<br/>
                3. The <span className="keyword">Host</span> will be the first dealer of the poker session.<br/>
                4. <span className="keyword">Dealer</span> will deal one hand, then pass the deck to the player to their left for the next deal.<br/>
                5. If there is a technical issue causing a lockup, or other issue that renders the current hand unable to complete, the Host will reset the game and reset the balance of every player to their balance at the close of the previous hand.<br/>
                6. Any disputes not  accounted for in House Rules, Table Rules, or Game Rules shall be resolved by the <span className="keyword">Host</span>. <span className="keyword">Host</span> shall have the authority to alter player chip balances if necessary.<br/>
            </div>
            {/* TODO: make left column title bold on House Rules page */}
            <div className="tableRules">
                <RulesRow title={"Topic"} content={"House Rule"} isTitleBold={true} />
                <RulesRow title={"Best Low Hand"} content={"6-4-3-2-A"} />
                <RulesRow title={"Best High Hand (No Wildcards)"} content={"Straight Flush"} />
                <RulesRow title={"Best High Hand (With Wildcards)"} content={"5 of a Kind"} />
                <RulesRow title={"Going Both Ways Rule"} content={"Tie or Lose either high or low, player loses both ways"} />
                <RulesRow title={"Lowest Hole Card for Low Chicago Game and Low Hole Wildcard Games"} content={"2"} />
                <RulesRow title={"Highest Hole Card for High Chicago Game"} content={"Ace"} />
                <RulesRow title={"Aces used as high or low in creating hands"} content={"Yes"} />
                <RulesRow title={"Lowest Pair of Cards"} content={"Pair of deuces (2,2)"} />
                <RulesRow title={"Highest Pair of Cards"} content={"Pair of Aces (A,A)"} />
                <RulesRow title={"Extra chip of uneven split pot goes to"} content={"1st winner of left of dealer"} />
                <RulesRow title={"Value of Up Wildcard when alone"} content={"Face Value"} />
                <RulesRow title={"Wildcard value in hand evaluations"} content={"Best possible value, Ie. Wild-8-5-3-1 beats Q-8-5-3-2"} />
                <RulesRow title={"Wildcard value and cards used can vary between high and low hands"} content={"Yes"} />
                <RulesRow title={"Draw Card Limit"} content={"3 (4 with an Ace)"} />
                <RulesRow title={"Raise/Bet increments = min bet"} content={"Yes"} />
                <RulesRow title={"# of Raises Allowed per Round"} content={"3"} />
                <RulesRow title={"Auto approve chip requests"} content={"Yes"} />
                <RulesRow title={"Auto Ante each player"} content={"Yes"} />
                <RulesRow title={"Cost to Buy Last Card Up"} content={"1x Min Bet*"} />
                <RulesRow title={"Check-Raise Allowed"} content={"Yes*"} />

                <p className="tableFootnote">*Can be changed by host in Table Settings Menu</p>
            </div>
        </>
    );
};