import React from "react";
import {useSeatContext} from "./SeatContext";
import { useSelector } from "react-redux";
import {getPlayerId} from '../../../logic/player/slice'
import {
    getPlayer727PassesRemaining
} from "../../../logic/gameInstance/slice";

export const PassesIndicator = () => {
    const {tablePosition} = useSeatContext();
    const playerId = useSelector(getPlayerId(tablePosition))
    const passes = useSelector(getPlayer727PassesRemaining(playerId))

    function getPassDot(index: number) {
        const classes = ["passDot"];
        if (index+1 > passes) classes.push("passDotFilled")
        return <div className={classes.join(" ")}/>;
    }

    function getPassDots() {
        const dots = [];
        for (let i = 0; i < 3; i++) {
            dots.push(getPassDot(i));
        }

        return dots;
    }

    return <div className="passesIndicator">
                {getPassDots()}
    </div>
};
