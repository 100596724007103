import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    acceptCard,
    declineCard,
    getAnteAction, getOfferCardPrompt, payAnte, skipAnte
} from "../../../logic/prompts/slice";
import "./AnteArea.css";
import {formatMoneyString} from "../../../utils/MoneyFormat";
import {selectUserBank} from "../../../logic/gameInstance/slice";


export const OfferCardArea = () => {
    const dispatch = useDispatch();

    const accept = () => {
        dispatch(acceptCard());
    };

    const skipClick = () => {
        dispatch(declineCard());
    };

    return (
        <div className="declareActions">
            <div className="declareButtonsContainer">
                <div className="anteActions">
                    <div className={"actionButton green"} onClick={accept}>Take a Card</div>
                    <br />
                    <div className="actionButton blue" onClick={skipClick}>No Card</div>
                </div>
            </div>
        </div>
    );
};
