import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    selectGameType, getPrefs, selectDealer
} from "../../logic/gameInstance/slice";
import { BetSchemes } from "../../logic/gameMenu/components/GameRules";
import {
    getGameOptions, getPrettyGameType
} from "../../utils/GameInfo";
import {
    getAnyChooseGamePrompt, getStartPrompt
} from "../../logic/prompts/slice";
import { HI_LO_SETTINGS } from "poker-cows-common/";
import { wildcardPrettyNames } from "../../data/rules/wildcardPrettyNames";
import { selectUserSeatNumber } from "../../logic/table/slice";

export const GameInfo = () => {
    const rawGameName = useSelector(selectGameType);
    const prefs = useSelector(getPrefs);
    const gameOptions = getGameOptions(rawGameName);

    const gamePrompt = useSelector(getAnyChooseGamePrompt);
    const startPrompt = useSelector(getStartPrompt);
    const dealer = useSelector(selectDealer);
    const seatNumber = useSelector(selectUserSeatNumber);
    const isDealer = dealer === seatNumber;

    let betStyle = prefs?.limitType;
    const highLow = prefs?.highLowSetting;
    const buyLastCardUpEnabled = prefs?.buyLastCardUpSetting > 0;
    const rollYourOwnEnabled = prefs?.rollYourOwnSetting > 0;
    const wildCardEnabled = prefs?.wildcardSetting;
    const wildcardName = wildCardEnabled ? wildcardPrettyNames[wildCardEnabled as keyof typeof wildcardPrettyNames] : "";

    if (betStyle === BetSchemes.FIXED_LIMIT) {
        betStyle = "Fixed Limit";
    }

    if (betStyle === BetSchemes.SPREAD_LIMIT) {
        betStyle = "Spread Limit";
    }

    const highLowPostfix = useMemo(() => {
        let working = highLow;

        if (highLow === HI_LO_SETTINGS.HIGH) working = "High Only";
        if (highLow === HI_LO_SETTINGS.LOW) working = "Low Only";

        return ` (${working})`;
    }, [highLow]);

    const gameRow = useMemo(() => {
        if (gamePrompt) return "";
        const gameName = getPrettyGameType(rawGameName);

        const gameHasMultipleVersions = gameOptions?.OPTIONS?.VERSION.length > 1;
        const flavor = gameOptions?.OPTIONS?.VERSION?.find((versionType: any) => versionType.FLAVOR == prefs?.gameFlavor);
        // the second conditional here turns off the version/flavor titling for
        // that long ugly version title in texas drop 'em
        const flavorName = gameHasMultipleVersions && flavor?.TITLE.indexOf("Equal") < 0 ? flavor?.TITLE + " " : "";

        return `${flavorName}${gameName}${highLowPostfix}`;
    }, [gamePrompt, rawGameName, highLowPostfix, prefs?.gameFlavor]);

    // is dealer and is selecting, show no game info (because it's behind prompt)
    if ((gamePrompt || startPrompt) && isDealer) return <></>;

    return (
        <div className="gameInfoHeader">
            {/* is NOT dealer and game has NOT been selected */}
            {(!isDealer && (gamePrompt || startPrompt)) && (
                <div className="game">Dealer selecting game.</div>
            )}
            {/* is NOT dealer and a game has been selected */}
            {(!isDealer && gameRow.length > 0) && (
                <div className="nameAndRules">
                    <div className="game">{gameRow}</div>
                </div>
            )}
            {/* IS dealer and game has started */}
            {(isDealer && !gamePrompt && !startPrompt) &&
                <div className="nameAndRules">
                    <div className="game">{gameRow}</div>
                </div>
            }

            {buyLastCardUpEnabled &&
                <div className="gameInfoText">Opt: Buy Last Card Up</div>}
            {rollYourOwnEnabled &&
                <div className="gameInfoText">Opt: Roll Your Own</div>}
            {(!buyLastCardUpEnabled && !rollYourOwnEnabled) &&
                <div className="gameInfoText">Opt: None</div>}

            {wildCardEnabled === "NONE" &&
                <div className="gameInfoText">Wild: None</div>
            }
            {wildCardEnabled !== "NONE" &&
                (<div className="gameInfoText">Wild: {wildcardName}</div>)
            }
        </div>
    );
};
