import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowUp,
    faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useDispatch} from "react-redux";
import {
    declareHigh, declareLow
} from "../../../logic/prompts/slice";
import "./DeclareArea.css";


export const DeclareArea = () => {
    const dispatch = useDispatch();

    const clickHigh = () => dispatch(declareHigh());
    const clickLow = () => dispatch(declareLow());

    return (
        <div className="declareActions">
            <div className="declareTitleText">DECLARE:</div>
            <div className="declareButtonsContainer">
                <div className="actionButton blue" onClick={clickLow}>
                    <div className="declareIcon">
                            <FontAwesomeIcon icon={faArrowDown} /> 
                        </div>
                        Low
                    </div>
                <div className="actionButton green" onClick={clickHigh}>
                    <div className="declareIcon">
                        <FontAwesomeIcon icon={faArrowUp} /> 
                    </div>
                    High
                </div>
            </div>
        </div>
    );
};
