import React from "react";
import { Denominations } from "../../../../data/settings/denominations";
import { DenominationSelect } from "../../ActionArea/MasterStartupComponents/DenominationSelect";

// this is a rulesRow component used in the masterStartup modal

interface InitialBuyInContentProps {
    startupPrefs: any,
    onChange: Function
}

export const InitialBuyInContent = (props: InitialBuyInContentProps) => {
    return (
        <div className="dropdownContainer">
            <div className="ante">
                <DenominationSelect value={props.startupPrefs.initialBuyIn} name="Buy In" prefsName={'initialBuyIn'} options={Denominations.VALUES} onChange={props.onChange} />
            </div> 
        </div>
    );
}