/**
 * FeaturesList.tsx
 *
 * UI for managing the state of experimental features.
 */

import React from "react";
import {useExperimentalFeatures} from "./FeatureContext";
import './FeaturesList.css';

export function FeaturesList() {
    const features = useExperimentalFeatures();
    return (
    <>
        <div className="modalTitle">Experimental Features</div>
        <p className="experimentalModalSubtitle">WARNING: These features are <b>EXPERIMENTAL</b> and can very likely result in a table breaking completely</p>
        <br />
        <div className="section experimentalModalContent">
            {features.getFeaturesList().map(feature => <span style={{cursor: "pointer"}}>
                <input style={{cursor: "pointer"}}  type="checkbox"
                    checked={features.isFeatureEnabled(feature)}
                    onChange={() => features.toggleFeature(feature)}/>
                    <span style={{userSelect: "none"}} onClick={() => features.toggleFeature(feature)}> {feature}</span><br/>
                <br />
            </span>)}
        </div>
    </>
    );
}
