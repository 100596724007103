import FiveCardStudRulesImagePlayer from "../../static/graphics/rules/Five_Card_Stud_Player_Hand.svg";

export const FiveCardStudRules = {
    RULES: {
        CARDS: [
            "• 5 cards dealt 1 down and 4 up",
        ],
        OBJECT: "Best 5 card poker hand",
        ACTIONS: [
            "1. Each Player is dealt 1 card down and 1 card up.",
            "2. Bet, starting with player showing best hand.",
            "3. 3 more rounds of cards dealt up with betting.",
            "4. Players declare “High” or “Low” (if a high-low split game)",
            "5. Cards displayed; winners paid",
        ],
        IMAGES: { PLAYER: FiveCardStudRulesImagePlayer },
    },
};
