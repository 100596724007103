import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { isUserHost } from "../../../logic/gameInstance/slice";
import {
    getPlayerId, getPlayerName
} from "../../../logic/player/slice";
import {kickPlayer} from "../../../logic/prompts/slice";

interface Props {
    position: number;
}

export const KickButton = ({ position }: Props) => {
    const dispatch = useDispatch();
    const isHost = useSelector(isUserHost);

    const playerId = useSelector(getPlayerId(position));
    const playerName = useSelector(getPlayerName(position));

    const className = isHost ? "kickButton bankButton" : "kickButton bankButton hidden";

    const click = () => {
        const kick: any = prompt(`Kick ${playerName}?`);

        dispatch(kickPlayer(playerId));
    };

    return (
        <div className={className} onClick={click}>Kick</div>
    );
};

