import React from "react";
import {
    useSelector
} from "react-redux";
import { getPrefs } from "../../../logic/gameInstance/slice";
import { BetSchemes } from "../../../logic/gameMenu/components/GameRules";
import { formatMoneyString } from "../../../utils/MoneyFormat";

export const GamePrefsInfo = () => {
    const prefs = useSelector(getPrefs);
    const isFixed = prefs?.limitType === BetSchemes.FIXED_LIMIT;

    return (
        <>
            {(prefs !== undefined) && (
                <div className="gamePrefsInfo">
                    <div className="gamePrefsItem">Ante: {formatMoneyString(prefs?.ante)}</div>
                    <div className="gamePrefsItem">Min: {formatMoneyString(isFixed ? prefs?.fixedLimitAmount : prefs?.spreadMinRaise)}</div>
                    <div className="gamePrefsItem">Max: {formatMoneyString(isFixed ? prefs?.fixedLimitAmount : prefs?.spreadMaxRaise)}</div>
                </div>
            )}
        </>
    );
};
