
import {
    getAlertPrompt, getAntePrompt, getAnyDelayPrompt, getTexasDelayPrompt,
    getBetPrompt,
    getChooseGamePrompt, getDeclarePrompt, getDelayOfGamePrompt, getDiscardCardsPrompt,
    getPlayerShowdownPrompt,
    getStartPrompt, submitTexasDelayPrompt, getAnyDealPrompt, getOfferCardPrompt, getDealPrompt, getBuyLastCardUpPrompt
} from "../../../logic/prompts/slice";
import { AlertArea } from "./AlertArea";
import BetArea from "./BetArea";
import { GameSelectArea } from "./GameSelectArea";
import { StartArea } from "./StartArea";
import React, {
    useContext, useEffect, useMemo
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { PlayerShowdownArea } from "./PlayerShowdownArea";
import { EndShowdownArea } from "./EndShowdownArea";
import { PlayerHand } from "../Hand";
import Seat from "../Seat/Seat";
import "./ActionArea.css";
import { PlayerBet } from "../PlayerBet";
import { useCurrentPlayer } from "../../../logic/gameInstance/hooks";
import { HandValue } from "./HandValue";
import { ChooseCardArea } from "./CardSelection/ChooseCardArea";
import { AnteArea } from "./AnteArea";
import {ErrorBoundary} from "../../../error_handling/ErrorBoundary";
import {DeclareArea} from "./DeclareArea";
import {
    getCardsDealt, selectPaused
} from "../../../logic/gameInstance/slice";
import {TableContext} from "../../views/table/Table";
import {useAllCardsOut} from "../../../logic/cards/allCardsOut";
import {OfferCardArea} from "./OfferCardArea";
import {DealArea} from "./DealArea/DealArea";
import {useSeatContext} from "../Seat/SeatContext";
import {WaitingArea} from "./WaitingArea";
import { BuyLastCardUpArea } from "./BuyLastCardUpArea";
import { FoldArea } from "./FoldArea";

const waitingCards = <div className="waitingText"/>;
const waitingNextGame = <EndShowdownArea />;
const pauseArea = <div className="waitingText">Host has paused the game</div>;

export const ActionArea = () => {
    const dispatch = useDispatch();

    const {tablePosition} = useSeatContext();
    const gamePrompt = useSelector(getChooseGamePrompt);
    const betPrompt = useSelector(getBetPrompt);
    const alertPrompt = useSelector(getAlertPrompt);
    const offerCardPrompt = useSelector(getOfferCardPrompt);
    const startPrompt = useSelector(getStartPrompt);
    const playerShowdownPrompt = useSelector(getPlayerShowdownPrompt);
    const discardCardsPrompt = useSelector(getDiscardCardsPrompt);
    const antePrompt = useSelector(getAntePrompt);
    const declarePrompt = useSelector(getDeclarePrompt);
    const delayPrompt = !!useSelector(getAnyDelayPrompt);
    const paused = useSelector(selectPaused);
    const texasDelay = useSelector(getTexasDelayPrompt);
    const dealPrompt = useSelector(getDealPrompt);
    const buyLastCardUpPrompt = useSelector(getBuyLastCardUpPrompt);

    const eventCallback = useContext(TableContext).eventCallback;

    const chooseWinner = useSelector(getDelayOfGamePrompt);

    const allCardsOut = useAllCardsOut();
    const { currentPlayer } = useCurrentPlayer(tablePosition);

    const waiting = useMemo(() => (
        <WaitingArea />
    ), [currentPlayer]);

    let visibleArea = (delayPrompt) ? waitingNextGame : waiting;
    let other = <></>;

    if (texasDelay && allCardsOut) dispatch(submitTexasDelayPrompt());
    if (chooseWinner) visibleArea = <EndShowdownArea />;
    if (offerCardPrompt) visibleArea = <OfferCardArea />;
    if (antePrompt) visibleArea = <AnteArea />;
    if (betPrompt) visibleArea = <BetArea />;
    if (gamePrompt) visibleArea = <GameSelectArea />;
    if (alertPrompt) visibleArea = <AlertArea />;
    if (startPrompt) visibleArea = <StartArea />;
    if (discardCardsPrompt) visibleArea = <ChooseCardArea />;
    if (declarePrompt) visibleArea = <DeclareArea />;
    if (dealPrompt) visibleArea = <DealArea />;
    if (buyLastCardUpPrompt) visibleArea = <BuyLastCardUpArea />;

    if (paused) visibleArea = pauseArea;
    if (playerShowdownPrompt) visibleArea = <PlayerShowdownArea />;

    if (chooseWinner) other = <EndShowdownArea dispatch={true}/>;

    const isWaiting = useMemo(() =>
        visibleArea === waiting || visibleArea === waitingCards || visibleArea === waitingNextGame
    , [visibleArea, waiting]);

    useEffect(() => {
        if (isWaiting) {
            eventCallback("waiting");
        } else {
            eventCallback("active");
        }
    }, [eventCallback, isWaiting]);

    return (<div className="actionArea">
        <ErrorBoundary>
            <div className="userCardBetColumn">
                <PlayerBet tablePosition={tablePosition} />
                <PlayerHand />
            </div>
        </ErrorBoundary>
        <ErrorBoundary>
            <HandValue tablePosition={tablePosition} />
        </ErrorBoundary>
        <ErrorBoundary>
            <div className="actionAreaContainer">
                {<FoldArea isDiscarding={discardCardsPrompt !== undefined} isWaiting={isWaiting} isBetting={betPrompt !== undefined} />}
                <Seat key={7}/>
                <div className="visibleActionArea">
                    {visibleArea}
                    <div className="removed">{other}</div>
                </div>
            </div>
        </ErrorBoundary>
    </div>);
};
