import {
    eventEndpoint,
    getEndpoint, postEndpoint, websocketEndpoint
} from "../api";

export async function createTable(name: string) {
    return postEndpoint("/tables", { name: name, numberOfPlayers: 7 });
}

export async function joinTable(tableInfo = {} as any) {
    return postEndpoint("/tables/" + tableInfo.tableId + "/join-table", tableInfo);
}

export async function getTable(tableId: string) {
    return getEndpoint("/tables/" + tableId);
}

export async function getTableStream(tableId: string) {
    return eventEndpoint("/tables/" + tableId + "/stream");
}

export function getWebsocket(tableId: string) {
    return websocketEndpoint({tableId});
}

export function chooseSeat(payload = {} as any) {
    const endpoint = "/tables/" + payload?.tableId + "/players/";
    const result = postEndpoint(endpoint, payload);

    if (result) result.then((body: any) => body.player);
}

export function resetGame(tableId: string) {
    return postEndpoint("/tables/" + tableId + "/game-actions", { actionType: "resetGame" });
}

//////////////////////////////
// PRIVATE HELPER FUNCTIONS //
//////////////////////////////
