import React from "react";
import { useSelector } from "react-redux";
import { getNextPrefs } from "../../../../../logic/gameInstance/slice";

export const MaxRaisesContent = () => {
    const nextPrefs = useSelector(getNextPrefs);

    return  (
        <div className="maxRaises">
            {nextPrefs?.maxRaises}
        </div>
    );
}