import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { undoHand } from "../../../../logic/prompts/slice";
import { selectTableId } from "../../../../logic/table/slice";
import { SubpageProps } from "../HostControlsModal";
import { SubpageHeader } from "./Components/SubpageHeader";

export const RestartHand = (props: SubpageProps) => {
    const dispatch = useDispatch()
    const tableId = useSelector(selectTableId);

    const undoHandClick = () => {
        const confirmed = window.confirm("Are you sure you want to undo the hand?");
        if (!confirmed) return;
        dispatch(undoHand(tableId));
        props.navigate('Main');
    };

    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'Host Controls'} />
            <div className={"restartHandModalContainer hostControlsSubpageContainer"}>
                <div className="restartHandTitleText">RESTART THIS HAND?</div>
                <div className="restartHandSubtext">This will cancel the current hand and return<br/>balances to the end of the previous hand.</div>
                <div className="restartHandRestartButton actionButton green" onClick={undoHandClick}>
                    YES, RESTART THIS HAND</div>
                <div className="restartHandCancelButton actionButton cancelButton" onClick={() => {props.navigate('Main')}}>NO, CANCEL</div>
            </div>
        </>
    );
}