import "./GameArea.css";
import React from "react";
import { BottomLeft } from "./BottomLeft";
import { TopLeft } from "./TopLeft";
import { TableColumn } from "./TableColumn";
import { TopRight } from "./TopRight";
import { BottomRight } from "./BottomRight";
import { ActionArea } from "../ActionArea/ActionArea";
import Pot from "../Pot";
import { TableCards } from "../TableCards";
import {useSelector} from "react-redux";
import {selectPot} from "../../../logic/gameInstance/slice";
import { useTwilioClient } from "../../../logic/twilio/hooks";
import { GameInfo } from "../GameInfo";
import {ErrorBoundary} from "../../../error_handling/ErrorBoundary";
import {SeatContext} from "../Seat/SeatContext";
import {selectConnectionState} from "../../../logic/table/slice";
import {ConnectionLost} from "../popup/ConnectionLost";

export const TableArea = () => {
    useTwilioClient();
    const pot = useSelector(selectPot);

    return (
        <div id="gameArea">
            <ConnectionLost />

            <TableColumn
                bottomContent={<BottomLeft />}
                screenPositions={[1, 2, 3]}
                className="left"
            />

            <div className="middle">
                <ErrorBoundary><GameInfo /></ErrorBoundary>
                <ErrorBoundary><TableCards /></ErrorBoundary>
                <Pot amount={pot} />
                <ErrorBoundary>
                    <SeatContext.Provider value={7}>
                        <ActionArea />
                    </SeatContext.Provider>
                </ErrorBoundary>
            </div>

            <TableColumn
                bottomContent={<BottomRight />}
                screenPositions={[4, 5, 6]}
                className="right"
            />
        </div>
    );
};
