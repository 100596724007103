export function formatMoneyString(amount?: number) {
    if (typeof amount !== 'number') {
        return ''
    }
    const initialString = (amount/100).toFixed(2);
    let formattedString = initialString;
    if (initialString.indexOf('.00') > -1) {
        formattedString = initialString.replace('.00', '');
    }
    formattedString = '$'+formattedString;
    if (amount < 0) {
        formattedString = '-'+formattedString.replace('-', '');
    }
    return formattedString;
}
