import React, {useState} from "react";
import { useEffect } from "react";
import { changePrefs } from "../../../../logic/prompts/slice";
import { useDispatch } from "react-redux";
import { RulesRow } from "../RulesPages/RulesRow";
import { ChipDenominationContent } from "../RulesPages/TableRules/ChipDenominationContent";
import { LastCardUpCostContent } from "../RulesPages/TableRules/LastCardUpCostContent";
import { MaxRaisesContent } from "../RulesPages/TableRules/MaxRaisesContent";
import { SubpageProps } from "../HostControlsModal";
import BettingStructure from "../../ActionArea/MasterStartupComponents/BettingStructure";
import { useSelector } from "react-redux";
import { getNextPrefs } from "../../../../logic/gameInstance/slice";
import { ChipDenomSetupContent } from "../../ActionArea/MasterStartupComponents/ChipDenomSetupContent";
import { SubpageHeader } from "./Components/SubpageHeader";

export const TableSettings = (props: SubpageProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const [newPrefs, setNewPrefs] = useState(nextPrefs ?? {});
    const dispatch = useDispatch();
    const [prefsChanged, setPrefsChanged] = useState(false);

    // this runs when the "start game" button is clicked, it's when the prefs are officially changed in state
    const savePrefsClick = () => {
        const prefsString = JSON.stringify(newPrefs).replace(" ", "");
        props.navigate('Main');
        dispatch(changePrefs(prefsString));
    };

    // set newPrefs, and tableInitialized to true so we know the masterStartup process has been triggered and dont show this screen again.
    useEffect(() => {
        setNewPrefs(nextPrefs ?? {});
        setNewPrefs({...newPrefs, tableInitialized: true});
        setPrefsChanged(JSON.stringify(nextPrefs) === JSON.stringify(newPrefs));
    }, [JSON.stringify(nextPrefs)]);

    // this runs when one of the dropdowns is changed and sets the new preferences
    const initialSettingsChange = (event: any) => {
        const update = {...newPrefs, [event.target.name]: parseFloat(event.target.value)};
        // enforce settings invariant i.e. make sure min === low =< mid =< hi =< max
        update.chipDenomLow = update.spreadMinRaise;
        update.chipDenomMid = Math.max(update.chipDenomMid, update.chipDenomLow);
        update.chipDenomHigh = Math.max(update.chipDenomHigh, update.chipDenomMid);
        update.spreadMaxRaise = Math.max(update.spreadMaxRaise, update.chipDenomHigh);

        setNewPrefs(update);
    }

    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'Table Settings'} />
            <div className="hostControlsSubpageContainer">
                <div className="table">
                    <RulesRow title="Betting Structure" content={<BettingStructure startupPrefs={newPrefs} onChange={initialSettingsChange} />}/>
                    <RulesRow title="Chip Denominations" content={<ChipDenomSetupContent startupPrefs={newPrefs} onChange={initialSettingsChange} />}/>
                    <RulesRow title="Max Raises Allowed" content={<MaxRaisesContent />}/>
                    <RulesRow title="Last Card Up Cost" content={<LastCardUpCostContent />}/>

                    <div className="buttons">
                        <div onClick={prefsChanged ? savePrefsClick : undefined} className={prefsChanged ? "startButton" : "startButton inactive"}>SAVE</div>
                    </div>
                </div>
            </div>
        </>
    );
}