import "./Card.css";
import React, {
    CSSProperties, useEffect, useMemo, useState
} from "react";
import {
    isCardPrivateWild,
    isCardWild,
    selectCardDelay, selectCardsDealt, selectWildCards
} from "../../logic/gameInstance/slice";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getSelectedCards, isCardSelected, Card as CardSelectInterface
} from "../../logic/cardSelection/slice";
import { getDiscardCardsPrompt } from "../../logic/prompts/slice";
import { useAllCardsOut } from "../../logic/cards/allCardsOut";
import {useSeatContext} from "./Seat/SeatContext";

export interface CardProps {
    rank: number,
    suit: number,
    up: boolean,
    showdownOverride: boolean,
    dealNumber: number,
    show?: boolean,
    styleClass?: string,
    style?: CSSProperties,
    selected?: boolean,
    tableCards?: boolean,
    metadata?: any
    cardClickCallback: (card: CardSelectInterface, isSelected: boolean) => void;
}

const Card = (props: CardProps) => {
    const {tablePosition} = useSeatContext();
    const cardData = { rank: props.rank, suit: props.suit };
    const tableCards = props.tableCards === undefined ? false : props.tableCards;
    const styleClass = props.styleClass === undefined ? "" : props.styleClass;
    const allCardsAnimated = useAllCardsOut();
    const selected = useSelector(isCardSelected(cardData));
    const isWild = useSelector(isCardWild(cardData));
    const isPrivateWild = useSelector(isCardPrivateWild(cardData, tablePosition));
    const toDiscard = useSelector(getSelectedCards);
    const selectPrompt = useSelector(getDiscardCardsPrompt);
    const isSelecting = !!selectPrompt && allCardsAnimated;
    const maxSelectAmount = selectPrompt?.data?.maxNumberOfCards;
    const discardsCount = useMemo(() => toDiscard.length, [toDiscard]);
    const texasDiscarded = props.metadata?.discarded;

    //orderDealt < 0 -- Delay reveal of already visible down cards
    const visible = useMemo(() => (props.up || props.show || texasDiscarded || props.showdownOverride),
        [props.up, props.show, texasDiscarded, props.showdownOverride]);

    const isUserHand = props.show;


    let className = styleClass + " card ";
    className += (visible) ? `suit-${(props.suit)}` : "down";
    if (!props.up && !tableCards) className += " hole-card";
    if (isWild) {
        className += " wild";
    } else if (isPrivateWild) className += " private-wild wild";
    if (selected) className += " selected";
    if (props.metadata?.selected) className += " backend-selected";
    if (texasDiscarded) className += " texasDiscarded";
    if (!isUserHand && !props.showdownOverride && isPrivateWild) className += " opponent-card";
    if (props.showdownOverride && !props.up) {
        className += " showdownOverride";
    }
    const value = <div className="cardValue">{getRankString(props.rank ?? -1)}</div>;
    const suitComponent = <span className="suit-icon" />;

    const dispatch = useDispatch();
    const clickCard = () => {
        if (discardsCount >= maxSelectAmount && !selected) return;
        if (texasDiscarded) return;
        if (props.up && selectPrompt?.data?.onlyAllowDownCards) return;
        if (isUserHand && isSelecting) {
            // allows a selected card to be unselected by clicking anywhere on the card while in the cardSelectArea - not when cards are grayed out in hand
            props.cardClickCallback(props, false);
        }
    };

    return (<div className={className} style={props.style} onClick={clickCard}>
        <div className="cardContent">
            <div className="left-details">{value}{suitComponent}</div>
            <div className="right-details">{suitComponent}</div>
        </div>
    </div>);
};

export function getRankString(rank: number): string {
    rank++;

    const ranks = new Map<number, string>();
    ranks.set(1, "A");
    ranks.set(11, "J");
    ranks.set(12, "Q");
    ranks.set(13, "K");

    const rankStr = ranks.get(rank);
    if (rankStr) return rankStr;

    return rank.toString();
}

export default Card;

