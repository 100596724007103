import React from "react";
import { Redirect } from "react-router-dom";
import "./SessionEnded.css";

export const HostShutdown = () => {
    const handleClick = () => {
        location.href = "./";
    }
    return (
        <div className="endSessionBackground">
            <div className="sessionEndedModal">
                <div className="sessionEndedHeadline">POKER SESSION ENDED</div>
                <div className="sessionEndedSubtext">You have ended your poker game session. A final report containing all player balances will be emailed to you.</div>
                <div className="sessionEndedSubtext">Thank you for hosting a game on PokerCows!</div>
                <div className="sessionEndedContinueButton actionButton green" onClick={handleClick}>CONTINUE</div>
            </div>
        </div>
    )
}