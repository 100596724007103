import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addTime, beginShutdown, shutdownNow } from "../../../../logic/prompts/slice";
import { SubpageProps } from "../HostControlsModal";
import { SubpageHeader } from "./Components/SubpageHeader";

export const CashOut = (props: SubpageProps) => {
    const [endGameSession, setEndGameSession]=useState(false);
    const dispatch = useDispatch();
    
    const handleBeginShutdown = () => {
        dispatch(beginShutdown());
        // cash players out
        // generate game report
        setEndGameSession(true);
    }

    const handleAddTime = () => {
        dispatch(addTime());
    }

    const handleShutdownNow = () => {
        dispatch(shutdownNow());
        setEndGameSession(false);
    }

    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'Host Controls'} />
                <div className="hostControlsSubpageContainer hostControlsCashoutContainer">

                    {/* header */}
                    <div className="hostControlsCashoutHeader">
                        {endGameSession ?
                            "YOUR POKER GAME SESSION IS OVER" :
                            "END THE GAME SESSION?"
                        }
                    </div>

                    {/* sub header */}
                    <div className="hostControlsCashoutSubtext">
                        {endGameSession ?
                            "Video chat and billing will remain active for:" :
                            "This cashes out all players and generates a game summary report."
                        }
                    </div>

                    {/* body contents before clicking end game */}
                    {!endGameSession && (
                        <>
                            <div>
                                <div className="restartHandRestartButton actionButton green" onClick={() => handleBeginShutdown()}>YES, END THE GAME SESSION *</div>
                                <div className="hostControlsCashoutDetail" >*Video chat remains active for 10 minutes.</div>
                            </div>
                            <div className="restartHandCancelButton actionButton cancelButton" onClick={() => {props.navigate('Main')}}>NO, CANCEL</div>
                        </>
                    )}

                    {/* body contents if clicked end game */}
                    {endGameSession && (
                        <>
                            <div>
                                <div className="timerNumbers" >9:59</div>
                                <div className="minutesText"> MINUTES </div>
                            </div>
                            <div>
                                <div className="restartHandRestartButton actionButton green" onClick={() => handleAddTime()}>ADD TEN MORE MINUTES VIDEO CHAT *</div>
                            </div>
                            
                            <div className="restartHandCancelButton actionButton cancelButton" onClick={() => handleShutdownNow()}> END VIDEO CHAT NOW & <br/> DISCONNECT FROM POKERCOWS</div>
                        </>
                    )}
            </div>
        </>
    );
}