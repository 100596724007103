import "./GameRules.css";
import React, {
    ChangeEvent, useEffect, useMemo, useState
} from "react";
import { selectedGame } from "../../selectGame/slice";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getNextPrefs, isUserHost, selectGameType
} from "../../gameInstance/slice";
import { changePrefs } from "../../prompts/slice";
import { getPrettyGameType } from "../../../utils/GameInfo";
import UndoHand from "../../../ui/components/UndoHand";
import PauseButton from "../../../ui/components/PauseButton";

export const ForcedBetSchemes = {
    BLINDS: 1,
    ANTE: 2,
};

export const BetSchemes = {
    NO_LIMIT: 1,
    POT_LIMIT: 2,
    FIXED_LIMIT: 3,
    CAP_LIMIT: 4,
    SPREAD_LIMIT: 5,
};

interface GameRulesProps {
    onSave: () => any
}

const GameRules = (props: GameRulesProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const [newPrefs, setNewPrefs] = useState(nextPrefs ?? {});
    const dispatch = useDispatch();

    const currentGameSelection = useSelector(selectedGame);
    const rawGameType = useSelector(selectGameType);

    const resetClick = () => setNewPrefs(nextPrefs ?? {});

    const isHost = useSelector(isUserHost);
    const hostOnlyStyle = isHost ? {} : { "display": "none" };

    const savePrefsClick = () => {
        const prefsString = JSON.stringify(newPrefs).replace(" ", "");
        dispatch(changePrefs(prefsString));
        props.onSave();
    };

    useEffect(() => {
        setNewPrefs(nextPrefs ?? {});
    }, [JSON.stringify(nextPrefs)]);

    const updateProperty = (propertyName: string, parseFunc?: (arg: string) => number) => (event: ChangeEvent<HTMLInputElement>) => {
        let valueFunc: any = () => event.target?.value;
        if (!parseFunc) parseFunc = parseInt;

        if (event.target?.type === "checkbox") {
            valueFunc = () => !newPrefs[propertyName];
        }

        let newValue: string | number | boolean = valueFunc();
        const rawValue = newValue;

        try {
            newValue = parseFunc(newValue.toString());
            if (event?.target?.type !== "number" && isNaN(newValue)) {
                newValue = rawValue;
            }
        } catch {
            if (event?.target?.type === "number") return;
            newValue = rawValue;
        }

        const temp = {
            ...newPrefs,
            [propertyName]: newValue,
        };

        setNewPrefs(temp);
    };

    // TODO write a function to prettify incoming game types
    const gameType = useMemo(() => getPrettyGameType(rawGameType), [rawGameType]);

    const blindSettingClass = "game-setting setting-detail blinds";
    const spreadLimitClass = "game-setting setting-detail spread-limit";
    const fixedLimitClass = "game-setting setting-detail fixed-limit";
    const potLimitClass = "game-setting setting-detail pot-limit";

    const allowBlinds = false;

    return (
        <div>
            {gameType ? "Rules for Current Game Mode: " + gameType : "Select a Game Mode to Edit Game Settings"}
            <hr />
            <form id="settings">
                <fieldset style={{ "border": "none" }} disabled={!isHost}>
                    <div className="game-setting">
                        <p>Forced Bet: </p>
                        {allowBlinds &&
                            <>
                                <input type="radio"
                                    id="blinds"
                                    value={ForcedBetSchemes.BLINDS}
                                    name="forced-bet-type"
                                    onChange={updateProperty("forcedBetType")}
                                    checked={newPrefs?.forcedBetType === ForcedBetSchemes.BLINDS}
                                />
                                <label htmlFor="blinds">Small / Big Blinds</label>
                            </>
                        }
                        <input type="radio"
                            id="ante"
                            value={ForcedBetSchemes.ANTE}
                            name="forced-bet-type"
                            onChange={updateProperty("forcedBetType")}
                            checked={newPrefs?.forcedBetType === ForcedBetSchemes.ANTE}
                        />
                        <label htmlFor="ante">Ante</label>
                    </div>

                    {newPrefs?.forcedBetType === ForcedBetSchemes.BLINDS ?
                        <div className={blindSettingClass}>
                            <label htmlFor="small-blind">Small Blind: $</label>
                            <input type="number"
                                id="small-blind"
                                value={newPrefs?.smallBlind}
                                onChange={updateProperty("smallBlind")}
                            /><br />
                            <label htmlFor="big-blind">Big Blind: $</label>
                            <input type="number"
                                id="big-blind"
                                value={newPrefs?.bigBlind}
                                onChange={updateProperty("bigBlind")}
                            />
                        </div>
                        : null}

                    {newPrefs?.forcedBetType === ForcedBetSchemes.ANTE ?
                        <div className={blindSettingClass}>
                            <label htmlFor="ante">Ante: $</label>
                            <input type="number"
                                id="ante"
                                value={newPrefs?.ante}
                                onChange={updateProperty("ante")}
                            /><br />
                        </div>
                        : null}

                    {newPrefs?.forcedBetType === ForcedBetSchemes.ANTE ?
                        <div className={blindSettingClass}>
                            <input type="checkbox"
                                id="auto-ante"
                                value="auto-ante"
                                name="auto-ante"
                                onChange={updateProperty("autoAnte")}
                                checked={newPrefs?.autoAnte}
                            />
                            <label htmlFor="auto-ante">Auto Ante</label>
                        </div>
                        : null}

                    <div className="game-setting">
                        <p>Betting Structure:</p>
                        <input type="radio"
                            id="fixed-limit"
                            value={BetSchemes.FIXED_LIMIT}
                            name="bet-structure"
                            onChange={updateProperty("limitType")}
                            checked={newPrefs?.limitType === BetSchemes.FIXED_LIMIT}
                        />
                        <label htmlFor="fixed-limit">Fixed Limit</label>
                        <input type="radio"
                            id="spread-limit"
                            value={BetSchemes.SPREAD_LIMIT}
                            name="bet-structure"
                            onChange={updateProperty("limitType")}
                            checked={newPrefs?.limitType === BetSchemes.SPREAD_LIMIT}
                        />
                        <label htmlFor="spread-limit">Spread Limit</label>
                    </div>

                    {newPrefs?.limitType === BetSchemes.FIXED_LIMIT ?
                        <div className={fixedLimitClass}>
                            <label htmlFor="min-raise">Fixed Bet Amount: $</label>
                            <input
                                type="number"
                                id="min-raise"
                                value={newPrefs?.fixedLimitAmount}
                                onChange={updateProperty("fixedLimitAmount")}
                            />
                        </div>
                        : null}

                    {newPrefs?.limitType === BetSchemes.SPREAD_LIMIT ?
                        <div className={spreadLimitClass}>
                            <label htmlFor="min-raise">Min. Raise: $</label>
                            <input type="number"
                                id="min-raise"
                                value={newPrefs?.spreadMinRaise}
                                onChange={updateProperty("spreadMinRaise")}
                            /><br />
                            <label htmlFor="max-raise">Max Raise: $</label>
                            <input type="number"
                                id="max-raise"
                                value={newPrefs?.spreadMaxRaise}
                                onChange={updateProperty("spreadMaxRaise")}
                            />
                        </div>
                        : null}

                    {newPrefs?.limitType === BetSchemes.POT_LIMIT ?
                        <div className={potLimitClass}>
                            <label htmlFor="pot-min-raise">Min Raise: $</label>
                            <input type="number" id="pot-min-raise" value="1, 2, 3... etc." onChange={() => null} />
                        </div>
                        : null}

                    <div className="game-setting title">
                        <p>Max # of Raises:</p>
                        <input type="number" value={newPrefs?.maxRaises ?? 3} onChange={updateProperty("maxRaises")} />
                    </div>

                    {isHost &&
                        <div className="game-setting title">
                            <p>Card delay (seconds):</p>
                            <input type="number" value={newPrefs?.cardDelay ?? 1} onChange={updateProperty("cardDelay", parseFloat)} />
                        </div>
                    }

                    {isHost &&
                        <div className="game-setting title">
                            <p>Showdown Pause Duration (seconds):</p>
                            <input type="number" value={newPrefs?.showdownPauseTime ?? 10} onChange={updateProperty("showdownPauseTime")} />
                        </div>
                    }
                </fieldset>

            </form>
            {isHost && (
                <div className={"bottomButtons"}>
                    <div>
                        <button onClick={savePrefsClick}>Save Settings for Next Game</button>
                        <button onClick={resetClick}>reset settings</button>
                    </div>
                    <div className={"dealerButtons"}>
                        <UndoHand />
                        <PauseButton />
                    </div>
                </div>
            )}
        </div>
    );
};

export default GameRules;
