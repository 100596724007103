/**
 * GameInfo.ts
 *
 * Organize various information about all the available games
 *
 * Information is based on the following figma, under "Select a game":
 *   - https://www.figma.com/file/RPfXM0eOC23wvmMby2oGBD/Poker-Cows---Final-Screens?node-id=9%3A136
 *
 * Information includes:
 *   - A human readable name
 *   - The category of the game
 *   - A React component for displaying the rules of the game
 */

 import {
    FiveCardDrawOptions,
    FiveCardStudOptions,
    FollowTheQueenOptions,
    GutsOptions,
    HighChicagoOptions,
    HollywoodSquaresOptions,
    IronCrossOptions,
    LowChicagoOptions,
    PassTheTrashOptions,
    RollYourOwnOptions,
    SevenCardStudOptions,
    SevenTwentySevenOptions,
    TexasDropemOptions,
    TexasHoldemOptions,
    TheSlutOptions,
} from "poker-cows-common";
import { FollowTheQueenRules } from "../data/rules/FollowTheQueenRules";
import { HollywoodSquaresRules } from "../data/rules/HollywoodSquaresRules";
import { IronCrossRules } from "../data/rules/IronCrossRules";
import { PassTheTrashRules } from "../data/rules/PassTheTrashRules";
import { SevenTwentySevenRules } from "../data/rules/SevenTwentySevenRules";
import { SevenCardStudRules } from "../data/rules/SevenCardStudRules";
import { FiveCardDrawRules } from "../data/rules/FiveCardDrawRules";
import { TexasHoldemRules } from "../data/rules/TexasHoldemRules";
import { TexasDropemRules } from "../data/rules/TexasDropemRules";
import { TheSlutRules } from "../data/rules/TheSlutRules";
import { HighChicagoRules } from "../data/rules/HighChicagoRules";
import { LowChicagoRules } from "../data/rules/LowChicagoRules";
import { GutsRules } from "../data/rules/GutsRules";
import { FiveCardStudRules } from "../data/rules/FiveCardStudRules";
import { RollYourOwnRules } from "../data/rules/RollYourOwnRules";

export const GAME_CATEGORIES = {
    CLASSIC: "CLASSIC GAMES",
    STUD: "STUD VARIATIONS",
    COMMUNITY: "COMMUNITY CARDS",
    OTHER: "COUNTING/OTHER",
};

const gameInfoMap: any = { 
    "FIVE_CARD_DRAW": {prettyName: "Five Card Draw", rules: FiveCardDrawRules, options: FiveCardDrawOptions, category: GAME_CATEGORIES.CLASSIC, },
    "FIVE_CARD_STUD": {prettyName: "Five Card Stud", rules: FiveCardStudRules, options: FiveCardStudOptions, category: GAME_CATEGORIES.CLASSIC, },
    "SEVEN_CARD_STUD": {prettyName: "Seven Card Stud", rules: SevenCardStudRules, options: SevenCardStudOptions, category: GAME_CATEGORIES.CLASSIC, },
    "TEXAS_HOLDEM": {prettyName: "Texas Hold'em", rules: TexasHoldemRules, options: TexasHoldemOptions, category: GAME_CATEGORIES.CLASSIC, },

    "FOLLOW_THE_QUEEN": {prettyName: "Follow The Queen", rules: FollowTheQueenRules, options: FollowTheQueenOptions, category: GAME_CATEGORIES.STUD, },
    "LOW_CHICAGO": {prettyName: "Low Chicago", category: GAME_CATEGORIES.STUD, rules: LowChicagoRules, options: LowChicagoOptions },
    "HIGH_CHICAGO": {prettyName: "High Chicago", category: GAME_CATEGORIES.STUD, rules: HighChicagoRules, options: HighChicagoOptions},
    "ROLL_YOUR_OWN": {prettyName: "Roll Your Own", category: GAME_CATEGORIES.STUD, rules: RollYourOwnRules, options: RollYourOwnOptions},

    "IRON_CROSS": {prettyName: "Iron Cross", rules: IronCrossRules, options: IronCrossOptions, category: GAME_CATEGORIES.COMMUNITY, },
    "HOLLYWOOD_SQUARES": {prettyName: "Hollymoo'd Squares", rules: HollywoodSquaresRules, options: HollywoodSquaresOptions, category: GAME_CATEGORIES.COMMUNITY, },
    "THE_SLUT": {prettyName: "The Slut", rules: TheSlutRules, options: TheSlutOptions, category: GAME_CATEGORIES.COMMUNITY, },

    "SEVEN_TWENTY_SEVEN": {prettyName: "7-27", rules: SevenTwentySevenRules, options: SevenTwentySevenOptions, category: GAME_CATEGORIES.OTHER, },
    "TEXAS": {prettyName: "Texas Drop'em", rules: TexasDropemRules, options: TexasDropemOptions, category: GAME_CATEGORIES.OTHER, },
    // "GUTS": {prettyName: "Guts", category: GAME_CATEGORIES.OTHER, rules: GutsRules, options: GutsOptions},
    "PASS_THE_TRASH": {prettyName: "Pass The Trash", rules: PassTheTrashRules, options: PassTheTrashOptions, category: GAME_CATEGORIES.OTHER, },
};

export function getGamesByCategory(category: string) {
    return Object.entries(gameInfoMap).filter(([key, value]: any) => value?.category === category);
}

export function getPrettyGameType(gameType: string) {
    if (!gameType) return "";

    const foundName = gameInfoMap[gameType]?.prettyName;
    if (foundName) return foundName;

    console.error(`Could not find human-friendly name for ${gameType}`);
    return gameType;
}

export function getGameRules(gameType: string) {
    if (!gameType) return "";

    const rules = gameInfoMap[gameType]?.rules;
    if (rules) return rules;

    console.error(`Could not find game rules for ${gameType}`);
    return gameType;
}

export function getGameOptions(gameType: string) {
    if (!gameType) return "";

    const rules = gameInfoMap[gameType]?.options;
    if (rules) return rules;

    console.error(`Could not find game options for ${gameType}`);
    return gameType;
}