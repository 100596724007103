//TODO
export async function getAvailableGameTypes() {
    return [
        { name: "seven-twenty-seven" },
        { name: "blackjack" },
        { name: "Go Fish" },
    ];
}

//TODO
export async function selectGame(gameSelection: string) {
    // will eventually be an  api post and return true
    return { "gameType": gameSelection };
}
