import * as Requests from "./api";
import {
    createAsyncThunk, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";


export const updateGameSettings = createAsyncThunk(
    "gameMenu/updateGameSettings",
    async() => Requests.getGameSettings()
);

export const initialState = {
    smallBlind: 5,
    bigBlind: 10,
    maxRaises: 3,
    timeBtwHands: 120,
    timeBeforeAutoFold: 45,
    allowCheckRaise: true,
};

export const slice = createSlice({
    name: "gameMenu",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateGameSettings.fulfilled, (state, action) => {
            state = { ...state, ...action.payload };
        });
    },
});

export default slice.reducer;

export const selectGameMenu = (state: RootState) => state.gameMenu;
export const selectSmallBlindSetting = (state: RootState) => state.gameMenu.smallBlind;
export const selectBigBlindSetting = (state: RootState) => state.gameMenu.bigBlind;
export const selectMaxRaisesSetting = (state: RootState) => state.gameMenu.maxRaises;
export const selectTimeBtwHandsSetting = (state: RootState) => state.gameMenu.timeBtwHands;
export const selectTimeBeforeAutoFoldSetting = (state: RootState) => state.gameMenu.timeBeforeAutoFold;
export const selectAllowCheckRaiseSetting = (state: RootState) => state.gameMenu.allowCheckRaise;
