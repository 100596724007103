import React, {useMemo, useState} from "react";
import "./ConnectionLost.css";
import {useSelector} from "react-redux";
import {selectConnectionState} from "../../../logic/table/slice";

export function ConnectionLost() {
    const isConnected = useSelector(selectConnectionState);

    const hiddenCssClass = isConnected ? "connection-lost-hidden" : "";

    return (
        <div className={"connection-lost " + hiddenCssClass}>
            <div className="connection-lost__text">
                <p>
                  Your connection to the server has been lost, attempting to reconnect...
                </p>
            </div>
        </div>
    );
}
