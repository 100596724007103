import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { adjustPlayerBank, hostTransferChips } from "../../../../../logic/prompts/slice";
import { formatMoneyString } from "../../../../../utils/MoneyFormat";
import { MoneyInput } from "./MoneyInput";
import { PlayerSelect } from "./PlayerSelect";

interface ChipTransferProps {
    players: any
}

export const ChipTransfer = (props : ChipTransferProps) => {
    const [chipTransfer, setChipTransfer ] = useState({fromPlayer: 0, toPlayer: 1, amount: '0'});
    const dispatch = useDispatch();

    useEffect(() => {
        setChipTransfer(chipTransfer ?? {});
    }, [chipTransfer]);

    const updateChipTransfer = (event: any) => {
        const update = {...chipTransfer, [event.target.name]:event.target.value };
        setChipTransfer(update);
    };

    const executeChipTransfer = () => {
        const argString = chipTransfer.fromPlayer + " " + chipTransfer.toPlayer + " " + (parseFloat(chipTransfer.amount)*100).toString();
        dispatch(hostTransferChips(argString));
    };

    return (
        <div className="chipTransferContainer">
            <div className="chipTransferHeader">TRANSFER CHIPS BETWEEN PLAYERS</div>
            <div className="chipTransfer">
                Take: ${<MoneyInput name="amount" onChange={updateChipTransfer} />} from {<PlayerSelect value={chipTransfer.fromPlayer} options={props.players} toOrFrom="fromPlayer" onChange={updateChipTransfer}/>} and give to {<PlayerSelect value={chipTransfer.toPlayer} options={props.players} toOrFrom="toPlayer" onChange={updateChipTransfer}/>}
            </div>
            <div className="restartHandRestartButton actionButton green" onClick={executeChipTransfer}>ADJUST CHIP BALANCES</div>
        </div>
    );
}