import {
    call as callReduxAction, check as checkReduxAction, fold as foldReduxAction, raise as raiseReduxAction, getBetPrompt
} from "../../../../../logic/prompts/slice";
import foldSfx from "../../../../../static/sounds/fold.wav";
import checkSfx from "../../../../../static/sounds/check.wav";
import callSfx from "../../../../../static/sounds/chips.wav";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getBet,
    getPrefs,
    selectBetRoundID,
    selectCallAmount,
    selectCurrentRaise,
    selectRaises,
    selectShowCall,
    selectUserBank,
    selectUserBet
} from "../../../../../logic/gameInstance/slice";
import {useMemo} from "react";

const playSound = (sfx: any) => {
    const sound = new Audio(sfx);
    sound.play();
};

interface BettingProps{
    minRaise: number;
    maxRaise: number;
}

export function useBetting(props: BettingProps) {
    const dispatch = useDispatch();
    const callAmount = useSelector(selectCallAmount);
    const currentBet = useSelector(getBet);
    const userBet = useSelector(selectUserBet);
    const preferences = useSelector(getPrefs);
    const currentRaise = useSelector(selectCurrentRaise);
    const userBank = useSelector(selectUserBank);
    const maxRaises = preferences?.maxRaises;
    const showCall = useSelector(selectShowCall);
    const numberOfRaises = useSelector(selectRaises);

    const minRaise = (preferences?.raiseMustBeAtLeastPreviousRaise) ? Math.max(props?.minRaise, currentRaise) : props?.minRaise;
    const maxRaise = useMemo(() => Math.min(props?.maxRaise, userBank), [props?.maxRaise, userBank]);

    const fold = (): void => actionWithSound(foldReduxAction(), foldSfx);
    const check = (): void => actionWithSound(checkReduxAction(), checkSfx);
    const call = (): void => actionWithSound(callReduxAction(callAmount), callSfx);
    const raiseTo = (amount: number): void => actionWithSound(raiseReduxAction(amount - userBet), callSfx);

    const betRoundId = useSelector(selectBetRoundID);
    const isTurnToBet = useSelector(getBetPrompt) != undefined;
    const canCheck = (callAmount <= 0);

    const actionWithSound = (action: any, sound: any) => {
        playSound(sound);
        dispatch(action);
    };

    return {
        fold,
        check,
        call,
        raiseTo,
        callAmount,
        currentBet,
        minRaise,
        maxRaise,
        userBet,
        currentRaise,
        userBank,
        maxRaises,
        showCall,
        numberOfRaises,
        isTurnToBet,
        canCheck,
        betRoundId,
    };
}
