import React, {
    ErrorInfo, ReactNode
} from "react";
import "./ErrorBoundary.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

export interface BoundaryState{
    error?: Error;
    errorInfo?: ErrorInfo;
    dismissed: boolean
}

interface BoundaryProps{
    children?: ReactNode;
    renderTemplate?: React.FunctionComponent<BoundaryState>;
}

const defaultState: BoundaryState = {
    error: undefined,
    errorInfo: undefined,
    dismissed: false,
};

export class ErrorBoundary extends React.Component<BoundaryProps, BoundaryState> {
    state: BoundaryState = {...defaultState};

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
            dismissed: false,
        });
        // You can also log error messages to an error reporting service here
    }

    dismissClick = () => {
        this.setState((state) => {
            return {
                ...state,
                dismissed: true,
            };
        });
    }

    render() {
        if (this.state.errorInfo) {
            if (this.props.renderTemplate) {
                const Render = this.props.renderTemplate;
                return <Render {...this.state} />;
            }

            const visibleStyle = this.state.dismissed ? "hidden" : "visible";

            // Error path
            return (
                <>
                    <div className={"error-message"} style={{visibility: visibleStyle}}>
                        <span className={"error-dismiss"} onClick={this.dismissClick}>X</span>
                        <b>An issue has occurred</b><br/>
                        Our team has already been notified, there is nothing you need to do.<br/>
                        <br/>
                        If something is not displaying correctly, refreshing might be able to fix the issue.
                    </div>
                    <div className={"error-icon-holder"}>
                        <FontAwesomeIcon className={"error-icon"} title="This element has crashed" icon={faExclamationCircle} />
                    </div>
                </>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
