import "./TableCards.css";
import React from "react";
import { useSelector } from "react-redux";
import {
    getGameType, getTableCards
} from "../../logic/gameInstance/slice";
import Hand from "./Hand";
import { GridTableCards } from "./GridTableCards";
import { HollywoodSquaresMap, IronCrossMap } from "poker-cows-common/";

export const TableCards = () => {
    const tableCards = useSelector(getTableCards);
    const gameType = useSelector(getGameType);

    if (gameType && gameType.includes("IRON_CROSS")) {
        return <GridTableCards gridLayoutMap={IronCrossMap} />;
    }

    if (gameType && gameType.includes("HOLLYWOOD_SQUARES")) {
        return <GridTableCards gridLayoutMap={HollywoodSquaresMap} />;
    }

    return (
        <div className="table-cards-container">
            <Hand tableCards={true} cardStack={tableCards} />
        </div>
    );
};
