import React from "react";
import { useSelector } from "react-redux";
import { selectFixedAmount } from "../../../../logic/gameInstance/slice";
import { BetSchemes } from "../../../../logic/gameMenu/components/GameRules";
import { useBetting } from "../BetArea/hooks/Betting";
import { getPrefs } from "../../../../logic/gameInstance/slice";

// this is the fold button, displayed to the left of the user seat/video

export const FoldButton = () => {
    const prefs = useSelector(getPrefs);
    const fixedAmount = useSelector(selectFixedAmount);

    let betting
    let bettingProps: any;

    if (prefs?.limitType === BetSchemes.FIXED_LIMIT) {
        bettingProps = {minRaise: fixedAmount, maxRaise: fixedAmount};
    }
    if (prefs?.limitType === BetSchemes.SPREAD_LIMIT) {
        bettingProps = {minRaise: prefs?.spreadMinRaise, maxRaise: prefs?.spreadMaxRaise};
    }
    betting = useBetting(bettingProps);

    return (
        <>
            <div className="actionButton red foldButton" onClick={betting.fold}>FOLD</div>
        </>
    )
}