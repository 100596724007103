import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { isUserHost } from "../../../logic/gameInstance/slice";
import {
    getPlayerId, getPlayerName
} from "../../../logic/player/slice";
import { adjustPlayerBank } from "../../../logic/prompts/slice";
import { isSeatTaken } from "../../../logic/table/slice";

interface Props {
    tablePosition: number;
}

export const BankButton = ({ tablePosition }: Props) => {
    const dispatch = useDispatch();

    const playerId = useSelector(getPlayerId(tablePosition));
    const playerName = useSelector(getPlayerName(tablePosition));

    const click = () => {
        const rawAmount: any = prompt("Adjust " + playerName + "'s bank by: ");

        if (!rawAmount) return;

        const numAmount = parseInt(rawAmount);
        if (isNaN(numAmount)) {
            alert(`Did not give player money. '${rawAmount}' is not a valid number`);
            return;
        }
        const adjString = playerId + " " + (numAmount ?? 0);

        dispatch(adjustPlayerBank(adjString));
    };

    return (
        <div className="bankButton" onClick={click}>Bank</div>
    );
};

