import React from "react";
import { useSelector } from "react-redux";

import { useWinners } from "../../../logic/gameInstance/hooks";
import {useDispatch} from "react-redux";
import {endShowdownPhase} from "../../../logic/prompts/slice";
import "./EndShowdownArea.css";
import { selectUserSeatNumber } from "../../../logic/table/slice";
import { isUserHost, selectDealer } from "../../../logic/gameInstance/slice";
import { selectPlayerById } from "../../../logic/player/slice";
interface EndShowdownProps{
    dispatch?: boolean
}

export const EndShowdownArea = (props: EndShowdownProps) => {
    const dispatch = useDispatch();
    const winners = useWinners({ dispatch: !!props.dispatch });

    const nextClick = () => dispatch(endShowdownPhase());
    const dealerNumber = useSelector(selectDealer);
    const seatNumber = useSelector(selectUserSeatNumber);
    const isDealer = dealerNumber === seatNumber;
    const isHost = useSelector(isUserHost);
    const dealer = useSelector(selectPlayerById(dealerNumber));
    if (isDealer){
        return (
            <div className = "endShowdownArea">
                <div className = "passDeckText"> YOUR DEAL IS OVER. <br/> PASS THE DECK TO THE NEXT PLAYER</div>
                <div className = "actionButton green passDeckButton" onClick={nextClick}>Pass the Deck</div>
            </div>
        );
    } else if (isHost) {
        return (
            <div className = "endShowdownArea">
                <div className = "passDeckText"> WAITING FOR PLAYER TO PASS THE DEAL. <br/> PASS THE DECK TO THE NEXT DEALER. </div>
                <div className = "actionButton green passDeckButton" onClick={nextClick}>Pass the Deck</div>
            </div>
        );
    }else {
        return (
            <div className="waitingText">Waiting for {dealer.name} to pass the deck</div>
        )
    }
};
