import React from "react";

interface TableLedgerRowProps {
    isHeader: boolean;
    leftContent: string;
    centerContent: string;
    rightContent: string;
}

export const TableLedgerRow = (props: TableLedgerRowProps) => {
    let contentClass = props.isHeader ? "boldTableText" : "tableText";
    return (
        <div className="tableLedgerRow">
            <div className="tableLedgerRow leftColumn">
                <div className={contentClass}>{props.leftContent}</div>
            </div>
            <div className="tableLedgerRow centerColumn">
                <div className={contentClass}>{props.centerContent}</div>
            </div>
            <div className="tableLedgerRow rightColumn">
                <div className={contentClass}>{props.rightContent}</div>
            </div>
        </div>
    );
}