/**
 * SeatContext.tsx
 *
 * When dealing with seats, there are 2 types of position.  The position on the screen and the position at the table.
 * Each user appears at the bottom of the screen, in the 7th or 0th screen position.
 *
 * React contexts are best used when dealing with infrequently changing values, because if a value changes,
 * all components in the Component tree inside that context will re-render.
 *
 * A Seat's screen position will never change, so we pass this value with a react context.
 * We wrap this context with the useSeatContext hook to add the relevant tablePosition and a boolean indicating if
 * the seat is the current user's seat.
 */

import React, {
    useContext, useMemo
} from "react";
import {useSelector} from "react-redux";
import {screenToTablePosition, isSeatTaken} from "../../../logic/table/slice";

export const SeatContext = React.createContext(0);

export function useSeatContext() {
    const screenPosition = useContext(SeatContext);
    const tablePosition = useSelector(screenToTablePosition(screenPosition));
    const isClientSeat = useMemo(() => (screenPosition % 7) === 0, [screenPosition]);
    const isThisSeatTaken = useSelector(isSeatTaken(tablePosition));

    return {
        screenPosition,
        tablePosition,
        isClientSeat,
        isThisSeatTaken,
    };
}
