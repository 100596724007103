/**
 * GameTypeList.tsx
 * A prompt for the dealer to choose which game to play next
 */

import React, {useMemo} from "react";
import {
    selectGame, selectGameTypes
} from "../../prompts/slice";
import {
    useDispatch, useSelector
} from "react-redux";
import GameTypeEntry from "./GameTypeEntry";
import "./GameTypeList.css";
import {GameSelectPopup} from "./GameSelectPopup";
import {
    GAME_CATEGORIES, getGamesByCategory
} from "../../../utils/GameInfo";

const GAME_CATEGORY_COLORS = {
    [GAME_CATEGORIES.CLASSIC]: "#1a9d27",
    [GAME_CATEGORIES.STUD]: "#018bd9",
    [GAME_CATEGORIES.COMMUNITY]: "#a42aca",
    [GAME_CATEGORIES.OTHER]: "#d6263d",
};
interface GameTypeListProps {
    isStatic?: boolean;
    clickAction?: object;
}

const GameTypeList = (props: GameTypeListProps) => {
    // this game type list is used for dealer game selection
    // and also in the rules modal to browse game rules
    // isStatic is the switch
    // it controls whether the list is rendered in a popup or directly output
    // (see return below)
    // and the onClick function of GameTypeEntry
    const isStatic = props?.isStatic;

    const categoryColumns = useMemo(() => {
        const columns = {} as any;
        for (const category of Object.values(GAME_CATEGORIES)) {
            const games = getGamesByCategory(category);
            columns[category] = [
                <div className="gameCategoryLabel" style={{backgroundColor: GAME_CATEGORY_COLORS[category]}}>{category}</div>,
                ...games.map((game: any) => (
                    // here we pass our click function
                    <GameTypeEntry clickAction={props?.isStatic ? props?.clickAction : null} gameType={game[0]} id={game[0]} key={game[0]} />
                ))];
        }
        return columns;
    }, []);

    const renderedColumns = useMemo(() => Object.entries(categoryColumns).map(([key, value]: any) => (
        <div className="gameTypeListColumn" key={key}>
            {value}
        </div>
    )), [categoryColumns]);

    return <>
        {/* default display */}
        {!props?.isStatic && (
            <>
                <span style={{
                    color: "#efc005", fontWeight: "bold", margin: "0 3em", top: "2px", position: "relative",
                }}>PLEASE SELECT A GAME ABOVE</span>
                <GameSelectPopup header="IT'S YOUR DEAL!">
                    <div style={{
                        color: "#efc005", marginBottom: "0.75em", fontSize: "1.2em", fontWeight: "bold",
                    }}>PLEASE SELECT A GAME</div>
                    <div className="gameSelectorGames">
                        {renderedColumns}
                    </div>
                </GameSelectPopup>
            </>
        )}

        {/* "static" display, like in rules modal */}
        {props?.isStatic && (
            <>
                <div className="gameSelectorGames">
                    {renderedColumns}
                </div>
            </>
        )}
    </>;
};

export default GameTypeList;
