import * as Requests from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserId } from "../api";


export const createTable = createAsyncThunk(
    "table/createTable",
    async(name: string) => Requests.createTable(name)
);

export const joinTable = createAsyncThunk(
    "table/joinTable",
    async(payload: { nickName: string, tableId: string, stack: number }) => {
        const response = await Requests.joinTable(payload);
        setUserId(response.id);
        return response;
    }
);

export const chooseSeat = createAsyncThunk(
    "table/chooseSeat",
    async(payload: {
        tableUserId: string,
        tableId: string,
        seatPosition: number
    }) => Requests.chooseSeat(payload)
);

export const resetGame = createAsyncThunk(
    "table/resetGame",
    async(tableId: string) => Requests.resetGame(tableId)
);

