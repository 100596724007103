import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck, faTimes
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useDispatch} from "react-redux";
import {
    buyLastCardUp, declineBuyLastCardUp
} from "../../../logic/prompts/slice";
import "./DeclareArea.css";


export const BuyLastCardUpArea = () => {
    const dispatch = useDispatch();

    const doBuyLastCardUp = () => dispatch(buyLastCardUp());
    const dontBuyLastCardUp = () => dispatch(declineBuyLastCardUp());

    return (
        <div className="declareActions">
            <div className="declareTitleText">BUY LAST CARD UP?</div>
            <div className="declareButtonsContainer">
                <div className="actionButton green" onClick={doBuyLastCardUp}>
                    <div className="declareIcon">
                        <FontAwesomeIcon icon={faCheck} /> 
                    </div>
                    Buy
                </div>
                <div className="actionButton blue" onClick={dontBuyLastCardUp}>
                    <div className="declareIcon">
                            <FontAwesomeIcon icon={faTimes} /> 
                        </div>
                        Decline
                    </div>
            </div>
        </div>
    );
};
