import React, { useState } from "react";
import { Disclaimer } from "../../components/Disclaimer";
import "./Login.css";
import silentSfx from "../../../static/sounds/silent.wav";

interface LoginParams {
    login: (name: string) => void;
    defaultName: string
}

const Login = (params: LoginParams) => {
    const [name, setName] = useState(params.defaultName);

    const playSound = (sfx: any) => {
        const sound = new Audio(sfx);
        sound.volume = 0;
        sound.play();
    };
    const login = (event: React.FormEvent<HTMLFormElement>) => {
        playSound(silentSfx);
        event.preventDefault();
        params.login(name);
    };

    const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value.trim();

        sessionStorage.setItem("username", name);
        setName(name);
    };

    return (<>
        <Disclaimer />
        <div className="login-container">
            <div className="tile" id="name-form">
                <form onSubmit={login} id="login-name">
                    <label><p>Enter a Nickname: </p>
                        <input autoFocus className="nameInput" type="text" name="name" value={name} onChange={nameChange} />
                    </label><br /><br />
                    <input type="submit" id="joinButton" value="Join Table" className="myButton" />
                </form>
            </div>
        </div>
    </>);
};

export default Login;
