import HollymoodSquaresRulesImagePlayer from "../../static/graphics/rules/Hollymood_Squares_Player_Hand.svg";
import HollymoodSquaresRulesImageCommunity from "../../static/graphics/rules/Hollymood_Squares_Community_Cards.svg";

export const HollywoodSquaresRules = {
    RULES: {
        CARDS: [
            "• 4 hole cards deal to each player.",
            "• 9 Community cards in rectangular pattern.",
        ],
        OBJECT: "Best 5 card hand utilizing any or all of your hole cards and up to 3 community cards up/down, left/right or diagonally.",
        ACTIONS: [
            "1. After all cards are dealt, a betting round starts left of the dealer. (Opening bet position rotates each betting round.)",
            "2. Next, the community card in position 1 is turned up, followed by a round of betting. This process is repeated for the remaining community cards 2 through 5.",
            "3. After final betting round, each remaining player will declare High or Low (if High/Low split game).",
            "4. Cards displayed; winners paid.",
        ],
        IMAGES: {
            PLAYER: HollymoodSquaresRulesImagePlayer,
            COMMUNITY: HollymoodSquaresRulesImageCommunity,
        },
    },
};
