import React from "react";
import { SeatInfo } from "./SeatInfo";
import "./TwilioMedia.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import {useTwilioPeer} from "../../../logic/twilio/hooks";

export const TwilioMedia = () => {
    const {video, audio} = useTwilioPeer();

    return <div className={"twilio-media"}>
        <PauseOverlay/>
        <video ref={video} autoPlay={true} data-private/>
        <audio ref={audio} autoPlay={true} data-private/>
        <SeatInfo />
    </div>;
};

const PauseOverlay = () => <div className={"pause-overlay"}>
    <div className={"pause-content"}>
        <FontAwesomeIcon icon={faPause}/>
        <br />
        <div className={"pause-text"}>Video Paused</div>
    </div>
</div>;
