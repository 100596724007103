import React, {useEffect} from "react";
import "./Dev.css";
import {
    Redirect, withRouter
} from "react-router-dom";
import {
    useDispatch, useSelector
} from "react-redux";
import {createTable} from "../../../logic/table/thunks";
import {
    selectJoined, selectTableId
} from "../../../logic/table/slice";

function DevCreate() {
    const dispatch = useDispatch();
    const joined = useSelector(selectJoined);
    const tableId = useSelector(selectTableId);

    useEffect(() => {
        dispatch(createTable("Created Table"));
    }, [dispatch]);

    if (joined) {
        const url = "/dev/" + tableId;
        return <Redirect to={url} />;
    }

    return <></>;
}

export default withRouter(DevCreate);
