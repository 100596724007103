import React, { useState } from "react";
import Modal, { useModal } from "../../Modal";
import { SubpageProps } from "../HostControlsModal";
import { ActForPlayerSubpage } from "./ActForPlayerSubpage";
import { getPlayers } from "../../../../logic/gameInstance/slice";
import { useSelector } from "react-redux";
import { SubpageHeader } from "./Components/SubpageHeader";
import { getBetPromptForPlayer } from "../../../../logic/prompts/slice";

export const ActForPlayer = (props: SubpageProps) => {
    const players = useSelector(getPlayers);
    const promptForPlayer = useSelector(getBetPromptForPlayer(0));

    const playerClick = (player: any) => {
        props.setSelectedPlayer(player);
        props.navigate('ActForPlayerSubpage');
    }

    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'Act for Player'} />
            <div className="hostControlsSubpageContainer actForPlayerButtonsContainer">
                {players.map((player: any, i: number) => {
                    return (
                        <div key={i} className="hostControlsButton" onClick={()=>{playerClick(player)}}>
                            <span className="buttonText">{player.name}</span>
                        </div>
                    )
                })}
            </div>
        </>
    );
}