import React, {
    useEffect
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import "./DeclareArea.css";
import {useSeatContext} from "../Seat/SeatContext";
import {useCurrentPlayer} from "../../../logic/gameInstance/hooks";
import {
    selectCheckCallInTurn,
    selectTargetBetRound,
    setCheckCallInTurn,
    setFoldInTurn,
    setTargetBetRound
} from "../../../logic/betting/slice";
import "./WaitingArea.css";
import "./ActionArea.css";
import "./BetArea/ChipBetArea.css";
import {
    isFolded, selectBetRoundID
} from "../../../logic/gameInstance/slice";
import { useExperimentalFeature } from "../../../logic/features/FeatureContext";
import { EXPERIMENTAL_FEATURES } from "../../../logic/features/FeatureContext";
import { RaisesCounter } from "./Components/RaisesCounter";



export const WaitingArea = () => {
    const {tablePosition} = useSeatContext();
    const { currentPlayer } = useCurrentPlayer(tablePosition);
    const folded = useSelector(isFolded(tablePosition));

    const dispatch = useDispatch();

    const checkCallInTurnEnabled = useExperimentalFeature(EXPERIMENTAL_FEATURES.CHECK_CALL_IN_TURN).isEnabled
    const checkCallInTurn = useSelector(selectCheckCallInTurn);

    const targetBetRound = useSelector(selectTargetBetRound);
    const betRoundId = useSelector(selectBetRoundID);

    const checkCallClick = () => {
        dispatch(setTargetBetRound(betRoundId));
        dispatch(setCheckCallInTurn(!checkCallInTurn));
    };

    useEffect(() => {
        //If bet round changes and does not match target, reset "in turn" action status
        if (targetBetRound !== betRoundId) {
            dispatch(setCheckCallInTurn(false));
            dispatch(setFoldInTurn(false));
            return;
        }
    }, [betRoundId, dispatch, targetBetRound]);



    return (
        <div className={!folded && checkCallInTurnEnabled ? "waitingArea" : "waitingArea waitingCentered"}>
            {!folded && checkCallInTurnEnabled && (
                <div
                    className={checkCallInTurn ? "selected actionButton checkCallInTurnButton" : "yellow actionButton checkCallInTurnButton"}
                    onClick={checkCallClick}
                >
                    {checkCallInTurn ? "Cancel\nCheck/Call\nin Turn" : "Check/Call\nin Turn"}</div>
            )}
            <div className="waitingTextRaises">
                <RaisesCounter />
                {/* empty div for layout */}
                <div style={{clear: "both"}}></div>
                Waiting for {currentPlayer?.name ?? "other player(s) to select an action"}
            </div>
            {/* empty div for correct text-centering layout */}
            <div></div>
        </div>
    );
};
