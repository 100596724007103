import { postEndpoint } from "../api";
import { PromptSelection } from "./slice";

export function getPrompts(tableId: string) {
    const body = { actionType: "showPrompts" };
    return postEndpoint("/tables/" + tableId + "/game-actions", body);
}

export function submitPrompt(tableId: string, body: PromptSelection) {
    return postEndpoint("/tables/" + tableId + "/game-actions", body);
}
