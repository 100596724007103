import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSeatContext } from "../../Seat/SeatContext";
import { isFolded } from "../../../../logic/gameInstance/slice";
import { selectBetRoundID } from "../../../../logic/gameInstance/slice";
import { useExperimentalFeature } from "../../../../logic/features/FeatureContext";
import { EXPERIMENTAL_FEATURES } from "../../../../logic/features/FeatureContext";
import {
    selectFoldInTurn,
    setTargetBetRound,
    setFoldInTurn } from "../../../../logic/betting/slice";

// this component displays and handles that button that lets you fold in turn
// used in <FoldArea />

export const FoldInTurnButton = () => {
    const dispatch = useDispatch();
    const {tablePosition} = useSeatContext();
    const folded = useSelector(isFolded(tablePosition));
    const checkCallInTurnEnabled = useExperimentalFeature(EXPERIMENTAL_FEATURES.CHECK_CALL_IN_TURN).isEnabled
    const foldInTurn = useSelector(selectFoldInTurn);
    const betRoundId = useSelector(selectBetRoundID);

    const foldClick = () => {
        dispatch(setTargetBetRound(betRoundId));
        dispatch(setFoldInTurn(!foldInTurn));
    };

    return (
        <>
            {!folded && checkCallInTurnEnabled && (
                <div
                    className={foldInTurn ? "actionButton foldInTurnButton selected" : "actionButton foldInTurnButton red"}
                    onClick={foldClick}
                >
                    {foldInTurn ? "CANCEL\nFOLD\nIN\nTURN" : "FOLD\nIN\nTURN"}
                </div>
            )}
        </>
    )
}