import React from "react";
import { SubpageProps } from "../HostControlsModal";
import { SubpageHeader } from "./Components/SubpageHeader";

export const MyAccount = (props: SubpageProps) => {
    return (
        <>
            <SubpageHeader navigate={()=>{props.navigate('Main')}} title={'My Account'} />
            <div className="section myAccountPlaceholder">
                <h2>Coming Soon</h2>
            </div>
        </>
    );
}