import React from "react";
import { PlayerHand } from "../Hand";
import {TwilioMedia} from "./TwilioMedia";
import {ErrorBoundary} from "../../../error_handling/ErrorBoundary";
import {useSeatContext} from "./SeatContext";
import { useSelector } from "react-redux";
import {
    selectGameType
} from "../../../logic/gameInstance/slice";
import { PassesIndicator } from "./PassesIndicator";

export const PlayerRow = () => {
    const {isClientSeat, isThisSeatTaken} = useSeatContext();
    const gameType = useSelector(selectGameType);
    const is_727 = gameType === "SEVEN_TWENTY_SEVEN"

    return <div className="playerRow">
        {isThisSeatTaken && is_727 && (
            <PassesIndicator/>
        )}
        {(!isClientSeat) && <ErrorBoundary><PlayerHand/></ErrorBoundary>}
        <TwilioMedia/>
    </div>;
};
