import "./BetArea.css";
import React, { useEffect } from "react";
import { getPrefs } from "../../../logic/gameInstance/slice";
import { useSelector } from "react-redux";
import FixedBetArea from "./BetArea/FixedBetArea";
import { SpreadBetArea } from "./BetArea/SpreadBetArea";
import {BetSchemes} from "../../../logic/gameMenu/components/GameRules";
import { useAutomatedBet } from "./BetArea/hooks/AutomatedBet";

const BetArea = () => {
    const settings: any = useSelector(getPrefs);

    // for reference:
    //NO_LIMIT = 1;
    //POT_LIMIT = 2;
    //FIXED_LIMIT = 3;
    //CAP_LIMIT = 4;
    //SPREAD_LIMIT = 5;

    useAutomatedBet();

    if (settings.limitType === BetSchemes.FIXED_LIMIT) return <FixedBetArea />;
    if (settings.limitType === BetSchemes.SPREAD_LIMIT) return <SpreadBetArea />;

    return <></>;
};

export default BetArea;
