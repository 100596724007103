import {
    useDispatch, useSelector
} from "react-redux";
import {
    selectMuted, selectVideoHidden, mute, unmute, startVideo, stopVideo
} from "../../logic/twilio/slice";
import React from "react";
import {
    faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./MediaControls.css";

const MediaControls = () => {
    // selectors
    const muted = useSelector(selectMuted);
    const videoHidden = useSelector(selectVideoHidden);

    const dispatch = useDispatch();

    // functions
    const muteMic = () => dispatch(mute());
    const unmuteMic = () => dispatch(unmute());
    const showCam = () => dispatch(startVideo());
    const hideCam = () => dispatch(stopVideo());

    const micClass = "startGame menuButton mic-" + ((muted) ? "on" : "off");
    const micFunc = (muted) ? unmuteMic : muteMic;
    const micIcon = (muted) ? faMicrophoneSlash : faMicrophone;

    const vidClass = "startGame menuButton video-" + ((videoHidden) ? "on" : "off");
    const vidFunc = (videoHidden) ? showCam : hideCam;
    const vidIcon = (videoHidden) ? faVideoSlash : faVideo;

    return (
        <div className="media-controls">
            <div className={vidClass} onClick={vidFunc}>
                <FontAwesomeIcon icon={vidIcon} />
            </div>

            <div className={micClass} onClick={micFunc}>
                <FontAwesomeIcon icon={micIcon} />
            </div>
        </div>
    );
};

export default MediaControls;
