import "./Menu.css";
import Modal, {useModal} from "./Modal";
import React, {useState} from "react";
import { useDispatch } from "react-redux";
import LogRocket from "logrocket";
import "./ReportIssue.css"

const ReportIssue = () => {
    const issueModal = useModal("reportIssue");
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");

    const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.trim());
    const descChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value);

    const submitReport = () => {
        if (description.trim() === "") return;

        issueModal.hide();
        LogRocket.captureMessage("User Reported Issue - "  + Date.now().toString(), {
            extra: {
                email: email,
                description: description,
            },
        });
        setDescription("");
    };

    return (
        <div className="menu contactUsModalContainer">
            <Modal id={issueModal.id}>
                <div className="modalTitle">
                    <h3 style={{marginTop: "0"}}>Report an issue</h3>
                </div>

                <div className="section">
                    <label htmlFor="email">(Optional) Email:</label><br />
                    <input style={{width: "20em"}} value={email} onChange={emailChange} type="text" id="email"/><br />

                    <br />

                    <label>Please describe the issue in as much detail as possible:</label><br />
                    <textarea style={{width: "99%"}} value={description} onChange={descChange}/><br />

                    <br />
                    <button className={"actionButton green reportSubmit"} style={{float: "left"}} onClick={submitReport}>Submit Report</button>
                </div>
            </Modal>
            <div className="actionButton" onClick={issueModal.show}>CONTACT<br/>US</div>
        </div>
    );
};
export default ReportIssue;
