/**
 * GameTypeEntry.tsx
 * A single game in the "dealer choose game" prompt.
 */

import React from "react";
import {getPrettyGameType} from "../../../utils/GameInfo";
import {
    selectGame, selectGameTypes
} from "../../prompts/slice";
import {
    useDispatch, useSelector
} from "react-redux";

interface Props {
    id: string;
    gameType: string;
    clickAction?: any;
}

const GameTypeEntry = (props : Props) => {
    const dispatch = useDispatch();
    const gameTypes: any[] = useSelector(selectGameTypes);
    const updateGameSelection = () => {
        const index = gameTypes.findIndex((gameType: { type: string; }) => gameType.type === props.gameType);
        dispatch(selectGame(index));
    };

    return <div className="gameSelectEntryBox">
        <div className="gameSelectEntry" onClick={props.clickAction ? () => {props.clickAction(props.gameType)} : updateGameSelection}>
            {getPrettyGameType(props.gameType)}
        </div>
    </div>;
};

export default GameTypeEntry;
