import React from "react";
import {
    useSelector
} from "react-redux";
import { isOnBreak } from "../../../logic/player/slice";
import ReportIssue from "../ReportIssue";
import {selectUserSeatNumber} from "../../../logic/table/slice";
import Menu from "../Menu";
import { isUserHost } from "../../../logic/gameInstance/slice";
import { GamePrefsInfo } from "./GamePrefsInfo";
import RulesModal from "../Settings/RulesModal";
import HostControlsModal from "../Settings/HostControlsModal";

export const BottomLeft = () => {
    const position = useSelector(selectUserSeatNumber);
    const onBreak = useSelector(isOnBreak(position));
    const isHost = useSelector(isUserHost);
    const label = onBreak ? "RETURN TO GAME" : "TAKE A BREAK";

    return (
        <>
            <div className="buttonsBottomLeft">
                <div className="buttonsBottomRow">
                    {isHost ?
                        <HostControlsModal/>
                        :
                        <ReportIssue />
                    }
                    <RulesModal />
                </div>
                <div className="buttonsBottomRow buttonsBottomRowCentered">
                    <GamePrefsInfo />
                </div>
            </div>
        </>
    );
};
