import React from "react"
import { Denominations } from "../../../../data/settings/denominations";
import { DenominationSelect } from "./DenominationSelect";

// this is a rulesRow component used on masterStartup modal
// it displays three dropdown select inputs for setting the Ante, Min. Bet, and Max. Bet
interface BettingStructureProps {
    startupPrefs: any,
    onChange: Function
}

const BettingStructure = (props: BettingStructureProps) => {
    const anteDenominations = Denominations.VALUES;
    const minBetDenominations = Denominations.VALUES;
    const maxBetDenominations = Denominations.VALUES;

    return (
        <div className="dropdownContainer">
            <div className="ante">
                Ante:
                <DenominationSelect value={props.startupPrefs.ante} name="Ante" prefsName="ante" options={anteDenominations} onChange={props.onChange} />
            </div>
            <div className="minBet">
                Min Bet:
                <DenominationSelect value={props.startupPrefs.spreadMinRaise} name="Min Bet" prefsName="spreadMinRaise" options={minBetDenominations} onChange={props.onChange} />
            </div>
            <div className="maxBet">
                Max Bet:
                <DenominationSelect value={props.startupPrefs.spreadMaxRaise} name="Max Bet" prefsName="spreadMaxRaise" options={maxBetDenominations} onChange={props.onChange} />
            </div>    
        </div>
    )
}

export default BettingStructure;