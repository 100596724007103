import { BUY_LAST_CARD_UP_SETTINGS, ROLL_YOUR_OWN_SETTINGS } from "poker-cows-common";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNextPrefs } from "../../../../logic/gameInstance/slice";
import { dealerChangePrefs } from "../../../../logic/prompts/slice";

interface OptionSectionProps {
    gameOptions: any;
}
// This is the Option section in the OptionPage when picking a game or looking at "Rules" within the game,
// meant for selecting Roll your Own, Buy Last card up, or others options. In theory multiple options could be selected at one time
// but for sake of simplicity the client has decided to only support one option at a time for now.
export const OptionSection = (props: OptionSectionProps) => {
    const nextPrefs = useSelector(getNextPrefs);
    const dispatch = useDispatch();

    const setRollYourOwnSetting = useCallback((value) => {
        const prefsString = JSON.stringify({ rollYourOwnSetting: value, buyLastCardUpSetting: BUY_LAST_CARD_UP_SETTINGS.DISABLED }).replace(" ", "");
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    const setOptionsToNone = useCallback(() => {
        const prefsString = JSON.stringify({ rollYourOwnSetting: ROLL_YOUR_OWN_SETTINGS.DISABLED, buyLastCardUpSetting: BUY_LAST_CARD_UP_SETTINGS.DISABLED }).replace(" ", "");
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    const setBuyLastCardUpSetting = useCallback(() => {
        const prefsString = JSON.stringify({ buyLastCardUpSetting: BUY_LAST_CARD_UP_SETTINGS.ENABLED, rollYourOwnSetting: ROLL_YOUR_OWN_SETTINGS.DISABLED }).replace(" ", "");
        dispatch(dealerChangePrefs(prefsString));
    }, [dispatch]);

    return (
        <div className="section">
        <div className="optionsSubSection optionTitle"> 
            <span>Option:</span><br/> 
            <div className="optionSelectionContainer">
                {props.gameOptions?.OPTIONS?.OPTION?.map((option: {DESCRIPTION: string, VALUE: string | number | boolean, DEFAULT?: boolean}) => {

                    // If the option is selected within our state, we need to show it as selected in the ui. 
                    // It effects the default button selected and brighter text on selected options.
                    let checked;
                    if (option.DESCRIPTION === "None" && option.VALUE === ROLL_YOUR_OWN_SETTINGS.DISABLED){
                        checked = nextPrefs?.rollYourOwnSetting === ROLL_YOUR_OWN_SETTINGS.DISABLED && nextPrefs?.buyLastCardUpSetting === BUY_LAST_CARD_UP_SETTINGS.DISABLED;
                    }

                    if (option.DESCRIPTION === "Roll Your Own (First card)" && option.VALUE === ROLL_YOUR_OWN_SETTINGS.FIRST){
                        checked = nextPrefs?.rollYourOwnSetting === ROLL_YOUR_OWN_SETTINGS.FIRST;
                    }

                    if (option.DESCRIPTION === "Buy Last Card Up" && option.VALUE === BUY_LAST_CARD_UP_SETTINGS.ENABLED) {
                        checked = nextPrefs?.buyLastCardUpSetting === BUY_LAST_CARD_UP_SETTINGS.ENABLED;
                    }

                    if (option.DESCRIPTION === "Always" && option.VALUE === ROLL_YOUR_OWN_SETTINGS.ALWAYS) {
                        checked = nextPrefs?.rollYourOwnSetting === ROLL_YOUR_OWN_SETTINGS.ALWAYS;
                    }
                    
                    // if the value of the option is BUY_LAST_CARD_UP_SETTINGS.ENABLED, and the options description is "Buy Last Card Up", the onclick value should be 
                    // setBuyLastCardUpSetting, otherwise if the rollYourOwn setting is "first" or "always", onClick should be setRollYourOwn function, 
                    // if it's not that, onClick should be the setOptionsToNone function
                    const onClick = option.VALUE === BUY_LAST_CARD_UP_SETTINGS.ENABLED && option.DESCRIPTION === "Buy Last Card Up" ? setBuyLastCardUpSetting :
                        (option.VALUE === ROLL_YOUR_OWN_SETTINGS.FIRST || option.VALUE === ROLL_YOUR_OWN_SETTINGS.ALWAYS) ? () => {setRollYourOwnSetting(option.VALUE)} : setOptionsToNone;

                    return (
                        <div style={checked ? {color: "#fff"} : {}} className="winnersOption" onMouseUp={onClick}>
                            <label htmlFor="option" className="radio">
                                <input readOnly type="radio" id="option" name="option" className="radioInput" checked={checked} />
                                <div className="customRadio"></div>
                                <div className="radioOptionText">
                                    {option.DESCRIPTION}
                                </div>
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>  
    );
}

