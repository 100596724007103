import React, {
    useMemo, useState
} from "react";
import { useSelector } from "react-redux";
import { getCurrentPlayer } from "../../../../logic/gameInstance/slice";
import { RaisesCounter } from "../Components/RaisesCounter";
import "./ChipBetArea.css";
import { getPrefs } from "../../../../logic/gameInstance/slice";
import {useBetting} from "./hooks/Betting";
import { Chip } from "../../Chip";
import { formatMoneyString } from "../../../../utils/MoneyFormat";

export function ChipBetArea(props: any) {
    const [raiseAmount, setRaiseAmount] = useState(0);
    const betting = useBetting(props);
    const raiseTo = useMemo(() => raiseAmount + betting.currentBet, [raiseAmount, betting.currentBet]);
    const prefs = useSelector(getPrefs);

    function increaseRaiseAmount(increaseAmount: number) {
        return () => setRaiseAmount((current) => Math.min(current + increaseAmount, betting.maxRaise));
    }

    function getBetLabel() {
        if (betting.currentBet <= 0) {
            return (<>
                BET<br/>
                {formatMoneyString(raiseAmount)}
            </>);
        }

        return <>
            RAISE <br/>
            {formatMoneyString(raiseAmount)} TO {formatMoneyString(raiseTo)}
        </>;
    }

    function getActionButton() {
        const classes = ["actionButton", "betButton"];
        const currentPlayer = useSelector(getCurrentPlayer);
        const moneyPutIn = raiseAmount + betting.callAmount;
        if (raiseAmount > 0) { //Green button
            const currentBankAmount = currentPlayer.bank;
            //if money put in is more than the player has, add ghost class to the div
            if (moneyPutIn > currentBankAmount){
                classes.push("ghost");
            }
            return <div className={["green", ...classes].join(" ")} onClick={() => betting.raiseTo(raiseTo)}>
                {getBetLabel()}
            </div>;
        } else if (betting.callAmount > 0) { //Blue button
            if (!betting.showCall) classes.push("ghost");
            return <div className={["blue", ...classes].join(" ")} onClick={betting.call}>
                CALL
                <br/>
                {formatMoneyString(betting.callAmount)}
            </div>;
        }
        return <div className={"yellow actionButton betButton"} onClick={betting.check}>CHECK</div>;
    }

    function getChip(denom: string, increaseAmount: number) {
        const raise = betting.callAmount > 0;
        const disabled = (raiseAmount >= betting.maxRaise || betting.numberOfRaises >= betting.maxRaises);
        return <Chip 
                    denom={denom}
                    value={increaseAmount}
                    raise={raise}
                    disabled={disabled}
                    onClick={increaseRaiseAmount(increaseAmount)} />
    }

    function getRaiseDot(index: number) {
        const classes = ["raiseDot"];
        if (index < betting.numberOfRaises) classes.push("raiseDotFilled");
        return <div className={classes.join(" ")}/>;
    }

    function getRaiseDots() {
        const dots = [];
        for (let i = 0; i < betting.maxRaises; i++) {
            dots.push(getRaiseDot(i));
        }

        return dots;
    }

    return <div className="chipBetArea">
        {getActionButton()}
        <div className="chipArea">
            <div className="chipAreaRow">
                <div className="actionButton betResetButton" onClick={() => setRaiseAmount(0)}>Reset</div>
                <RaisesCounter />
            </div>
            <div className="chipAreaRow chips">
                {getChip("low", prefs.chipDenomLow)}
                {getChip("mid", prefs.chipDenomMid)}
                {getChip("high", prefs.chipDenomHigh)}
            </div>
        </div>
    </div>;
}
