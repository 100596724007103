import React from "react";
import "../DeclareArea.css";
import {useAutoDeal} from "./UseAutoDeal";

export const DealArea = () => {
    //useAutoDeal();

    return (
        <div className="dea">
            Cards are being dealt.
        </div>
    );
};
