/**
 * Hand.tsx
 *
 * A react component for showing a collection of cards.
 */

import Card from "./Card";
import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import "./Hand.css";
import {
    isSeatTaken, screenToTablePosition
} from "../../logic/table/slice";
import {
    hasCards, selectCardStack, selectHand
} from "../../logic/player/slice";
import {
    doesGameExist, isFolded, selectDeclarations
} from "../../logic/gameInstance/slice";
import {getAnyDeclarePrompt} from "../../logic/prompts/slice";
import {HandValue} from "./ActionArea/HandValue";
import {PlayerDeclarationIndicator} from "./PlayerDeclarationIndicator";
import {FoldIndicator} from "./Seat/FoldIndicator";
import {Card as CardProps} from "../../logic/cardSelection/slice";
import {HI_LO_SETTINGS} from "poker-cows-common/";
import {useCardSelection} from "./ActionArea/CardSelection/useCardSelection";
import {useSeatContext} from "./Seat/SeatContext";

interface Props {
    cardStack: any[],
    show?: boolean,
    style?: string,
    tableCards?: boolean,
    folded?: boolean
    label?: JSX.Element
    declarationInd?: JSX.Element
    selecting?: boolean
    cardClickCallback: (card: CardProps, isSelected: boolean) => void;
}

/**
 * A component for rendering any collection of cards.
 */
const Hand = ({ cardStack, show, style, tableCards, label, declarationInd, folded, cardClickCallback, selecting, showdownOverride }: Props) => {
    if (folded === undefined) folded = true;

    const mapCard = (card: any) => (
        <Card
            dealNumber={card.orderDealt}
            tableCards={tableCards}
            rank={card.rank}
            suit={card.suit}
            up={card.up}
            showdownOverride={showdownOverride}
            show={show}
            key={card.rank + "-" + card.suit}
            metadata={card.metadata}
            cardClickCallback={cardClickCallback}
        />
    );
    const mapCards = (cards: any[]) => {
        if (!("map" in cards)) {
            return null;
        }
        return [
            ...cards.filter(card => !card?.metadata?.discarded),
            ...cards.filter(card => !!card?.metadata?.discarded)
                .sort((cardA, cardB) => (cardA.metadata?.discardedTime - cardB.metadata?.discardedTime)),
        ].map(mapCard);
    };

    const cssClass = "cards " + style + ((tableCards) ? "" : " overlapped ") + (selecting && show ? "selectingDiscard " : "");

    return (<div className={cssClass}>
        {cardStack && mapCards((cardStack || []).filter(card => !card?.metadata?.selected))}
        {label}
        {declarationInd}
        {folded && <FoldIndicator />}
        <div className={"selected-group"} >
            {cardStack && mapCards((cardStack || []).filter(card => !!card?.metadata?.selected))}
        </div>
    </div>);
};

/**
 * A react component for showing the cards of a specific player.
 */
export const PlayerHand = () => {
    const {tablePosition, screenPosition} = useSeatContext();
    const cardStack = useSelector(selectCardStack(tablePosition));
    const folded = useSelector(isFolded(tablePosition));
    const {onCardClicked, isSelecting} = useCardSelection();
    const declarations = useSelector(selectDeclarations);
    const declaration = declarations[tablePosition] ?? "";
    const anyDeclarePrompt = useSelector(getAnyDeclarePrompt);
    const isUserHand = (screenPosition % 7) === 0;
    const hasDownCard = !!cardStack.find((card: any) => !card.up);
    const label = hasDownCard ? undefined : <HandValue tablePosition={tablePosition} />;

    const seatTaken = useSelector(isSeatTaken(tablePosition));
    const cardsFolded = useSelector(isFolded(tablePosition));
    const gameExists = useSelector(doesGameExist);
    const doesHaveCards = useSelector(hasCards(tablePosition));
    const showdownOverride = useSelector(selectHand(tablePosition)).showdownOverride;

    const showFolded = gameExists && seatTaken && cardsFolded && doesHaveCards;

    let cssClass = folded ? "folded" : "";

    cssClass += " outside";

    const declarationInd = ((!anyDeclarePrompt) || isUserHand) ? <PlayerDeclarationIndicator high={declaration === HI_LO_SETTINGS.HIGH} low={declaration === HI_LO_SETTINGS.LOW} /> : <></>;
    const handContent = <Hand cardClickCallback={onCardClicked} folded={showFolded} cardStack={cardStack} style={cssClass} show={isUserHand} label={label} declarationInd={declarationInd} selecting={isSelecting} showdownOverride={showdownOverride} />;

    if (isSelecting && isUserHand) {
        return <span className="wrapper selecting">
            {handContent}
        </span>;
    }

    return handContent;
};

export default Hand;
