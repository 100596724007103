/**
 * useCardSelection.tsx
 *
 * A react hook for selecting cards.
 * Anywhere you need to select cards, or get info about selected cards, this hook should likely be used.
 */

import {
    useDispatch, useSelector
} from "react-redux";
import {
    discardCards, getDiscardCardsPrompt
} from "../../../../logic/prompts/slice";
import {useAllCardsOut} from "../../../../logic/cards/allCardsOut";
import {selectCardStack} from "../../../../logic/player/slice";
import {
    useCallback, useEffect
} from "react";
import {
    Card as CardProps, clearSelectedCards, getSelectedCards, setCardSelected
} from "../../../../logic/cardSelection/slice";
import {useSeatContext} from "../../Seat/SeatContext";
import { foldDuringSelection } from "../../../../logic/prompts/slice";

export const useCardSelection = () => {
    const dispatch = useDispatch();
    const {tablePosition} = useSeatContext();
    const toDiscard = useSelector(getSelectedCards);
    const selectPrompt = useSelector(getDiscardCardsPrompt);
    const selectSameRank = selectPrompt?.data?.selectSameRank;
    const selectingString = selectPrompt?.data?.selectingString ?? "";
    const selectedString = selectPrompt?.data?.selectedString ?? "";
    const maxSelectAmount = selectPrompt?.data?.maxNumberOfCards;
    const minSelectAmount = selectPrompt?.data?.minNumberOfCards ?? maxSelectAmount;
    const allCardsAnimated = useAllCardsOut();
    const isSelecting = !!selectPrompt && allCardsAnimated;

    const cardStack = useSelector(selectCardStack(tablePosition));

    const onCardClicked = useCallback((card: CardProps, isCardSelected: boolean) => {
        const cards = [];

        if (selectSameRank) {
            for (let i = 0; i < cardStack.length; i++) {
                if (cardStack[i].rank === card.rank) {
                    const otherCard = cardStack[i];
                    cards.push({
                        selectSameRank,
                        rank: otherCard.rank,
                        suit: otherCard.suit,
                        selected: !isCardSelected,
                    });
                }
            }
        } else {
            cards.push({
                selectSameRank,
                rank: card.rank,
                suit: card.suit,
                selected: !isCardSelected,
            });
        }

        dispatch(setCardSelected(cards));
    }, [cardStack, dispatch, selectSameRank]);

    const fold = () => {
        dispatch(foldDuringSelection())
    }

    useEffect(() => {
        dispatch(clearSelectedCards());
        return () => {
            dispatch(clearSelectedCards());
        };
    }, [dispatch]);

    const submitSelectedCards = () => {
        //TODO: update once DiscardCardAction and its subclasses support spaces in JSON array argument!
        const cardString = JSON.stringify(toDiscard).replace(" ", "").trim();

        dispatch(discardCards(cardString));
        dispatch(clearSelectedCards());
    };

    return {
        toDiscard,
        onCardClicked,
        isSelecting,
        selectingString,
        selectedString,
        maxSelectAmount,
        minSelectAmount,
        submitSelectedCards,
        fold,
    };
};
