import React from "react";

interface Props {
    name: string
    balance: string
}


const PlayerInfo = ({ name, balance }: Props) => (<div className="info-grid">
    <div className="name">{name}</div>
    <div className="balance">{balance}</div>
</div>);

export default PlayerInfo;
