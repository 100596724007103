import {
    chooseSeat, joinTable
} from "../table/thunks";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const initialState = {} as any;

export const slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setUserId: (state, action) => {
            state.id = action.payload;
            state.seatPosition = 1;
        },
    },
    extraReducers: builder => {
        builder.addCase(joinTable.fulfilled, (state, action) => {
            return { ...state, ...action.payload };
        });
        builder.addCase(chooseSeat.fulfilled, (state, action) => {
            return { ...state, ...action.payload };
        });
    },
});

export default slice.reducer;
export const {setUserId} = slice.actions;

export const selectTwilioKey = (state: any) => state.user.twilioKey || "";
export const selectUser = (state: any) => state.user;
export const selectUserId = (state: RootState) => state.user.id;
