import React from "react";
import "./Testing.css";


const Testing = () => (
    <div className="testing-container">
    </div>
);

export default Testing;
