import {
    createEntityAdapter, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";

type Modal = {
    id: string;
    visible: boolean;
};

const modalAdapter = createEntityAdapter<Modal>();

export const slice = createSlice({
    name: "modal",
    initialState: modalAdapter.getInitialState(),
    reducers: {
        toggleModal(state, action) {
            modalAdapter.upsertOne(state, action.payload);
        },
    },
});

export default slice.reducer;
export const { toggleModal } = slice.actions;

export const modalSelectors = modalAdapter.getSelectors<RootState>(
    (state) => state.modal
);

export const selectModalVisible = (modalId: string) => (state: RootState) =>
    modalSelectors.selectById(state, modalId)?.visible;
