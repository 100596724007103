/**
 * ChooseCardArea.tsx
 *
 * Components used for the selection of cards and displaying selected cards
 *
 * The design these componets are based on can be found at:
 * https://www.figma.com/file/RPfXM0eOC23wvmMby2oGBD/Poker-Cows---Final-Screens?node-id=9%3A136
 */

import React from "react";
import { useSelector } from "react-redux";
import Card from "../../Card";
import "./ChooseCardArea.css";
import {useCardSelection} from "./useCardSelection";
import {Card as CardSelectInterface} from "../../../../logic/cardSelection/slice";
import RightArrowCardSelectButton from "../../../../static/graphics/Right_Angle_Button.svg";
import { getGameType } from "../../../../logic/gameInstance/slice";

/**
 * Component for selection of cards.
 * Comprised of a label/button component on the left, and a "selected cards" ui to the right 
 */
export const ChooseCardArea = () => {
    const cardSelection = useCardSelection();

    return (
        <div style={{flexDirection: "row", justifyContent: "space-between", display: "flex", flex: 1}}>
            <SelectionLabelAndButton {...cardSelection} />
            <SelectedCards {...cardSelection} />
        </div>
    );
};

interface SelectedCardsParams {
    maxSelectAmount: number;    //The actual number of cards that player selected
    toDiscard: CardSelectInterface[];
    onCardClicked: (card: CardSelectInterface, isCardSelected: boolean) => void;
}

/**
 * Component for displaying the currently selected cards
 */
function SelectedCards(props: SelectedCardsParams) {
    const gameType = useSelector(getGameType);
    const containerClass = gameType === "TEXAS" ? " texDiscardCardContainer" : ""

    return (
        <div className={"discardCardContainer"+containerClass}>
            {Array(Math.max(props.maxSelectAmount ?? 0, props.toDiscard.length)).fill("").map((v, i) => {
                if (!(props.toDiscard)[i]) {
                    return <div className="card blankCard"/>;
                }

                const card = (props.toDiscard)[i];

                return <div className={"cardAndDeselectContainer"} onClick={() => props.onCardClicked(card, true)}>
                    <button onClick={() => props.onCardClicked(card, true)} className={"deselectCardButton"}>X</button>
                    <Card
                        show={true}
                        rank={card.rank}
                        suit={card.suit}
                        selected={false}
                        up={true}
                        showdownOverride={false}
                        dealNumber={0}
                        cardClickCallback={() => {
                            props.onCardClicked(card, true);
                        }}/>
                </div>;
            })}
        </div>
    );
}

interface SelectionParams {
    toDiscard: CardSelectInterface[];
    // If the player must select exactly x cards then: minSelectAmount = maxSelectAmount = x
    minSelectAmount: number; //The maximum number of cards a player can choose
    maxSelectAmount: number; //The minimum number of cards a player can choose
    selectingString: string;
    selectedString: string;
    submitSelectedCards: () => void;
}

/**
 * Component for displaying label/button for card selection area
 */
function SelectionLabelAndButton({
    maxSelectAmount,
    minSelectAmount,
    selectedString,
    selectingString,
    submitSelectedCards,
    toDiscard,
}: SelectionParams) {
    const gameType = useSelector(getGameType);
    const cardSelection = useCardSelection();
    const moreThanOneCard = cardSelection.toDiscard.length > 1;

    return <div style={{
        width: "165px", alignSelf: "center", display: "flex", flexDirection: "column", flex: 1
    }}>
        {(toDiscard.length === 0 || toDiscard.length < minSelectAmount) ?
            <>
                <div className="selectCards">
                    {gameType === "TEXAS" && (
                        <>
                            Pick a card to drop
                        </>
                    )}
                    {gameType !== "TEXAS" && (
                        <>
                            Select {minSelectAmount === 0? "up to" : minSelectAmount !== maxSelectAmount ? `${minSelectAmount} to ` : ""} {maxSelectAmount}<br/>
                            card{maxSelectAmount > 1 && "s"} to {selectingString}
                        </>
                    )}
                </div>

                {minSelectAmount <= 0 &&
                    <button onClick={submitSelectedCards} className="skipSelectButton">
                        Skip turn
                    </button>
                }
            </>
            :
            <button disabled={toDiscard.length < minSelectAmount} onClick={submitSelectedCards} className="passTheseCardsButton">
                <svg width="100%" height="100%" viewBox="-1 -1 160 115" preserveAspectRatio="none">
                    <path xmlns="http://www.w3.org/2000/svg" stroke-width="3" className="st0" d="M6.06,113.1c-2.82,0-5.12-2.3-5.12-5.12V5.49c0-2.56,2.08-4.65,4.64-4.65h117.6c1.99,0,3.78,1.1,4.68,2.87   l25.84,50.85c0.72,1.41,0.72,3.08,0,4.49l-26.13,51.41c-0.83,1.63-2.47,2.64-4.3,2.64H6.06z"/>
                </svg>

                <div className="passTheseCardsButtonText">{selectedString.toUpperCase()}<br/>{moreThanOneCard && "THESE \nCARDS"}{!moreThanOneCard && "THIS \nCARD"}</div>
            </button>
        }

    </div>;
}
