import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { updateTable } from "../shared";
import {
    selectUserPlayer, selectUserSeatNumber
} from "../table/slice";
import {
    getPlayerBank, getPlayerId, selectPlayerByTablePos
} from "../player/slice";
import {Wildcards} from "poker-cows-common";
export const initialState = {};

export const slice = createSlice({
    name: "gameInstance",
    initialState: initialState as any,
    reducers: {
        updateGameData: (state, action) => {
            return { ...state, ...action.payload, requiresRefresh: false };
        },
    },
    extraReducers: (builder => {
        builder.addCase(updateTable, (state, action) => {
            const gameData = action.payload?.fullTable;
            if (!gameData) return;
            return { ...state, ...gameData, requiresRefresh: false };
        });
    }),
});

export const { updateGameData } = slice.actions;

export const getToyboxTable = (state: RootState) => state.gameInstance;

export const getCurrentPlayer = (state: RootState) => getToyboxTable(state)?.currentGameInstance?.currentPlayer;

export const getTableCards = (state: RootState) => state.gameInstance?.currentGameInstance?.communityCards?.hand?._cards ?? [];
export const getGameType = (state: RootState) => state.gameInstance?.gameType;

export const getPlayersBets = (state: RootState) => state.gameInstance?.currentGameInstance?.currentBettingRoundTotals;
export const getPlayersChecks = (state: RootState) => state.gameInstance?.currentGameInstance?.currentBettingRoundChecks;
export const getPlayers = (state: RootState) => state.gameInstance?.players;
export const getPlayerBet = (tablePosition: number) => (state: RootState) => getPlayersBets(state)?.[getPlayerId(tablePosition)(state)] ?? 0;
export const getPlayerChecked = (tablePosition: number) => (state: RootState) => getPlayersChecks(state)?.[getPlayerId(tablePosition)(state)];
export const getPlayer727PassesRemaining = (playerId: number) => (state: RootState) => state.gameInstance?.currentGameInstance?.data?.passesRemaining?.[playerId] ?? 3;

export const getRemainingPlayers = (state: RootState) => (
    state.gameInstance?.currentGameInstance?.remainingPlayers ?? []
);
export const isFolded = (tablePosition: number) => (state: RootState) => !getRemainingPlayers(state)?.[getPlayerId(tablePosition)(state)];

export const getBet = (state: RootState) => state.gameInstance?.currentGameInstance?.currentBet || 0;
export const getAllBets = (state: RootState) => state.gameInstance?.currentGameInstance?.totalBetPerPlayer;
export const selectPot = (state: RootState) => state.gameInstance?.currentGameInstance?.pot;
export const selectWinnings = (state: RootState) => state.gameInstance?.currentGameInstance?.accounting?.totalWonPerPlayer ?? {};

export const doesGameExist = (state: RootState) => !!state.gameInstance?.currentGameInstance;

export const selectDealer = (state: RootState) => state.gameInstance?.currentDealer?.id;
export const selectPaused = (state: RootState) => state.gameInstance?.paused || false;

export const getCardsDealt = (state: RootState) => state.gameInstance?.currentGameInstance?.cardsDealt ?? 0;

export const selectWildCards = (state: RootState) => {
    const wildCards = [];
    if (getGameType(state) === "FOLLOW_THE_QUEEN") wildCards.push(11);

    const tracked = state.gameInstance?.currentGameInstance?.wildCard?.rank ?? -1;
    if (tracked >= 0 && !wildCards.find(value => value === tracked)) wildCards.push(tracked);
    return wildCards;
};

export const isCardWild = (cardData: any) => (state: RootState) => {
    const wilds = new Wildcards(state.gameInstance?.currentGameInstance?.wildcards._wildcards);
    return wilds.isWild(cardData);
};

export const isCardPrivateWild = (cardData: any, tablePosition: number) => (state: RootState) => {
    const player = selectPlayerByTablePos(tablePosition)(state);
    const wilds = new Wildcards(player?.hand?.wildcards._wildcards);
    return wilds.isWild(cardData);
};

export const selectCallAmount = (state: RootState) => {
    const position = selectUserSeatNumber(state);
    const fullCall = getBet(state) - getPlayerBet(position)(state);
    return fullCall;
};

export const selectCallAmountForPlayer = (playerSeatPosition: number) => (state: RootState) => {
    const fullCall = getBet(state) - getPlayerBet(playerSeatPosition)(state);
    return fullCall;
};

export const selectUserBet = (state: RootState) => {
    const position = selectUserSeatNumber(state);
    return getPlayerBet(position)(state);
};

//TODO: + small blind/previous raise
export const selectUserBank = (state: RootState) => {
    const position = selectUserSeatNumber(state);
    return getPlayerBank(position)(state);
};
export const selectShowCheck = (state: RootState) => (selectCallAmount(state) === 0);
export const selectShowCall = (state: RootState) => {
    const callAmount = selectCallAmount(state);
    if (callAmount <= 0) {
        return false;
    }
    return selectUserBank(state) >= callAmount;
};
export const selectBetRoundNumber = (state: RootState) => state.gameInstance?.currentGameInstance?.betRoundNumber ?? 0;
export const selectGameUUID = (state: RootState) => state.gameInstance?.currentGameInstance?.uuid ?? "";
export const selectBetRoundID = (state: RootState) => selectGameUUID(state) + "::" + selectBetRoundNumber(state);
export const selectSmallBlind = (state: RootState) => state.gameInstance?.prefs?.smallBlind;
export const selectFixedAmount = (state: RootState) => state.gameInstance?.prefs?.fixedLimitAmount;
export const selectBigBlind = (state: RootState) => state.gameInstance?.prefs?.bigBlind;
export const selectGameType = (state: RootState) => state.gameInstance?.gameType;
export const selectCardsDealt = (state: RootState) => state.gameInstance?.currentGameInstance?.cardsDealt ?? 0;
export const selectDeclarations = (state: RootState) => state.gameInstance?.currentGameInstance?.declarations ?? {};
export const selectHostId = (state: RootState) => state.gameInstance?.host?.id;
export const selectCurrentRaise = (state: RootState) => state.gameInstance?.currentGameInstance?.currentRaise ?? 0;
export const selectRaises = (state: RootState) => state.gameInstance?.currentGameInstance?.currentBettingRoundRaises ?? 0;
export const isUserHost = (state: RootState) => state.user?.seatPosition === 1;
export const selectTableStatus = (state: RootState) => state.gameInstance?.status ?? "";

export const selectWinners = (state: RootState) => state.gameInstance?.currentGameInstance?.winners ?? [];

//TODO bet settings are currently hardcoded but should come from the backend
export const selectCardDelay = (state: RootState) => state.gameInstance?.prefs.cardDelay;
export const getPrefs = (state: RootState) => state.gameInstance?.prefs;
export const getNextPrefs = (state: RootState) => state.gameInstance?.nextPrefs;

export default slice.reducer;
