import "./Pot.css";
import React from "react";
import {formatMoneyString} from "../../utils/MoneyFormat";

interface Props {
  amount: number;
}

const Pot = ({ amount }: Props) => {
    const content = formatMoneyString(amount);
    return <div className="pot">{amount && content}</div>;
};

export default Pot;
