import {
    useEffect, useState
} from "react";
import {useRedirect} from "../../../../hooks/useRedirect";

interface UseDeveloperProps {
    turnOn?: boolean;
}

export function useDeveloper(props?: UseDeveloperProps) {
    const [developer, setDeveloper] = useState(false);
    const gotoDevTable = useRedirect("/dev/");

    useEffect(() => {
        const isDeveloper = localStorage.getItem("developer");
        if (isDeveloper) {
            setDeveloper(true);
        }
    }, []);

    useEffect(() => {
        if (props?.turnOn) {
            localStorage.setItem("developer", "true");
            setDeveloper(true);
        }
    }, [props?.turnOn]);

    return {
        isDeveloper: developer,
        gotoDevTable,
    };
}
