import SevenTwentySevenRulesImagePlayer from "../../static/graphics/rules/Seven_Twenty_Seven_Player_Hand.svg";

export const SevenTwentySevenRules = {
    RULES: {
        CARDS: [
            "• 2 cards dealt to each player, 1 up, 1 down.",
        ],
        OBJECT: "Closest point total to 7 or 27 or both. Inside beats Outside, i.e. 7.5 beats 6.5 and 26.5 beats 27.5. Aces = 1 or 11. Face Cards = ½. All others = face value. If a player goes both ways with a perfect 7-27, they cannot lose, but may tie and split pots. If they go both ways without a perfect 7-27, and tie on either the low or the high, they lose both ways.",
        ACTIONS: [
            "1. Each Player is dealt 2 cards, 1 down and 1 up.",
            "2. Bet, starting with player left of dealer. Starting bet rotates each round.",
            "3. ALWAYS starting left of dealer, the players will choose either \"Take Card\" or \"No Card.\"",
            "4. Bet, then repeat the round of \"Take Card\" or \"No Card\". Once a player has chosen \"No Card\" a total of 3 times, they are locked out from taking any additional cards.",
            "5. These \"Card or No Card, Bet\" rounds continue until there is a round where no player takes a card, or the player's total is showing above 27. Final Bet.",
            "6. Players declare \"High\" or \"Low\" or \"Both\"",
            "7. Cards displayed; winners paid",
        ],
        IMAGES: { PLAYER: SevenTwentySevenRulesImagePlayer },
    },
};
