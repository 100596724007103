import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

const initialState = {
    checkCallInTurn: false,
    foldInTurn: false,
    targetBetRound: "", //The bet round actions are automated for, to prevent performing actions in the wrong round
};

export const slice = createSlice({
    name: "betting",
    initialState: initialState,
    reducers: {
        setCheckCallInTurn(state, action) {
            state.checkCallInTurn = action.payload;
            state.foldInTurn = false;
        },
        setFoldInTurn(state, action) {
            state.foldInTurn = action.payload;
            state.checkCallInTurn = false;
        },
        setTargetBetRound(state, action) {
            state.targetBetRound = action.payload;
        },
    },
});

export const selectCheckCallInTurn = (state: RootState) => state.betting?.checkCallInTurn;
export const selectFoldInTurn = (state: RootState) => state.betting?.foldInTurn;
export const selectTargetBetRound = (state: RootState) => state.betting?.targetBetRound;

export default slice.reducer;
export const { setCheckCallInTurn, setFoldInTurn, setTargetBetRound } = slice.actions;
