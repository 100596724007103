// this is a screen that shows upon the first time ever creating a table. 
//it's the first time the Initial Buy-in, Betting Structure (ante, min, max), and Chip Denominations settings are set.
import "../Settings/Settings.css";
import React, {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getNextPrefs,
} from "../../../logic/gameInstance/slice";
import { changePrefs } from "../../../logic/prompts/slice";
import { RulesRow } from "../Settings/RulesPages/RulesRow";
import BettingStructure from "./MasterStartupComponents/BettingStructure";
import { InitialBuyInContent } from "../Settings/InitialSetup/InitialBuyInContent";
import { ChipDenomSetupContent } from "./MasterStartupComponents/ChipDenomSetupContent";

export const ForcedBetSchemes = {
    BLINDS: 1,
    ANTE: 2,
};

export const BetSchemes = {
    NO_LIMIT: 1,
    POT_LIMIT: 2,
    FIXED_LIMIT: 3,
    CAP_LIMIT: 4,
    SPREAD_LIMIT: 5,
};

const MasterStartup = () => {
    const nextPrefs = useSelector(getNextPrefs);
    const [newPrefs, setNewPrefs] = useState(nextPrefs ?? {});
    const dispatch = useDispatch();

    // this runs when the "start game" button is clicked, it's when the prefs are officially changed in state
    const savePrefsClick = () => {
        const prefsString = JSON.stringify(newPrefs).replace(" ", "");
        dispatch(changePrefs(prefsString));
    };
    // set newPrefs, and tableInitialized to true so we know the masterStartup process has been triggered and dont show this screen again.
    useEffect(() => {
        setNewPrefs(nextPrefs ?? {});
        setNewPrefs({...newPrefs, 
            tableInitialized: true,
            ante: 100,
            spreadMinRaise: 100,
            spreadMaxRaise: 1000,
            chipDenomLow: 100,
            chipDenomMid: 500,
            chipDenomHigh: 1000,
        });
    }, [JSON.stringify(nextPrefs)]);

    // this runs when one of the dropdowns is changed and sets the new preferences
    const initialSettingsChange = (event: any) => {
        const update = {...newPrefs, [event.target.name]: parseFloat(event.target.value)};
        // enforce settings invariant i.e. make sure min === low =< mid =< hi =< max
        update.chipDenomLow = update.spreadMinRaise;
        update.chipDenomMid = Math.max(update.chipDenomMid, update.chipDenomLow);
        update.chipDenomHigh = Math.max(update.chipDenomHigh, update.chipDenomMid);
        update.spreadMaxRaise = Math.max(update.spreadMaxRaise, update.chipDenomHigh);

        setNewPrefs(update);
    }

    return (
        <div className="gamePopup">
            <div className="gameSelectorTitle">
                Welcome to PokerCows!
            </div>
            <div className="section masterStartupContainer">
                <div className="startupSubHeader">Let's get your table set up and let the fun begin!</div>
                <div className="tableRules">
                    <RulesRow title={"Initial Buy-In / Player"} content={<InitialBuyInContent onChange={initialSettingsChange} startupPrefs={newPrefs} />} isTitleBold={true} />
                    <RulesRow title={"Betting Structure"} content={<BettingStructure onChange={initialSettingsChange} startupPrefs={newPrefs} />} isTitleBold={true} />
                    <RulesRow title={"Chip Denominations"} content={<ChipDenomSetupContent onChange={initialSettingsChange} startupPrefs={newPrefs} />} isTitleBold={true} />
                </div>
            </div>
            <div className="buttons">
                <div onClick={savePrefsClick} className="startButton">START GAME</div>
            </div>
        </div>
    );
};

export default MasterStartup;
