/**
 * PlayAutomaticallyButton.tsx
 *
 * Button for the host to progress the game by randomly choosing an action for the current player.
 */

import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { isUserHost } from "../../../logic/gameInstance/slice";
import {playAutomatically} from "../../../logic/prompts/slice";

export const PlayAutomaticallyButton = () => {
    const dispatch = useDispatch();
    const isHost = useSelector(isUserHost);

    const className = isHost ? "kickButton bankButton" : "kickButton bankButton hidden";

    const click = () => {
        dispatch(playAutomatically());
    };

    return (
        <div className={className} onClick={click}>Play Automatically</div>
    );
};
