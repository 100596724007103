import React from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    getAnteAction, payAnte, skipAnte
} from "../../../logic/prompts/slice";
import "./AnteArea.css";
import {formatMoneyString} from "../../../utils/MoneyFormat";
import {selectUserBank} from "../../../logic/gameInstance/slice";


export const AnteArea = () => {
    const dispatch = useDispatch();
    const anteAction = useSelector(getAnteAction);
    const bank = useSelector(selectUserBank);
    const formattedAmount = formatMoneyString(anteAction?.betAmount);

    const payClick = () => {
        dispatch(payAnte());
    };

    const skipClick = () => {
        dispatch(skipAnte());
    };

    const canAfford = (anteAction?.betAmount <= bank);
    const classColor = (canAfford) ? "green" : "gray";
    const clickFunc = (canAfford) ? payClick : () => alert("Not enough money");

    return (
        <div className="anteActions">
            <div className={"actionButton " + classColor} onClick={clickFunc}>Pay {formattedAmount} Ante</div>
            <br />
            <div className="actionButton blue" onClick={skipClick}>Skip Hand</div>
        </div>
    );
};
