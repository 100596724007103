import TexasHoldemRulesImagePlayer from "../../static/graphics/rules/Texas_Holdem_Player_Hand.svg";
import TexasHoldemRulesImageCommunity from "../../static/graphics/rules/Texas_Holdem_Community_Cards.svg";

export const TexasHoldemRules = {
    RULES: {
        CARDS: [
            "• 2 player cards dealt down",
            "• 5 Community down cards",
        ],
        OBJECT: "Best 5 card poker hand",
        ACTIONS: [
            "1. Each player is dealt 2 cards down with 5 community cards.",
            "2. Bet, starting with the player left of the dealer. Bet rotates each round",
            "3. 3 community cards flipped, bet.",
            "4. 1 community card flipped, bet.",
            "5. Final community card flipped, bet.",
            "6. Players declare “High” or “Low” (if a high-low split game)",
            "7. Cards displayed; winners paid",
        ],
        IMAGES: {
            PLAYER: TexasHoldemRulesImagePlayer,
            COMMUNITY: TexasHoldemRulesImageCommunity,
        },
    },
};
