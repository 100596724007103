import React, {
    useCallback,
    useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    startGame, undoGameSelection
} from "../../../logic/prompts/slice";
import { GameSelectPopup } from "../../../logic/selectGame/components/GameSelectPopup";
import { selectGameType } from "../../../logic/gameInstance/slice";
import { getPrettyGameType } from "../../../utils/GameInfo";
import "./StartArea.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Settings } from "../Settings/Settings";

export const StartArea = () => {
    const [started, setStarted] = useState(false);
    const dispatch = useDispatch();

    const rawGameName = useSelector(selectGameType);
    const gameName = getPrettyGameType(rawGameName);

    const start = useCallback(() => {
        setStarted(true);
        dispatch(startGame());
    }, [dispatch]);

    const undo = useCallback(() => {
        dispatch(undoGameSelection());
    }, [dispatch]);

    return (<>
        Please fill out the game options above
        <GameSelectPopup header={gameName}>
            <div>
                <Settings rulesFirst={false}/>
            </div>

            <div className="buttons">
                <span className="backButton" onClick={undo}>
                    <FontAwesomeIcon className="backIcon" icon={faAngleLeft} />
                    Back to Games
                </span>
                <span className="startButton" onClick={start}>START GAME</span>
            </div>
        </GameSelectPopup>
    </>
    );
};
