import React, {useEffect} from "react";
import Modal, {useModal} from "./Modal";

export const Disclaimer = () => {
    const disclaimerModal = useModal("disclaimer");
    const agreed = localStorage.getItem("hasAgreed") || false;

    useEffect(() => {
        if (agreed) return;

        disclaimerModal.show();
    }, [agreed, disclaimerModal]);

    const hideDisclaimer = () => {
        disclaimerModal.hide();
        localStorage.setItem("hasAgreed", String(true));
    };

    return (
        <span className="disclaimer">
            <Modal id={disclaimerModal.id}>
                <h2>Poker Cows is currently released in <b>Open Alpha</b> mode.</h2>
                <h3>What this means for you:</h3>
                This is the first ever public release of Poker Cows, meant for testing and gathering feedback. You may experience bugs or unexpected behavior.
                If this happens, your feedback and any screenshots will be greatly beneficial to the improvement of the game in the future.<br /><br />
                Additionally, Poker Cows Open Alpha supports video and audio chat, and it's possible the development team will use this data to improve our services.<br /><br />
                If you have any questions or feedback, reach us at <b>support@pokercows.com</b>.<br /><br />
                <button onClick={hideDisclaimer}>
                    I understand, let's play!
                </button>
            </Modal>
        </span>
    );
};
