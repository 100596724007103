import React from "react";
import {SeatInfoContents} from "./SeatInfoContents";

export const SeatInfo = () => {
    const seatClass = "seatSpot seat";

    return (
        <div className={seatClass}>
            <SeatInfoContents />
        </div>
    );
};
