
import React, { useMemo, useState } from "react";
import Modal, { useModal } from "../Modal";
import { TableSettings } from "./HostControls/TableSettings";
import { MyAccount } from "./HostControls/MyAccount";
import { RestartHand } from "./HostControls/RestartHand";
import { ActForPlayer } from "./HostControls/ActForPlayer";
import { BankingLedger } from "./HostControls/BankingLedger/BankingLedger";
import { CashOut } from "./HostControls/CashOut";
import { ActForPlayerSubpage } from "./HostControls/ActForPlayerSubpage";
import "./HostControls.css"
import { BankingLedgerSubpage } from "./HostControls/BankingLedger/BankingLedgerSubpage";

export interface SubpageProps {
    navigate: any;
    setSelectedPlayer?: any;
}

// this is the modal that pops up to display host controls, for host only
// it appears any time you're a host on pokercows.com
// from here you can adjust table settings, restart hand, act for player,
// view banking ledger, and end the session
const HostControlsModal = () => {
    const hostControlsModal = useModal("host-controls");

    const [subpageIsVisible, setSubpageIsVisible] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [activePage, setActivePage] = useState('TableSettings');

    const navigate = (pageHandle: string) => {
        if (pageHandle == 'Main') {
            setSubpageIsVisible(false);
        } else {
            setSubpageIsVisible(true);
            setActivePage(pageHandle);
        }
    }

    const buttons = [
        {handle: 'Main', title: 'Main'},
        {handle: 'TableSettings', title: 'Table Settings'},
        {handle: 'MyAccount', title: 'My Account'},
        {handle: 'RestartHand', title: 'Restart Current Hand'},
        {handle: 'ActForPlayer', title: 'Act for Player'},
        {handle: 'BankingLedger', title: 'Banking Ledger and Transfers'},
        {handle: 'CashOut', title: 'Begin Cash Out & End Poker Session Process'}
    ]

    return (
        <div className="hostControls">
            <div className={"hostControlsModalContainer"}>
                <Modal hideClose={true} id={hostControlsModal.id}>
                    {!subpageIsVisible && (
                        <>
                            <div className="modalTitle">Host Controls</div>
                            <div className={"exitModalButton"} onClick={hostControlsModal.hide} />
                            <div className="hostControlsNavigationPage">
                                <div className="hostControlsButtonsContainer">
                                    {buttons.map((button: {handle: string, title: string}, i: number) => {
                                        if (button.handle !== 'Main') {
                                            return (
                                                <div key={i} className="hostControlsButton" onClick={()=>{navigate(button.handle)}}>
                                                    <span className="buttonText">{button.title}</span>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                    {subpageIsVisible &&
                        <>
                            {activePage === 'TableSettings' && <TableSettings navigate={navigate} />}
                            {activePage === 'MyAccount' && <MyAccount navigate={navigate} />}
                            {activePage === 'RestartHand' && <RestartHand navigate={navigate} />}
                            {activePage === 'ActForPlayer' && <ActForPlayer navigate={navigate} setSelectedPlayer={setSelectedPlayer} />}
                            {activePage === 'BankingLedger' && <BankingLedger navigate={navigate} setSelectedPlayer={setSelectedPlayer} />}
                            {activePage === 'CashOut' && <CashOut navigate={navigate} />}
                            {activePage === 'ActForPlayerSubpage' && <ActForPlayerSubpage navigate={navigate} selectedPlayer={selectedPlayer} />}
                            {activePage === 'BankingLedgerSubpage' && <BankingLedgerSubpage navigate={navigate} selectedPlayer={selectedPlayer} />}
                        </>
                    }
                </Modal>
            </div>
            
            <div className={hostControlsModal.visible ? "actionButton activeRulesButton" : "actionButton"} onClick={hostControlsModal.show}>HOST CONTROLS</div>
        </div>
    );
};

export default HostControlsModal;
