
import React, { useMemo, useState } from "react";
import {useSelector} from "react-redux";
import { selectGameType } from "../../../logic/gameInstance/slice";
import { getAnyChooseGamePrompt } from "../../../logic/prompts/slice";
import { getGameOptions, getGameRules, getPrettyGameType } from "../../../utils/GameInfo";
import Modal, { useModal } from "../Modal";
import { AllGames } from "./RulesPages/AllGames/AllGames";
import { CurrentGame } from "./RulesPages/CurrentGame/CurrentGame";
import { HouseRules } from "./RulesPages/HouseRules/HouseRules";
import { TableRules } from "./RulesPages/TableRules/TableRules";

// this is the modal that pops up to display game rules, options, table rules, etc.
// it appears when choosing a game or TODO: when a non-dealer player clicks the "rules button"
const RulesModal = () => {
    const gamePrompt = useSelector(getAnyChooseGamePrompt);
    const rawGameName = useSelector(selectGameType);
    const rulesModal = useModal("rules-documentation");
    const gameRules = useMemo(() => getGameRules(rawGameName), [rawGameName]);
    const gameOptions = useMemo(() => getGameOptions(rawGameName), [rawGameName]);
    const [selectedTab, setSelectedTab] = useState(2);

    const pages = [
        { title: 'HOUSE RULES', component: <HouseRules gameRules={gameRules} />},
        { title: 'TABLE RULES', component: <TableRules gameRules={gameRules} />},
        { title: 'CURRENT GAME', component: <CurrentGame gameRules={gameRules} gameOptions={gameOptions} />},
        { title: 'ALL GAMES', component: <AllGames gameRules={gameRules} />}
    ]

    return (
        <div className="rules">
            <div className="RulesDocumentation">
                <Modal hideClose={true} id={rulesModal.id}>
                    <div className="gameSetupButtonsContainer">
                        {pages.map((page, i) => {
                            return <div key={i} className={selectedTab == i ? "rulesTab activeButton" : "rulesTab inactiveButton"} onClick={() => setSelectedTab(i)}>{page.title}</div>
                        })}
                        <div className={"exitModalButton"} onClick={rulesModal.hide} />
                    </div>
                    <div className={"rulesModalContentContainer " + "rules"+selectedTab}>
                        {pages[selectedTab].component}
                    </div>
                </Modal>
            </div>
            {!gamePrompt && gameRules &&
                <div className={rulesModal.visible ? "actionButton activeRulesButton" : "actionButton"} onClick={rulesModal.show}>RULES</div>}
        </div>
    );
};

export default RulesModal;
