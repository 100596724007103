import LowChicagoRulesImagePlayer from "../../static/graphics/rules/Low_Chicago_Player_Hand.svg";

export const LowChicagoRules = {
    RULES: {
        CARDS: [
            "• 7 cards dealt 2 down, 4 up, 1 down",
        ],
        OBJECT: "Best 5 card poker hand. Low spade in the hole splits the pot with the low hand. 2 is lowest Spade.",
        ACTIONS: [
            "1. Each Player is dealt 2 cards down and 1 card up.",
            "2. Bet, starting with player showing best hand.",
            "3. 3 more rounds of cards dealt up with betting.",
            "4. 1 card dealt down, bet.",
            "5. Players declare “High” or “Low” (if a high-low split game)",
            "6. Cards displayed; winners paid",
        ],
        IMAGES: {PLAYER: LowChicagoRulesImagePlayer},
    },
};
