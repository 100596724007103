// This component displays 3 dropdown select inputs for setting the value of each chip
import "./../../Chip.css"
import React from "react";
import { DenominationSelect } from "./DenominationSelect";
import { Denominations } from "../../../../data/settings/denominations";
import { Chip } from "../../Chip";
interface ChipDenomSetupProps {
    startupPrefs: any,
    onChange: Function
}

export const ChipDenomSetupContent = ( props: ChipDenomSetupProps ) => {
    let lowChipDenominations = Denominations.VALUES;
    let midChipDenominations = Denominations.VALUES;
    let highChipDenominations = Denominations.VALUES;

    return (
        <div className="chipDenomSetupContainer">
            <div className="minBet">
                <div className=""></div>
                <Chip denom={"low"} value={props.startupPrefs.chipDenomLow} />
                <DenominationSelect value={props.startupPrefs.chipDenomLow} name="Low Chip" prefsName="chipDenomLow" options={lowChipDenominations} onChange={props.onChange} />
            </div>
           
            <div className="minBet">
                <Chip denom={"mid"} value={props.startupPrefs.chipDenomMid} />
                <DenominationSelect value={props.startupPrefs.chipDenomMid} name="Mid Chip" prefsName="chipDenomMid" options={midChipDenominations} onChange={props.onChange} />
            </div>
            
            <div className="minBet">
                <Chip denom={"high"} value={props.startupPrefs.chipDenomHigh} />
                <DenominationSelect value={props.startupPrefs.chipDenomHigh} name="High Chip" prefsName="chipDenomHigh" options={highChipDenominations} onChange={props.onChange} />
            </div>
        </div>
    ) 
};