import React from "react";
import Modal, {useModal} from "../Modal";
import TableLedger from "./TableLedger";
import {
    useDispatch, useSelector
} from "react-redux";
import { isOnBreak } from "../../../logic/player/slice";
import { toggleBreak } from "../../../logic/prompts/slice";
import { selectUserSeatNumber } from "../../../logic/table/slice";
import { isUserHost } from "../../../logic/gameInstance/slice";
import MediaControls from "../MediaControls";
import PauseButton from "../PauseButton";

export const BottomRight = () => {
    const ledgerModal = useModal("ledger");
    const position = useSelector(selectUserSeatNumber);
    const onBreak = useSelector(isOnBreak(position));
    const dispatch = useDispatch();
    const takeBreakClick = () => dispatch(toggleBreak());
    const label = onBreak ? "RETURN TO GAME" : "TAKE A\nBREAK";
    const isHost = useSelector(isUserHost);

return (
    <>
        <Modal id={ledgerModal.id}>
            <TableLedger />
        </Modal>
        <div className="actionButton breakButton" onClick={ledgerModal.show}>
            REQUEST CHIPS & BANKING
        </div>
        <div className={"breakPauseButtonsContainer"}>
            <div className="actionButton" onClick={takeBreakClick}>
                {label}
            </div>
            {isHost && <PauseButton useTextOnButton />}
        </div>
        <MediaControls/>
    </>

)};
