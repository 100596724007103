import * as Requests from "./api";
import {
    createAsyncThunk, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
    selectTableId, selectUserPlayer, selectUserSeatNumber
} from "../table/slice";
import { updateTable } from "../shared";

export interface PromptSelection {
    actionType: string,
    electedActionDto: {
        promptId: string,
        selection: number,
        argsString: string
    }
}

export const getPrompts = createAsyncThunk(
    "table/getPrompts",
    async(payload: { actionType: string }, thunkApi) => (
        Requests.getPrompts(selectTableId(thunkApi.getState()))
    )
);

export const submitPromptByAction = async(selector: Function, actionName: string, thunkApi: any, args = "") => {
    const prompt = selector(thunkApi.getState() as any);
    const returnedIndex = getActionIndex(prompt.prefix, actionName)(thunkApi.getState());
    return submitPrompt(selector, returnedIndex, thunkApi, args);
};


export const submitPrompt = async(selector: Function, selection: number | undefined, thunkApi: any, args = "") => {
    if (!selection && selection !== 0) return;
    const prompt = selector(thunkApi.getState() as any);

    const payload: any = {
        actionType: "electAction",
        electedActionDto: {
            promptId: prompt.prefix + "-" + prompt.id,
            selection: selection,
            argsString: args,
        },
    };

    return Requests.submitPrompt(selectTableId(thunkApi.getState()), payload);
};

export const startGame = createAsyncThunk(
    "table/startGame",
    async(data, thunkApi) => submitPromptByAction(getStartPrompt, "StartGameAction", thunkApi)
);

export const undoGameSelection = createAsyncThunk(
    "table/UndoGameSelectAction",
    async(data, thunkApi) => submitPromptByAction(getStartPrompt, "UndoGameSelectAction", thunkApi)
);

export const playAutomatically = createAsyncThunk(
    "table/playAutomatically",
    async(data, thunkApi) => Requests.submitPrompt(selectTableId(thunkApi.getState()), {
        actionType: "playAutomatically",
        electedActionDto: {
            promptId: "",
            selection: 0,
            argsString: "",
        },
    })
);

export const skipAnte = createAsyncThunk(
    "prompt/skipAnte",
    async(data, thunkApi) => submitPromptByAction(getAntePrompt, "FoldAction", thunkApi)
);

export const payAnte = createAsyncThunk(
    "table/payAnte",
    async(data, thunkApi) => submitPromptByAction(getAntePrompt, "ForcedBetAction", thunkApi)
);

export const acceptCard = createAsyncThunk(
    "table/acceptCard",
    async(data, thunkApi) => submitPromptByAction(getOfferCardPrompt, "AcceptCardAction", thunkApi)
);

export const declineCard = createAsyncThunk(
    "table/declineCard",
    async(data, thunkApi) => submitPromptByAction(getOfferCardPrompt, "DeclineCardAction", thunkApi)
);

export const declareHigh = createAsyncThunk(
    "table/declareHigh",
    async(data, thunkApi) => submitPromptByAction(getDeclarePrompt, "DeclareHighAction", thunkApi)
);

export const declareLow = createAsyncThunk(
    "table/declareLow",
    async(data, thunkApi) => submitPromptByAction(getDeclarePrompt, "DeclareLowAction", thunkApi)
);

export const buyLastCardUp = createAsyncThunk(
    "table/buyLastCardUp",
    async(data, thunkApi) => submitPromptByAction(getBuyLastCardUpPrompt, "BuyCardUpAction", thunkApi)
);

export const declineBuyLastCardUp = createAsyncThunk(
    "table/declineBuyLastCardUp",
    async(data, thunkApi) => submitPromptByAction(getBuyLastCardUpPrompt, "DeclineBuyCardUpAction", thunkApi)
);

export const dealCard = createAsyncThunk(
    "table/dealCard",
    async(data, thunkApi) => submitPrompt(getDealPrompt, 0, thunkApi)
);

export const foldDuringSelection = createAsyncThunk(
    "table/fold",
    async(data, thunkApi) => submitPrompt(getSelectCardsPrompt, 1, thunkApi)
);

export const fold = createAsyncThunk(
    "table/fold",
    async(data, thunkApi) => submitPrompt(getBetPrompt, 1, thunkApi)
);

export const check = createAsyncThunk(
    "table/check",
    async(data, thunkApi) => submitPrompt(getBetPrompt, 0, thunkApi, "0")
);

export const call = createAsyncThunk(
    "table/call",
    async(amount: number, thunkApi) => submitPrompt(getBetPrompt, 0, thunkApi, amount.toString())
);

export const foldForPlayer = createAsyncThunk(
    "table/fold",
    async(playerId: number, thunkApi) => submitPrompt(getBetPromptForPlayer(playerId), 1, thunkApi)
);

export const checkForPlayer = createAsyncThunk(
    "table/check",
    async(playerId: number, thunkApi) => submitPrompt(getBetPromptForPlayer(playerId), 0, thunkApi, "0")
);

export const callForPlayer = createAsyncThunk(
    "table/call",
    async({playerId, amount}: any, thunkApi) => submitPrompt(getBetPromptForPlayer(playerId), 0, thunkApi, amount.toString())
);

export const raise = createAsyncThunk(
    "table/raise",
    async(amount: number, thunkApi) => submitPrompt(getBetPrompt, 0, thunkApi, amount.toString())
);

export const showHand = createAsyncThunk(
    "table/showHand",
    async(data, thunkApi) => submitPrompt(getPlayerShowdownPrompt, 0, thunkApi)
);

export const dismiss = createAsyncThunk(
    "table/dismiss",
    async(data, thunkApi) => submitPrompt(getPlayerShowdownPrompt, 1, thunkApi)
);

export const changePrefs = createAsyncThunk(
    "table/changePreferences",
    async(newPrefs: string, thunkApi) => submitPrompt(getChangePrefsPrompt, 0, thunkApi, newPrefs)
);

export const dealerChangePrefs = createAsyncThunk(
    "table/dealerChangePreferences",
    async(newPrefs: string, thunkApi) => submitPromptByAction(getStartPrompt, "ChangePrefsAction", thunkApi, newPrefs)
);

export const undoHand = createAsyncThunk(
    "table/undoHand",
    async(newPrefs: string, thunkApi) => submitPrompt(getUndoHandPrompt, 0, thunkApi, newPrefs)
);

export const selectGame = createAsyncThunk(
    "selectGame/selectGame",
    async(index: number, thunkApi) => submitPrompt(getChooseGamePrompt, index, thunkApi)
);

export const toggleBreak = createAsyncThunk(
    "prompts/toggleBreak",
    async(data, thunkApi) => submitPrompt(getPlayerBreakPrompt, 0, thunkApi)
);

export const togglePause = createAsyncThunk(
    "prompts/toggleBreak",
    async(data, thunkApi) => submitPrompt(getPausePrompt, 0, thunkApi)
);

export const discardCards = createAsyncThunk(
    "prompts/discardCard",
    async(cardString: string, thunkApi) => submitPrompt(getDiscardCardsPrompt, 0, thunkApi, cardString)
);

export const adjustPlayerBank = createAsyncThunk(
    "prompts/adjustBank",
    async(adjString: string, thunkApi) => submitPrompt(getAdjustPlayerBankPrompt, 0, thunkApi, adjString)
);

export const playerRequestChips = createAsyncThunk(
    "prompts/requestChips",
    async(adjString: string, thunkApi) => submitPrompt(getPlayerRequestChipsPrompt, 0, thunkApi, adjString)
)

export const kickPlayer = createAsyncThunk(
    "prompts/kickPlayer",
    async(adjString: string, thunkApi) => submitPrompt(getKickPlayerPrompt, 0, thunkApi, adjString)
);

export const leaveGame = createAsyncThunk(
    "prompts/leaveGame",
    async(playerId: string, thunkApi) => submitPrompt(getPlayerLeaveGamePrompt, 0, thunkApi, playerId)
)

export const beginShutdown = createAsyncThunk(
    "prompts/beginShutdown",
    async(data, thunkApi) => {submitPrompt(getHostEndSessionPrompt, 0, thunkApi)}
)

export const alertOkay = createAsyncThunk(
    "prompts/alertOkay",
    async(data, thunkApi) => submitPrompt(getAlertPrompt, 0, thunkApi)
);

export const submitTexasDelayPrompt = createAsyncThunk(
    "prompts/submitTexasDelayPrompt",
    async(data, thunkApi) => submitPrompt(getTexasDelayPrompt, 0, thunkApi)
);

export const endShowdownPhase = createAsyncThunk(
    "prompts/endShowdownPhase",
    async(data, thunkApi) => submitPrompt(getDelayOfGamePrompt, 0, thunkApi)
);

export const hostTransferChips = createAsyncThunk(
    "prompts/hostTransferChips",
    async(argString: string, thunkApi) => submitPrompt(getHostTransferChipsPrompt, 0, thunkApi, argString)
);

export const shutdownNow = createAsyncThunk(
    "prompts/shutdownNow",
    async (data, thunkApi) => submitPrompt(getShutdownPrompt, 0, thunkApi)
);

export const addTime = createAsyncThunk(
    "prompts/addTime",
    async (data, thunkApi) => submitPrompt(getShutdownPrompt, 1, thunkApi )
);



export const initialState = {};

export const slice = createSlice({
    name: "prompts",
    initialState: initialState as any,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPrompts.fulfilled, (state, action) => {
            return { ...state, ...action.payload };
        });
        builder.addCase(updateTable, (state, action) => {
            const prompts = action.payload?.prompts;
            if (!prompts) return;
            return { ...state, ...prompts, requiresRefresh: false };
        });
    },
});

export default slice.reducer;

// eslint-disable-next-line arrow-body-style
export const selectGameTypes = (state: RootState) => {
    const prompt = getAnyPrompt("GameTypePrompt")(state);
    if (!prompt) return [];
    return (prompt?.actionsAvailable ?? []);
};
export const selectedGame = (state: RootState) => state.selectGame.selectedGame;

export const selectPromptId = (state: RootState) =>
    state.prompts?.fullPrompts[0]?.prefix + "-" + state.prompts?.fullPrompts[0]?.id;

const getPromptSuffix = (promptTypeSuffix: string) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];
        const playerId = prompt?.player?.id;

        if (prompt?.prefix.endsWith(promptTypeSuffix) && playerId === selectUserPlayer(state)?.id) {
            return prompt;
        }
    }
};

const getPrompt = (promptType: string) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];
        const playerId = prompt?.player?.id;

        if (prompt?.prefix === promptType && playerId === selectUserPlayer(state)?.id) {
            return prompt;
        }

    }
};

const getPromptForPlayer = (promptType: string, playerId: number) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];
        const promptPlayerId = prompt?.player?.id;

        if (prompt?.prefix === promptType && promptPlayerId === playerId) {
            return prompt;
        }

    }
}

export const getAnyPromptSuffix = (promptTypeSuffix: string) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];

        if (prompt?.prefix.endsWith(promptTypeSuffix)) {
            return prompt;
        }
    }
};

export const getAnyPrompt = (promptType: string) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];

        if (prompt?.prefix === promptType) {
            return prompt;
        }
    }
};

const getAction = (promptName: string, actionName: string) => (state: RootState) => {
    const prompts = state.prompts?.fullPrompts;
    if (!prompts) {
        return undefined;
    }
    for (let i = 0; i < prompts.length; i++) {
        const prompt = prompts[i];
        const actions = prompt.actionsAvailable;
        const playerId = prompt?.player?.id;

        if (prompt?.prefix === promptName && playerId === selectUserPlayer(state)?.id) {

            for (let j = 0; j < actions.length; j++) {
                const action = actions[j];

                if (action?._reflectionName.endsWith(actionName)) {
                    return {
                        ...action,
                        promptIndex: j,
                    };
                }
            }
        }
    }
};

const getActionIndex = (promptName: string, actionName: string) => (state: RootState) => getAction(promptName, actionName)(state)?.promptIndex;

export const getAnteAction = (state: RootState) => getAction("AntePrompt", "ForcedBetAction")(state);

export const getAntePrompt = (state: RootState) => getPrompt("AntePrompt")(state);

export const getDeclarePrompt = (state: RootState) => getPrompt("DeclarePrompt")(state);
export const getBuyLastCardUpPrompt = (state: RootState) => getPrompt("BuyCardUpPrompt")(state);

export const getDealPrompt = (state: RootState) => getPromptSuffix("DealCardPrompt")(state) || getPromptSuffix("FlipCardPrompt")(state);
export const getAnyDealPrompt = (state: RootState) => getAnyPromptSuffix("DealCardPrompt")(state) || getAnyPromptSuffix("FlipCardPrompt")(state);

export const getChooseGamePrompt = (state: RootState) => getPrompt("GameTypePrompt")(state);
export const getAnyChooseGamePrompt = (state: RootState) => getAnyPrompt("GameTypePrompt")(state);

export const getBetPrompt = (state: RootState) => getPrompt("BetPrompt")(state);

export const getBetPromptForPlayer = (playerId: number) => (state: RootState) => getPromptForPlayer("BetPrompt", playerId)(state);

export const getAnyBetPrompt = (state: RootState) => getAnyPrompt("BetPrompt")(state);

export const getAlertPrompt = (state: RootState) => getPrompt("AlertPrompt")(state);
export const getOfferCardPrompt = (state: RootState) => getPrompt("OfferCardPrompt")(state);

export const getChangePrefsPrompt = (state: RootState) => getPrompt("ChangePrefsPrompt")(state);

export const getUndoHandPrompt = (state: RootState) => getPrompt("UndoHandPrompt")(state);

export const getStartPrompt = (state: RootState) => getPromptSuffix("DealerPrompt")(state) ||
        getPromptSuffix("StartGamePrompt")(state);

export const getPlayerShowdownPrompt = (state: RootState) => getPromptSuffix("PlayerShowdownPrompt")(state);

export const getPlayerBreakPrompt = (state: RootState) => getPromptSuffix("PlayerBreakPrompt")(state);
export const getPausePrompt = (state: RootState) => getPromptSuffix("TogglePausePrompt")(state);

export const getDelayOfGamePrompt = (state: RootState) => getPromptSuffix("DelayOfGamePrompt")(state);

export const getTexasDelayPrompt = (state: RootState) => getPromptSuffix("TexasDiscardDelayPrompt")(state);
export const getAnyDelayPrompt = (state: RootState) => getAnyPrompt("DelayOfGamePrompt")(state);
export const getAnyDeclarePrompt = (state: RootState) => getAnyPrompt("DeclarePrompt")(state);

export const getDiscardCardsPrompt = (state: RootState) => getPromptSuffix("DiscardCardsPrompt")(state) ?? getPromptSuffix("RedrawCardsPrompt")(state) ?? getSelectCardsPrompt(state);
export const getSelectCardsPrompt = (state: RootState) => getPromptSuffix("SelectCardsPrompt")(state);

export const getAdjustPlayerBankPrompt = (state: RootState) => getPromptSuffix("HostGiveTakeMoneyPrompt")(state);
export const getPlayerRequestChipsPrompt = (state: RootState) => getPromptSuffix("PlayerRequestChipsPrompt")(state);
export const getKickPlayerPrompt = (state: RootState) => getPromptSuffix("HostKickPlayerPrompt")(state);

export const getHostTransferChipsPrompt = (state: RootState) => getPromptSuffix("HostTransferChipsPrompt")(state);
export const getPlayerLeaveGamePrompt = (state: RootState) => getPromptSuffix("PlayerLeaveGamePrompt")(state);
export const getHostEndSessionPrompt = (state: RootState) => getPromptSuffix("HostEndSessionPrompt")(state);
export const getShutdownPrompt = (state: RootState) => getPromptSuffix("ShutdownPrompt")(state);
