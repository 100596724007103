import * as Requests from "./api";
import {
    createAsyncThunk, createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export const updateGameTypes = createAsyncThunk(
    "selectGame/updateGameTypes",
    async() => Requests.getAvailableGameTypes()
);

export const initialState = {
    availableGames: [] as any,
    selectedGame: "",
};

export const slice = createSlice({
    name: "selectGame",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateGameTypes.fulfilled, (state, action) => {
            state.availableGames = [...action.payload];
        });
    },
});

export default slice.reducer;

export const selectGameTypes = (state: RootState) => state.selectGame.availableGames;

export const selectedGame = (state: RootState) => state.selectGame.selectedGame;
