import React, { useMemo, useState } from "react";

interface CurrentGameProps {
    gameRules: any
    gameOptions: any
}

export const CurrentGame = (props: CurrentGameProps) => {
    return (
        <>
            <div className="section">
                <div className="sectionTextContent">
                    <span className="sectionTextTitle">Cards:</span>
                    <br />
                    <span className="sectionBulletPoints">
                        {props.gameRules?.RULES?.CARDS.map((cardRule: string) => <>{cardRule}<br /></>)}
                    </span>
                    <br />
                    <br />
                    <div className="objective">Object: <span className="objectiveText"> {props.gameRules?.RULES?.OBJECT}</span></div>
                </div>
                <div className="sectionImages">
                    {props.gameRules?.RULES?.IMAGES?.PLAYER ? <img src={props.gameRules.RULES?.IMAGES?.PLAYER}></img> : undefined}
                    {props.gameRules?.RULES?.IMAGES?.COMMUNITY ? <img src={props.gameRules.RULES?.IMAGES?.COMMUNITY}></img> : undefined}
                </div>
            </div>
            <br />
            <br />
            <div className="section">
                <div className="sectionTextContent">
                    <span className="sectionTextTitle">Action:</span><br />
                    <div className="actionText">
                        {props.gameRules?.RULES?.ACTIONS?.map((item: string) => <>{item}<br /></>)}
                    </div>
                </div>
            </div>
            <div className="section">
                <div style={{display: "flex", flexDirection: "column"}} className="sectionTextContent">
                    <div className="objective">Winners:  <span className="objectiveText"> {props.gameOptions?.OPTIONS?.WINNERS.map((option: any) => option.DESCRIPTION + ", ")}</span></div>
                    <div className="objective">Options: <span className="objectiveText"> {props.gameOptions?.OPTIONS?.OPTION.map((option: any) => option.DESCRIPTION + ", ")}</span></div>
                    <div className="objective">Wildcards: <span className="objectiveText"> {props.gameOptions?.OPTIONS?.WILDCARD.map((option: any) => option.DESCRIPTION + ", ")}</span></div>
                </div>
            </div>
        </>
    );
};