import React from "react";
interface MoneyInputProps {
    value?: number;
    name: string;
    onChange: any;
}

export const MoneyInput = (props: MoneyInputProps) => {
    return (
        <input className="playerSelect" type="number" value={props.value} name={props.name} onChange={props.onChange}/>
    );
}