import PassTheTrashRulesImagePlayer from "../../static/graphics/rules/Pass_The_Trash_Player_Hand.svg";

export const PassTheTrashRules = {
    RULES: {
        CARDS: [
            "• 7 cards dealt down to each player",
            "• Pass 3, discard 2, roll 4",
        ],
        OBJECT: "Best 5 card poker hand",
        ACTIONS: [
            "1. Each Player is dealt 7 down cards. Bet, starting left of dealer.",
            "2. Each player simultaneously selects and passes 3 cards to their left player, Bet.",
            "3. Players simultaneously discard 2 and roll 1, then bet based on best hand.",
            "4. Players simultaneously roll 1 card, then bet for 3 more rounds.",
            "5. Players declare “High” or “Low” (if a high-low split game)",
            "6. Cards displayed; winners paid",
        ],
        IMAGES: {PLAYER: PassTheTrashRulesImagePlayer},
    },
};
