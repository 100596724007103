/** FiveCardDrawRules.tsx
 *
 * In-Game Rules for FiveCardDraw, shown when you click on "Rules" in the browser.
 */
import FiveCardDrawRulesImagePlayer from "../../static/graphics/rules/Five_Card_Draw_Player_Hand.svg";

export const FiveCardDrawRules = {
    RULES: {
        CARDS: [
            "• 5 cards dealt face down to each player.",
        ],
        OBJECT: "Best 5 card poker hand",
        ACTIONS: [
            "1. Each Player is dealt 5 cards face down",
            "2. Bet, starting left of dealer",
            "3. Players replace 0 to 3 cards (up to 4 with an Ace), starting left of dealer.",
            "4. Bet, starting left of dealer",
            "5. Players declare “High” or “Low” (if a high-low split game)",
            "6. Cards displayed; winners paid",
        ],
        IMAGES: {PLAYER: FiveCardDrawRulesImagePlayer},
    },
};
