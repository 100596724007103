import FollowTheQueenRulesImagePlayer from "../../static/graphics/rules/Follow_The_Queen_Player_Hand.svg";

export const FollowTheQueenRules = {
    RULES: {
        CARDS: [
            "• 7 cards dealt 2 down, 4 up, 1 down",
        ],
        OBJECT: "Best 5 card poker hand. Queens and followers are wild. Wildcard changes with each Queen.",
        ACTIONS: [
            "1. Each Player is dealt 2 cards down and 1 card up.",
            "2. Bet, starting with player showing best hand.",
            "3. 1 card dealt down, bet.",
            "4. 3 more rounds of cards dealt up with betting.",
            "5. Players declare “High” or “Low” (if a high-low split game)",
            "6. Cards displayed; winners paid",
        ],
        IMAGES: {PLAYER: FollowTheQueenRulesImagePlayer},
    },
};
