import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsCurrentUser } from "../../../logic/user/selectors";
import turnSfx from "../../../static/sounds/your-turn.wav";
import {
    getCardsDealt, selectPaused
} from "../../../logic/gameInstance/slice";
import {useAllCardsOut} from "../../../logic/cards/allCardsOut";

export const TurnIndicator = () => {
    const isUser = useSelector(selectIsCurrentUser);
    const allCardsOut = useAllCardsOut();
    const paused = useSelector(selectPaused);

    const playSound = (sfx: any) => {
        const sound = new Audio(sfx);
        sound.play();
    };

    useEffect(() => {
        if (!isUser) return;
        if (!allCardsOut) return;
        if (paused) return;

        playSound(turnSfx);

        const repeatSecondsDelay = 15;
        const repeat = setInterval(() => playSound(turnSfx), repeatSecondsDelay * 1000);

        return () => {
            clearInterval(repeat);
        };
    }, [allCardsOut, isUser, paused]);

    if (!allCardsOut) return null;

    return (
        <>
            <div className="turnIndicatorContainer">
                <div className="turnIndicator" />
            </div>
        </>
    );
};
