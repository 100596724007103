import React from "react";
import { createTable } from "../../logic/table/thunks";
import { useDispatch } from "react-redux";

const CreateTableButton = () => {
    const dispatch = useDispatch();
    const handleCreateTable = () => dispatch(createTable("Created Table"));

    return <div className="button " onClick={handleCreateTable}>
        Start Game
    </div>;
};

export default CreateTableButton;
